<template>
  <div v-cloak v-if="design && design.id">
    <van-swipe :autoplay="3000" v-if="design && design.attach&&(design.imgs.larges&&design.imgs.larges.length==0)">
      <van-swipe-item>
        <img v-lazy="design.attach" @click="previewImgs2()"  fit="none" style="width:100%"/>
      </van-swipe-item>
    </van-swipe>
    <van-swipe :autoplay="3000" v-if="design && design.imgs&&design.imgs.larges">
     <van-swipe-item  v-for="(image, index) in design.imgs.larges" :key="index">
        <img @click="previewImgs(index)" v-lazy="image" fit="none" style="width:100%"/>
      </van-swipe-item>
    </van-swipe>
    <van-cell center v-show="design" style="flex:0.5">
      <template slot="title">
                <span style="display:block;font-size:1.2rem;font-weight: bold;">
                  <!-- ¥{{ design.price }} -->
                </span>
      </template>
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <van-button type="default" size="small" v-if="design['realshow_url']" @click="tagTo(design['realshow_url'])">换装</van-button>
      <van-button type="default" size="small" v-if="design['3d_url']" @click="tagTo(design['3d_url'])">3D</van-button>
    </van-cell>
    <van-cell center size="large" title-style="font-size:2rem" v-show="design" style="flex:0.5">
      <template slot="title">
        <span style="display:block;font-size:1.2rem;">{{ design.code }}</span>
        
        <span v-if="!$store.getters.token" style="display:block;font-size:1.0rem;color:red">¥{{ design.price ? price_ratio(design.price) : 0 }}/{{ design.unit_label }}</span>
         <span v-else style="display:block;font-size:1.0rem;color:red">¥{{ design.price ? Number(design.price* (design.price_ratio||1)).toFixed(2) : 0 }}/{{ design.unit_label }}</span>

      </template>
    </van-cell>
    <div v-if="design && design.desc" v-html="design.desc" class="design-desc"></div>
    <!-- <van-cell center title="系列选择：" title-class="width-left" value-class="series-image" v-if="design && design.series && design.series.items">
        <van-image v-for="item in design.series.items" :key="item.id" width="2.5rem" height="2.5rem" style="padding:3px;" :src="item.img" @click="changeDesign(item.id)" />
    </van-cell>
    <van-cell-group v-for="(goodss, index) in goodsList" :key="index" style="border-top:1px solid lightgrey;">
        <van-cell :title="index" title-class="width-left" value-class="cell-value" value="面料型号" />
        <van-cell v-for="(item, i) in goodss" :key="i" title-class="width-left" value-class="cell-value" :title="item.curtain_type_label" :value="showLabelValue(item)" />
    </van-cell-group> -->
    <!-- <div v-if="design && design.desc" v-html="design.desc" class="design-desc"></div> -->
    <!-- 下单内容 -->
    <CreateFabricOrder v-bind:show.sync="isShowCartInfoPop2" v-bind:cart_info="{}" v-bind:design="design"></CreateFabricOrder>
    <van-tabbar route v-model="active">
      <van-tabbar-item :to="'/series/' + $store.getters.shop_id + '/0/mianliaoshangcheng'" icon="replay">返回</van-tabbar-item>
      <van-tabbar-item
          @click="addCart"
          style="color: #fff;
                                                background-color: #07c160;
                                                border: 1px solid #07c160;"
      >
        加入购物车
      </van-tabbar-item>
      <van-tabbar-item replace to="/cart" icon="shopping-cart">购物车</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import CreateFabricOrder from '@/components/CreateFabricOrder.vue'
  import { ImagePreview } from 'vant';
export default {
  name: 'Goods',
  components: {CreateFabricOrder},
  data() {
    return {
      goodsList: {},
      design: {},
      goods: {
        goods_id: 0,
        // 商品标题
        title: '',
        // 默认商品 sku 缩略图
        picture: ''
      },
      isShowCartInfoPop2: false,
      active: 0
    }
  },
  watch: {
    $route(to, from) {
      this.getList(to.params.design_id)
    }
  },
  mounted() {
    this.getList(this.$route.params.design_id)
  },
  methods: {
    previewImgs2(){
   ImagePreview({
    images: 
    [this.design.attach]
  ,
  startPosition: 0,
});
    },
    previewImgs(index){
      ImagePreview({
    images: 
    this.design.imgs.larges
  ,
  startPosition: index,
});
        // ImagePreview([img], startPosition: 1);
    },
    getList(design_id) {
      let design = JSON.parse(localStorage.getItem('product'))
      design.imgs = design.imgs||{larges:[]}
      this.design = design
      this.title = this.design.code ? this.design.code : '商品详情'
      document.title = this.design.code
        this.$nextTick(()=>{
          if(document.querySelector(".design-desc")){
document.querySelector(".design-desc").onclick =function(e){
            if(e.target.nodeName.toLowerCase()=='img'&&e.target.src){
               ImagePreview({
              images: [e.target.src],
               startPosition: 0,
            });
            }  
          }
          }
         
        })
      // this.$http.get('/clients/designs/' + design_id).then(res => {
      //     this.design = res
      //     this.title = this.design.code ? this.design.code : '商品详情'
      //     document.title = this.design.code
      //     let goods = {}
      //     res.items.forEach(item => {
      //         if (!goods[item.position_label]) {
      //             goods[item.position_label] = []
      //         }
      //         goods[item.position_label].push(item)
      //     })
      //     this.goodsList = goods
      // })
    },
    // changeDesign(id) {
    //     this.$router.replace('/goods/' + this.$store.getters.shop_id + '/' + id)
    // },
    // tagTo(url) {
    //     if (!url || url.length === 0 || url === undefined || url === 'undefined') {
    //         return false
    //     }
    //     if (url.startsWith('http')) {
    //         window.location.href = url
    //     } else {
    //         this.$router.push(url)
    //     }
    // },
    // showLabelValue(item) {
    //     let value = ''
    //     if (item.product_code) {
    //         value += item.product_code
    //     }
    //     if (item.strap_dir_label) {
    //         value += ' ' + item.strap_dir_label
    //     }
    //     if (item.open_style_label) {
    //         value += ' ' + item.open_style_label
    //     }
    //     if (item.install_craft_label) {
    //         value += ' ' + item.install_craft_label
    //     }
    //     if (item.process_type_label) {
    //         value += ' ' + item.process_type_label
    //     }
    //     if (item.status_label) {
    //         value += ' ' + item.status_label
    //     }
    //     if (item.unit_label) {
    //         value += ' ' + item.unit_label
    //     }
    //     return value
    // },
    addCart(res) {
      this.isShowCartInfoPop2 = true
      this.active = ''
    }
  }
}
</script>
<style>
.cell-value {
  text-align: left;
}

.series-image {
  text-align: left;
}

.width-left {
  -webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
  width: 30%;
}

.design-desc {
  padding: 0.2rem 0.5rem;
}

.design-desc * {
  max-width: 100%;
}

.design-desc img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.van-sku-actions .van-button--danger {
  background: -webkit-linear-gradient(left, #07c160, #07c160);
  background: linear-gradient(to right, #07c160, #07c160);
}
</style>
