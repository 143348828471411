/** @format */

import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import Vuex from 'vuex'
import router from './router'
import device from 'current-device'
import VueDraggableResizable from 'vue-draggable-resizable'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.prototype.$device = device
import {
    Badge,
    Dialog,
    DatetimePicker,
    Toast,
    Field,
    Col,
    Divider,
    Row,
    Button,
    Cell,
    Checkbox,
    CellGroup,
    Card,
    Form,
    Grid,
    GridItem,
    Image,
    ImagePreview,
    Icon,
    ShareSheet,
    Loading,
    List,
    Lazyload,
    NavBar,
    NumberKeyboard,
    Panel,
    Popup,
    Picker,
    Search,
    Sku,
    Sticky,
    Swipe,
    Switch,
    SwipeItem,
    Sidebar,
    SidebarItem,
    Tabs,
    Tab,
    Tag,
    Tabbar,
    TabbarItem,
    Uploader,
    Empty,
    RadioGroup,
    Radio,
    CheckboxGroup,
    SubmitBar,
    Skeleton,
    Stepper,
    NoticeBar,
    ContactCard
} from 'vant'

Vue.use(Grid)
.use(ContactCard)
.use(NoticeBar)
    .use(Stepper)
    .use(SubmitBar)
    .use(Empty)
    .use(Skeleton)
    .use(CheckboxGroup)
    .use(Divider)
    .use(Dialog)
    .use(DatetimePicker)
    .use(Toast)
    .use(Field)
    .use(Checkbox)
    .use(Form)
    .use(Row)
    .use(Button)
    .use(Cell)
    .use(Col)
    .use(CellGroup)
    .use(Card)
    .use(GridItem)
    .use(Image)
    .use(ImagePreview)
    .use(Icon)
    .use(Switch)
    .use(RadioGroup)
    .use(Radio)
    .use(Loading)
    .use(List)
    .use(Lazyload, {
        lazyComponent: true
    })
    .use(ShareSheet)
    .use(NavBar)
    .use(NumberKeyboard)
    .use(Panel)
    .use(Popup)
    .use(Picker)
    .use(Search)
    .use(Sku)
    .use(Swipe)
    .use(Sticky)
    .use(SwipeItem)
    .use(Sidebar)
    .use(SidebarItem)
    .use(Tabbar)
    .use(Tabs)
    .use(Tab)
    .use(Tag)
    .use(Badge)
    .use(Vuex)
    .use(TabbarItem)
    .use(Uploader)


Vue.config.productionTip = false
function loadJS(url, callback) {

    var script = document.createElement('script'),

        fn = callback || function() {};

    script.type = 'text/javascript';



    //IE

    if (script.readyState) {

        script.onreadystatechange = function() {

            if (script.readyState == 'loaded' || script.readyState == 'complete') {

                script.onreadystatechange = null;

                fn();

            }

        };

    } else {

        //其他浏览器

        script.onload = function() {

            fn();

        };

    }

    script.src = url;

    document.getElementsByTagName('head')[0].appendChild(script);

}
if (window.location.href.includes("mode=dev")) {
    loadJS("https://cdnjs.cloudflare.com/ajax/libs/vConsole/3.13.0/vconsole.min.js", function() {
        var vConsole = new VConsole();
        console.log('Hello world');


    });

}
/**
 * Vue.prototype.$http = axios
 */
// axios.defaults.baseURL = 'https://lian.suishou.cc/api/'
// axios.defaults.baseURL = 'http://lian-dev.suishou.cc/api/'
axios.defaults.baseURL = !window.location.protocol.includes("file") ? process.env.VUE_APP_baseURL : "http://" + process.env.VUE_APP_baseURL;
const { origin, host } = window.location;
if (origin.includes("zhilian")) {
    axios.defaults.baseURL = "//" + host + "/api8/"
}
if(window.location.protocol.includes("file")){axios.defaults.baseURL = 'http://lian3dev.suishouecai.com/api/'}
// axios.defaults.baseURL = 'http://zhilian3.suishou.cc/api8/'
axios.defaults.timeout = 30000
    // axios.defaults.withCredentials =true
Vue.prototype.$loading = false
Vue.prototype.$toast = Toast;
Vue.prototype.$baseURL = axios.defaults.baseURL;
axios.interceptors.request.use(
    function(config) {
      
        if(store.getters.confirmorderid&&config.headers["Content-Type"]!='multipart/form-data'){
            if(config.method=='post'||config.method=='patch'){
                config.data ={
                    ...config.data,
                    edit:'edit'
                }
            }else if(config.method=='get'||config.method=='delete'){
                config.params ={
                    ...config.params,
                    edit:'edit'
                }
            }
        }
      
        if (!config.hidden_toast) {

            Vue.prototype.$loading = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: config.message || '加载中'
            })
        }
        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + store.getters.token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config
    },
    function(error) {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    ({ data ,headers,url,config}) => {
        if(config.url.includes('/clients/designs')){
           
            if(parseInt(data.data.isLogin)==0&&!isNaN(parseInt(data.data.isLogin))){
                store.commit('SET_TOKEN', '') 
            }
        }
        Toast.clear()
        if (data.code === 999) {
            store.commit('SET_TOKEN', '')
            router.replace('/login')
        } else if (data.success !== true && data.message == '非法访问') {
            if (Vue.isFobbidenModal) {
                return
            }
            Vue.isFobbidenModal = true;
            Dialog.alert({
                title: '提示',
                showCancelButton: true,
                message: "您确定要退出当前登录，进入新的店铺",
                theme: 'round-button',
            }).then(() => {
                Vue.isFobbidenModal = false;
                store.dispatch('StorageClear');
                window.location.reload();
            }).catch(() => {
                Vue.isFobbidenModal = false;
                let { shop_id } = JSON.parse(localStorage.getItem("user") || '{}');
                store.commit('SET_QUERY_SHOP_ID', shop_id)
                localStorage.setItem("queryShopId", shop_id)
                router.replace('/shop/' + shop_id)
            })
        } else if (data.success !== true) {
            Dialog.alert({
                title: '提示',
                message: data.message ? data.message : '系统错误'
            }).then(() => {
                if (data.code === 999) {
                    router.replace('/login')
                } else if (data.err === 500) {
                    // 需要刷新的错误代码
                    router.replace('/login')
                }else{
                  
                }
            })
            // return data
        }

        return data.data
    },
    error => {
        if (router.history.current.name == 'land') {
            return
        }
        Vue.prototype.$loading = false
        Toast.clear()
        if (error.code === 'ECONNABORTED') {
            Dialog.alert({
                title: '错误',
                message: '请求超时，请检查网络连接或刷新重试'
            }).then(() => {
                // 需要刷新的错误代码
                window.location.reload()
            })
            return Promise.reject(error)
        }
        if (error.response.data.code === 999) {
            // store.commit('SET_TOKEN', '')
            // router.replace('/login')
            Dialog.confirm({
                title: '请重新登录',
                confirmButtonText: '去登录'
            }).then(() => {
                store.commit('SET_TOKEN', '')
                router.replace('/login')
            })
            return Promise.reject(error)
        }
        if (error.response) {
            console.log('----response----')
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
            console.log('----response----')
        }
        console.log('--------')
        console.dir(error)
        console.dir(error.headers)
        console.dir(error.code)
        console.dir(error.status)
        console.dir(error.message)
            // console.log(error.toJSON());
        console.log('--------')
        Toast.clear()
        Dialog.alert({
            title: '错误',
            message: error ? error : '系统错误'
        }).then(() => {
            // 需要刷新的错误代码
            router.replace('/shop/' + store.getters.shop_id)
        })
        return Promise.reject(error)
    }
)
Vue.prototype.$http = axios
    // 状态管理
const store = new Vuex.Store({
    state: {
        confirmorder:{
            vouchers:[],
            comment:"",
            sale_discount:""
        },
        menutexts:{},
        price_ratio:1,
        only_access_specify_design:false,
        token: localStorage.getItem('token'),
        user: localStorage.getItem('user'),
        shop: sessionStorage.getItem('shop'),
        shopId: sessionStorage.getItem('shopId'),
        from_id: sessionStorage.getItem('from_id'),
        queryShopId: localStorage.getItem('queryShopId')
    },
    getters: {
        token: state => (state.token ? state.token : ''),
        confirmorder: state => (state.confirmorder ? state.confirmorder : {}),
        user: state => {
            try {
                let user = state.user ? JSON.parse(state.user) : {}
                user.id = user.id ? user.id : 0
                user.shop_id = user.shop_id ? user.shop_id : 0
                user.client_id = user.client_id ? user.client_id : 0
                user.name = user.name ? user.name : ''
                user.email = user.email ? user.email : ''
                user.mobile = user.mobile ? user.mobile : ''
                user.type = user.type ? user.type : ''
                user.status = !!user.status
                user.process_steps = user.process_steps ? user.process_steps : []
                user.store_name = user.store_name ? user.store_name : '智帘'
                return user
            } catch (err) {
                sessionStorage.clear()
                Dialog.alert({
                    title: '登录失效',
                    message: '登录失效,请重新登录',
                    confirmButtonText: '去登录'
                }).then(() => {
                    store.dispatch('Logout')
                    router.replace('/login')
                })
            }
        },
        shop: state => {
            try {

                let shop = state.shop ? JSON.parse(state.shop) : {}
                shop.id = shop.id ? shop.id : 0
                shop.name = shop.name ? shop.name : ''
                shop.phone = shop.phone ? shop.phone : ''
                shop.config = shop.config ? shop.config : ''
                shop.contact_person = shop.contact_person ? shop.contact_person : ''
                shop.public_mode = !!shop.public_mode

                return shop
            } catch (err) {
                sessionStorage.clear()
                console.log("店铺信息获取失败333",err);
                Dialog.alert({
                    title: '店铺信息错误',
                    message: '店铺信息获取失败,请先登录',
                    confirmButtonText: '去登录'
                }).then(() => {
                    store.dispatch('Logout')
                    router.replace('/login')
                })
            }
        },

        price_ratio:state => (store.getters.shop.config.price_ratio||10),
      
        mode: state => store.getters.shop.public_mode,
        shopId: state => (store.getters.shop.id ? store.getters.shop.id : ''),
        from_id: state => (state.from_id ? state.from_id : ''),
        queryShopId: state => (state.queryShopId ? state.queryShopId : ''),
        shop_id: state => (store.getters.queryShopId ? store.getters.queryShopId : store.getters.shopId)
    },
    mutations: {

        SET_TOKEN: (state, token) => {
            state.token = token
            localStorage.setItem('token', token)
            console.log('token:', token)
        },
        SET_CONFIRM_ORDER:(state,info={
            vouchers:[],
            comment:""
        })=>{
           state.confirmorder = info;
        },
        SET_FROM_ID: (state, from_id) => {
            state.from_id = from_id
            sessionStorage.setItem('from_id', from_id)
            console.log('from_id:', from_id)
        },
        SET_USER: (state, user) => {
            if (user) {
                try {
                    state.user = JSON.stringify(user)
                    localStorage.setItem('user', state.user)
                } catch (err) {
                    console.log('user_err:', err)
                }
            }
            console.log('user:', user)
        },
        SET_SHOP: (state, shop) => {
            if (shop) {
                try {
                    state.shop = JSON.stringify(shop)
                    sessionStorage.setItem('shop', state.shop)
                    sessionStorage.setItem('shopname', shop.name)
                } catch (err) {
                    console.log('shop:', shop)
                    console.log('shop_err:', err)
                }
            }
        },
        SET_QUERY_SHOP_ID: (state, queryShopId) => {
            if (queryShopId) {
                state.queryShopId = queryShopId
                localStorage.setItem('queryShopId', queryShopId)
            }
            console.log('queryShopId:', queryShopId)
        }
    },
    actions: {
        LoginWithOpenid:({ dispatch },openid)=>{
            axios.post('/user/autologin',{openid,shop_id:store.getters.shop_id}).then(res => {
                if (res&&res.user!="no user"&&res.api_token) {
                    dispatch('LoginSuccess', res)
                }
            })
        },
        Login: ({ dispatch }, userInfo) => {
            axios.post('/user/codelogin', userInfo).then(res => {
                if (res) {
                    dispatch('LoginSuccess', res)

                }
            })
        },
        LoginSuccess({ commit }, res) {

            commit('SET_TOKEN', res.api_token)
            commit('SET_QUERY_SHOP_ID', res.shop_id)
            commit('SET_USER', res)
            commit('SET_FROM_ID', res.id)

            // localStorage.setItem("balance",res.client.deposit_balance)
            if (wx && wx.miniProgram) {
                wx.miniProgram.postMessage({ data: { shop_name: res.shop_name } })
            }
            axios.get('/clients/carts/num').then(res => {
                if (res) {
                    localStorage.setItem('cartcount', res)
                }

            })
            router.replace('/shop/' + store.getters.shop_id)
        },
        GetShop({ commit, state }) {
      
            if (store.getters.shop_id && store.getters.shop_id != 'land') {
                let shop_id = store.getters.shop_id

                return axios.get('/clients/shops/' + shop_id, {
                    hidden_toast: true
                }).then(res => {
                    if (!res || !res.id) {
                        Dialog.alert({
                            title: '店铺信息错误',
                            message: res.message||'店铺信息获取失败,请先登录',
                            confirmButtonText: '去登录'
                        }).then(() => {
                            store.dispatch('Logout')
                            router.replace('/login')
                        })
                    }
                    commit('SET_SHOP', res)

                    if (router.currentRoute.name === 'index') {
                        document.title = res.name
                    }
                })
            }
        },
        StorageClear({ commit, state }) {
            let shopId = store.getters.shop_id
            sessionStorage.clear()
            localStorage.clear()
            window.sessionStorage.clear()
            window.localStorage.clear()
            if (shopId) {
                commit('SET_QUERY_SHOP_ID', shopId)
            }
        },
        Logout: ({ commit, state }) => {
            Toast.clear()
            let is_login = store.getters.token
            store.dispatch('StorageClear')
            localStorage.setItem('cartcount', '')
            commit('SET_TOKEN', '')
            if (is_login) {
                Dialog.alert({
                    title: '提示',
                    message: '您已经退出登录'
                }).then(() => {
                    router.replace('/login')
                })
            } else {
                router.replace('/login')
            }
        }
    }
})
var _hmt = _hmt || []
window._hmt = _hmt // 修改为window 全局变量
;
(function() {
    var hm = document.createElement('script')
    hm.src = "https://hm.baidu.com/hm.js?fd16fe48f6f4f2c88f0465a5be77a0c6"
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
})()

router.beforeEach((to, from, next) => {
    Vue.prototype.$loading = false;
    if(!store.getters.token&&store.getters.shop_id){
        var openid = getQueryVariable("openid")||undefined;
        if(openid){
          sessionStorage.setItem("openid",openid)
          store.dispatch("LoginWithOpenid",openid)
        }

     }
    if (to.path === '/login') {
        // sessionStorage.clear()
        if (store.getters.token && store.getters.shop_id) {
            next({
                path: '/shop/' + store.getters.shop_id
            })
            return false
        }
        // store.dispatch('StorageClear')
        next()
    } else if (to.meta.is_login && !store.getters.token) {
        Dialog.confirm({
            title: '请先登录',
            confirmButtonText: '去登录'
        }).then(() => {
            store.dispatch('Logout')
            next({
                path: '/login'
            })
        })
    } else {
        // from_id
        if (from.query.from_id && store.getters.from_id != from.query.from_id) {
            store.commit('SET_FROM_ID', from.query.from_id)
        } else if (to.query.from_id && store.getters.from_id != to.query.from_id) {
            store.commit('SET_FROM_ID', to.query.from_id)
        }
        // shop_id
        if (from.query.shop_id && store.getters.queryShopId != from.query.shop_id) {
            store.commit('SET_QUERY_SHOP_ID', from.query.shop_id)
        } else if (to.query.shop_id && store.getters.queryShopId != to.query.shop_id) {
            store.commit('SET_QUERY_SHOP_ID', to.query.shop_id)
        } else if (to.params.shop_id && store.getters.queryShopId != to.params.shop_id) {
            store.commit('SET_QUERY_SHOP_ID', to.params.shop_id)
        } else if (from.params.shop_id && store.getters.queryShopId != from.params.shop_id) {
            store.commit('SET_QUERY_SHOP_ID', from.params.shop_id)
        } else if (!store.getters.shop_id && from.path.indexOf("clients") != -1) {
            Dialog.alert({
                title: '店铺未知',
                message: '请登录后访问',
                confirmButtonText: '去登录'
            }).then(() => {
                store.dispatch('Logout')
                next({
                    path: '/login'
                })
            })
            return false
        }
        if (!to.params.shop_id && store.getters.shop_id && ['default', 'index'].indexOf(to.name) !== -1) {
            next({ path: '/shop/' + store.getters.shop_id, replace: true })
        } else if (!to.query.from_id && store.getters.from_id) {
            let query = to.query
            query.from_id = store.getters.from_id;
            next({
                    path: to.path,
                    query: to.query
                })
                // next({
                //     path: to.path + '?from_id=' + store.getters.from_id
                // })
        } else if (!store.getters.shop || store.getters.shop.id != store.getters.shop_id) {
            store.dispatch('GetShop')
        }
        // mode === 1时 任何人都可以访问所有不需要登录的页面
        // mode === 0时 必须登录 并且 shop_id === queryShopId 才能访问所有页面
        if (store.getters.shop && store.getters.shop.id && !store.getters.mode && ['default', 'index'].indexOf(to.name) !== -1) {
            if (!store.getters.token) {
                Dialog.confirm({
                    title: '隐私模式',
                    message: '请登录后，再访问其他页面',
                    confirmButtonText: '去登录'
                }).then(() => {
                    store.dispatch('Logout')
                    next({
                            path: '/login',
                            replace: true
                        }) // on confirm
                })
                return false
            } else if (store.getters.shop_id != store.getters.shop.id) {
                Dialog.alert({
                    title: '隐私模式',
                    message: '只能访问当前店铺'
                })
                return false
            }
        }
        if (window._hmt && to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
        window.scrollTo(0, 200);
        if(!to.path.includes('confirmOrder')&&!to.path.includes('address')){
              store.commit('SET_CONFIRM_ORDER')
        }
        next()
    }
})
router.afterEach((to, from, next) => {
        Vue.prototype.$loading = false
        if (to.name != 'index') {
            let v = to.meta.title;
            let ss = sessionStorage.getItem("confirmorderid")
            if(to.name=='cart'&&ss&&ss!='undefined'){
               v ='临时单修改'
            }else{
              v = v ?store.state.menutexts[v]?store.state.menutexts[v]:v : to.name;

            }
            document.title = v
        }

    })
    // try {
    //     var shop_id2 = window.location.hash.split("?")[0].split("/")[2];
    //     if (shop_id2) {
    //     axios.get('/clients/shops/' + shop_id2).then(res => {
    //         if (res) {
    //             // if (router.currentRoute.name === 'index') {
    //                 document.title = res.name
    //                 // state.user = JSON.stringify(res.user)
    //                 localStorage.setItem('shopname', res.name)
    //             // }
    //         }

//     })
//     }
// } catch (error) {

// }
function getQueryVariable(variable)

{

       var query = window.location.hash.substring(1);

       var vars = query.split("&");
       var ret = false;
       for (var i=0;i<vars.length;i++) {

                var pair = vars[i].split("=");
               if(pair[0].includes(variable)){
                ret =  pair[1];
                break;
                }

       }

       return ret;

}
Vue.prototype.$setMenuText =(callback)=>{
    let that = this;
    let config = JSON.parse(sessionStorage.getItem("shop")||'{}').config||{}
   
    if(Object.keys(config).length>0){
        store.state.menutexts =config.smart_curtain_alias
       
       
         callback &&callback(config.smart_curtain_alias);
    }else{
     
        store.dispatch("GetShop").then((res)=>{
         config = JSON.parse(sessionStorage.getItem("shop")||'{}').config||{}
         store.state.menutexts =config.smart_curtain_alias
        
        callback &&callback(config.smart_curtain_alias);
    })
    }
}
Vue.prototype.getQueryVariable = getQueryVariable;
Vue.prototype.$can = permission => {
    return store.getters.permissions.indexOf(permission) > -1
}
Vue.prototype.price_ratio =(v,type)=>{
    if(store&&!store.getters.token){
     v = Number((store.getters.shop.config.price_ratio||1)*v)||(type==2?0:'');
    }
    if(store&&store.getters.shop.config.smart_curtain_sale_price_odd=='round'){
       v = Math.round(v)
    }
    if(store&&store.getters.shop.config.smart_curtain_sale_price_odd=='floor'){
        v = Math.floor(v)
        
    }
    return v;

 }

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')