<!-- @format -->

<template>
  <div>
     <van-popup
                      position="bottom"
                         :style="{ height: '60%',overflow:'hidden' }"
                     v-model="productModalshow"
                    >
                      <van-search
                        v-model="keyWord"
                        placeholder="请输入搜索关键词"
                          @clear="onCancel"
                        @change="onSearch"
                      />
                    
                      <van-list
                      offset="10"
                      style="display:flex;flex-wrap:wrap;height:calc(100% - 98px);overflow-y: auto;    padding: 0 10px;"
                       v-model:loading="loading"
                      :finished="finished"
                      :finished-text="Productlist.length>0?'没有更多了':'没有数据'"
                      @load="onLoad"
                >
           <div @click="setActiveProduct(index)" :class="activeProductIndex==index?'img-item active':'img-item'" v-for="(item,index) in  Productlist">
                          <img  v-if="item._text.attach&&item._text.attach!=null" :src="'/storage/'+item._text.attach"/>
                            <img  v-else />
                          <div style="text-align:center;max-height: 42px;overflow: hidden;">{{item.text}}</div>
                     </div> 
    </van-list>
                   
                   
                 
                       <div style="display:flex;">
                     <van-button :disabled="activeProductIndex==-1" style="flex:1"  @click="setPosItem2" type="primary" size="normal">确定</van-button>
                      <van-button style="flex:1" plain @click="productModalshow=false;activeProductIndex=-1" type="default" size="normal">取消</van-button>
                       </div>
                    
              </van-popup>
    <van-popup
      v-model="show"
     :overlay="true"
        class="create-order ssss"
     :close-on-click-overlay="clickOverlay"
      get-container="body"
      position="bottom"
     :style="{ height: '90%' }"
      @opened="init"
    >
    <van-sticky  offset-top="10vh">
      <van-row   style="margin-top: 10px;">
        <van-col span="7">
          <van-image style="width: 100%; margin: 3px" :src="design.img||cart_info.design_url" />
        </van-col>
        <van-col span="15" style="padding: 5px 0 5px 15px">
           <!-- <div style="color: #9e9e9e;    font-size: 14px;">外帘：{{cart_info.total_price_label|| (design.price_wailian?('¥ '+design.price_wailian):'')}}</div> -->
          <div style="color: #777;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;">款式：{{ design.code ||cart_info.design_code}}</div>
          <div style="display: flex; justify-content: flex-start">
            <van-tag
              :key="tag"
              style="display: flex; margin-left: 5px"
              v-if="design.tags_label && design.tags_label.length"
              v-for="tag in design.tags_label"
             
              >{{ tag }}</van-tag
            >
          </div>
        </van-col>
        <van-col span="2">
          <van-col class="close-wrapper" span="2">
          <van-icon name="cross" class="close-icon" @click="goBack2" />
        </van-col>
        </van-col>
      </van-row>
      </van-sticky>
      <van-form
        class="cart_form"
      
        style="margin-bottom: 50px; padding-bottom: 30px"
      >
      
      
           <template v-if="init_cart">
         <template v-for="item in options.positions">
            <van-cell-group
              class="position"
              v-if="
                tmp_pos_items[item['key']] &&
                tmp_pos_items[item['key']].length > 0
              "
              :key="item['key']"
            >
              <template>
                <div class="header">
                  <div style="display:flex;position:relative;height: 30px;width:100%">
                  <div class="pos-name"> {{ item["text"] }}</div>
                 
                  <div class="check-pos">
                  <van-checkbox
                    v-model="checked[item['key']]"
                    @click="updateCheck(item['key'])"
                    shape="square"
                    icon-size="16px"
                    style="color: #646566; font-size: 12px"
                  >
                   
                  </van-checkbox>
                  <div  v-if="cart['pos_price'][item['key']]" style="color:#e73333">{{ cart["pos_price"][item["key"]] }}{{design['pos_price_'+item['key']]}}</div>
                   </div>
                  </div>
                  <div></div>
                  <!-- <div  v-if="checked[item['key']]" style="font-size: 12px">
                    小记： {{ cart["pos_subtotal_label"][item["key"]] }}
                  </div> -->
                </div>
              </template>
              <div v-if="checked[item['key']]" class="border-color">
              <template   v-if="isReady">
            <div v-if="widgets.name==item['key']" :key="index2+'fieldset'" v-for="(widgets,index2) in fieldsets" >
             <!-- <van-divider v-if="Object.keys(fieldsets).length>1" :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }" contentPosition="left">{{widgets.label}}</van-divider> -->
            <div :key="index+'components'" v-for="(component,index) in widgets.items" class="section">
            <div class="label">{{component.label}}{{component.element&&component.element[0].tag=='multi-select'?'（多选）':''}}</div>
           <div v-if="component.element&&component.element[0].tag=='input'" class="aaa">
                <template v-for="$item in component.element">
                  <!--  -->
                <input 	@blur="setCartText2(widgets.name,widgets.label,component.label,$item['v_model'])" @input="setCartText(widgets.name,widgets.label,component.label,$item['v_model'])"  v-model="cart1[widgets.name][$item['v_model']]" :max="$item.max||1000000" :min="$item.min||0" :type="$item.type||'text'" :required="$item.required"    name="$item['v_model']"  :placeholder="$item.placeholder||'请输入'"></input> 
                 <div class="error-message" v-if="$item.max&&(parseFloat(cart1[widgets.name][$item['v_model']])>parseFloat($item.max))">不能超过最大值{{$item.max}}</div>
                 <div class="error-message" v-if="$item.min&&(parseFloat(cart1[widgets.name][$item['v_model']])<parseFloat($item.min))">不能小于最小值{{$item.min}}</div>
                 </template>
            </div>
             <div v-if="component.element[0]&&component.element[0].tag=='textarea'" class="aaa">
                <textarea @input="setCartText(widgets.name,widgets.label,component.label,$item['v_model'])" style="width:80%" :required="$item.required" v-for="$item in component.element" v-model="cart1[widgets.name][$item['v_model']]" name="$item['v_model']"  :placeholder="$item.placeholder||'请输入'"></textarea> 
            </div>
            <div v-if="component.element[0]&&component.element[0].tag=='select'" class="aaa">
              <van-tag
                :key="item.value"
                size="medium"
                @click="setActive(widgets.name,component.element[0].v_model, item,widgets.label,component.label)"
                v-for="(item, index) in component.element[0].items"
                :class="
                  cart1[widgets.name][component.element[0].v_model] == item.value
                    ? 'aactive'
                    : '#F2F2F2'
                "
                tag-padding="10"
                >{{ item.label }}</van-tag>
            </div>
            <div v-if="component.element[0]&&component.element[0].tag=='tag-mulite-select'" class="aaa">
              <van-checkbox-group
                v-if="component.element[0].items.length>0"
                direction="horizontal"
                v-model="cart1[widgets.name][component.element[0].v_model]"
             
              >
                <van-checkbox
                  :key="item.value"
                  v-for="item in component.element[0].items"
                  :name="item.value"
                  >{{ item.label }}</van-checkbox>
              </van-checkbox-group>
               </div>
          </div>
           </div>
        </template>
              
               
              <van-grid style="    margin-top: 10px;" v-if="checked[item['key']]" column-num="4">
                <van-grid-item :text="item['text']" />
                <van-grid-item text="货号" />
                <van-grid-item text="单价" />
                 <van-grid-item text="用料" />
              </van-grid>
          
              <van-grid
                v-if="checked[item['key']]"
                column-num="4"
                v-for="(pos, index) in tmp_pos_items[item['key']]"
                ref="pos"
                :key="index"
              >
            
                <van-grid-item>
                  <template>
                    <van-field
                      readonly
                      clickable
                      input-align="center"
                      v-model="tmp_pos_items[item['key']][index]['curtain']"
                    />
                  
                    <van-popup position="bottom" v-model="pos['curtain_show']">
                      <van-picker
                        show-toolbar
                        :columns="options.curtain_types"
                        @cancel="
                          onPosItemClick(item['key'], index, 'curtain', false)
                        "
                        @confirm="
                          (val) =>
                            setPosItem(val, item['key'], index, 'curtain')
                        "
                      />
                    </van-popup>
                    <!-- <span style="color: #323233;font-size:10px;">单价：{{ pos['price'] }}</span> -->
                  </template>
                </van-grid-item>
              
                <van-grid-item>
                  <template>
                    
                        <span
                      readonly
                      clickable
                      input-align="center"
                      style="    width: 70px;font-size: 12px;"
                        @click="
                        () =>  show_popup(item['key'], index, 'product_show',pos['product_id'])
                      "
                     
                    >{{tmp_pos_items[item['key']][index]['product']}}</span>
                       <van-icon v-if="allow_toggle_product" style="    position: absolute;
    right: 5px;
    color: #bbb;
    font-size: 10px;" name="arrow-down" />
                  </template>
                </van-grid-item>
                <van-grid-item>
                  <template>
                  <label>
                    {{price_ratio2(tmp_pos_items[item['key']][index]['price'])}}
                   </label>
                    <!-- <van-field
                      v-model="tmp_pos_items[item['key']][index]['price']"
                      @change="update(tmp_pos_items[item['key']][index])"
                      type="number"
                      :readonly="userType==0"
                      input-align="center"
                      :formatter="fix2"
                      placeholder="--"
                    /> -->
               
                  </template>
                </van-grid-item>
                <van-grid-item>
                  <template>
                    <van-field
                      v-model="tmp_pos_items[item['key']][index]['quantity']"
                      @change="updateFoldNum(item['key'], index)"
                      type="number"
                       :readonly="true"
                      input-align="center"
                      :formatter="fix2"
                      placeholder="--"
                    />
                                   <span style="    font-size: 12px;
    position: absolute;
    right: 5px">{{pos_items[item['key']][index].unit_label}}</span>
                  </template>
                </van-grid-item>
              </van-grid>
              <!-- <div v-if="checked[item['key']]&&userType" class="buwei-desc-wrapper">
                <input @blur="updateField"  v-model="cart.pos_comment[item['key']]"   class="buwei-desc" type="text" placeholder="部位备注，选填" />
              </div> -->
               </div>
            </van-cell-group>
          </template>
           </template>
          <!-- {{cart}} -->
      </van-form>
      <van-tabbar active-color="#7d7e80" :safe-area-inset-bottom="true">
        <van-tabbar-item @click="goBack2" icon="replay">返回</van-tabbar-item>
        <!-- <van-tabbar-item @click="$router.replace('/cart')" icon="shopping-cart">购物车</van-tabbar-item> -->
        <van-tabbar-item
          @click="onSubmit"
          style="
            color: #fff;
            background-color: #07c160;
            border: 1px solid #07c160;
          "
          >加入购物车</van-tabbar-item
        >
        <van-tabbar-item @click="calc" style="flex:1;padding: 0 10px;position:relative;font-size: 18px;">
             <img style="width: 20px;
    height: 20px;
    position: absolute;
    left: 5px;
    top: 12px;" src="/jisuanqi.png" />
          <template #icon="props">
            <span :formatter="fix2">¥{{ price_ratio2(cart.total_price) }}</span
            >
          </template>
          计算
        </van-tabbar-item>
      </van-tabbar>
    </van-popup>
  </div>
</template>

<script>
import { Dialog ,Divider,Stepper} from 'vant';
var scalarArrayEquals = function (array1 = [], array2 = []) {
  return (
    array1.length == array2.length &&
    array1.every(function (v, i) {
      return v === array2[i];
    })
  );
};
var isClick2 = true;
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    design: {
      type: Object,
      default: {},
    },
    cart_info: {
      type: Object,
      default: {},
    },
    hideCartInfoPopup:()=>{}
  },
  data() {
    return {
      mode:"a",
      allow_toggle_product:false,
       curpage:1,
       loading:false,
      finished:false,
      activeProductIndex:-1,
       productModalshow:false,
      item_key:"",
      item_index:0,
       fieldsets:[],
       clickOverlay:false,
      userType:1,
      isReady:false,
      last_pos_extend: {},
      _customer_locations: "",
      customer_locations: "",
      editStatus: false,
      pos_comment: {},
      selected_extend_check: {},
      pos_strap_dir: {},
      pos_fold_gap: {},
      pos_open_style: {},
      
      pos_craft: {},
      pos_width: {},
      pos_height: {},
      selectedTag: {
        install_locations: "",
        fold_times: "",
        process_types: "",
      },
      keyWord: "",
      action: "",
      initCount: 0,
      Productlist:[],
      showPickerList: {},
   
      PosItemlist: {},
      pos_items: {},
      imgList: [],
      options: {
        order_extend_check: [],
        fold_times: [],
        curtain_types: [],
        install_crafts: [],
        install_locations: [],
        open_styles: [],
        craft_wailian: [],
        craft_shalian: [],
        positions: [],
        process_types: [],
        strap_dirs: [],
      },
      tmp_val: {
        checked: {},
      },
      checked: {},
      init_cart: {
        pos_items: {},
      },
      tmp_pos_items: {},
      order_extend_check: [],
      cart1:{
        dict:{}
      },
      cart: {
        
        pos_comment:{},
        pos_price:{},
        pos_width:{},
         fold_times: "",
        total_price: 0,
        fold_gap: "",
        window_width: "",
        window_height: "",
        pos_price: {},
        pos_quantity: {},
        pos_subtotal: {},
        pos_subtotal_label: {},
        pos_items: {},
        pos_width: {},
        pos_height: {}
     
      },
    };
  },
  watch: {
    "cart.pos_extend_check": function (a, b) {
      // var isEquals =  compare(a,b)
      //   !isEquals&&
      // throttle(,400,{begin:true})();
      // this.update("api_update")
    },
    // "cart.order_extend_check": function (a, b) {
    //   var isEquals = scalarArrayEquals(a, b);
    //   // !isEquals && this.update("api_update");
    // },
  },
  computed: {
    order_extend_check_all() {
      this.options.order_extend_check = this.options.order_extend_check || [];
      return this.options.order_extend_check.filter((item) => {
        return !item.text.position;
      });
    },
    is_show: {
      get: function () {
        return this.show;
      },
      set: function () {
       
        this.$parent.isShowOtherCartInfoPop = false;
        if(this.$parent.$parent.$parent.$parent){
       this.$parent.$parent.$parent.$parent.isShowOtherCartInfoPop =false
        }
       
      },
    },
  },
 destroyed(){
    if(this.is_show){
      this.goBack2();
       window.removeEventListener("beforeunload",this.goBack2)
    }
      
  },
  methods: {
      price_ratio2(v=''){
      if(v&&v!=0&&v!=''){
         v=v+''
        v = parseFloat(v.replace("¥",""))
        v = Number(v* (this.cart.price_ratio||1)).toFixed(2);
          if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='round'){
       v = Math.round(v)
       
    }
    if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='floor'){
        v = Math.floor(v)
         
    }
       return v;
      }else if(v==0){
        return 0
      }else if(v==''||v==null){
        return ''
      }
    },
      onCancel(){
         this.Productlist =[];
            this.curpage =1;
            this.keyWord = '';
            this.activeProductIndex =-1;
          this.getProducts();
    },
    onLoad(e){
      
      if(this.Productlist.length>0){
       this.curpage ++
       this.getProducts(this.productcode);
      }
     
    },
      setActiveProduct(index){
    
       this.activeProductIndex = index;
    },
      updateFoldNum(key1, key2) {
     
      let value = this.tmp_pos_items[key1][key2]["fold_num"];
      let tmp_pos_items_quantity = this.tmp_pos_items[key1][key2]["quantity"];
      if (
        this.cart.fold_gap &&
        !isNaN(this.cart.fold_gap) &&
        value &&
        !isNaN(value)
      ) {
        tmp_pos_items_quantity = this.fix2(
          value * this.cart.fold_gap
        );
        
      }
      
      
      this.update("api_update",true).then(()=>{
         this.tmp_pos_items[key1][key2]["quantity"] =tmp_pos_items_quantity;
         this.$forceUpdate();
         this.$nextTick(()=>{
          this.update(this.tmp_pos_items[key1][key2]);
         })
       
      })
      
    },
     postion_extend_check(key) {
      this.options.order_extend_check = this.options.order_extend_check || [];
      return this.options.order_extend_check.filter((item) => {
        return item.text.position == key;
      });
    },
     setCartText2(widgetname,widgetlabel,componentlabel,componentname){
       
      if(event.target.type=='number'){
         event.target.value =  Math.min(parseFloat(event.target.value), parseFloat((event.target.max)));
        event.target.value =  Math.max(parseFloat(event.target.value), parseFloat((event.target.min)));
        this.cart1[widgetname][componentname] =  event.target.value;
      }

    },
    setCartText(widgetname,widgetlabel,componentlabel,componentname){
     
    
       this.cart1.dict[widgetname] = widgetlabel;
       this.cart1.dict[componentname] = componentlabel;
    },
    dosave() {
      this.customer_locations = this._customer_locations;
      this.editStatus = false;
      this._customer_locations = "";
      this.cart1.install_location = this.customer_locations;
      this.selectedTag.install_locations = this.customer_locations;
    },
    setInputStatus() {
      this._customer_locations = this.customer_locations;
      this.editStatus = true;
    },
    change2(value, key) {
      // debugger
      if (
        event &&
        event.currentTarget &&
        event.currentTarget.constructor &&
        event.currentTarget.constructor.name == "XMLHttpRequest"
      ) {
        return;
      }
      if (!isClick2) {
     
        return;
      }
      // clearTimeout(timer);
      var timer = setTimeout(function () {
        isClick2 = true;
      }, 800); //一秒内不能重复点击
      isClick2 = false;
      this.cart.pos_extend_check = {
        ...this.cart.pos_extend_check,
        [key]: value,
      };
       this.$forceUpdate();
        // this.update("api_update");
    
    },
    updateField() {
      this.update("api_update");
    },
    
     getCartNum(){
      if(!this.$store.getters.token) {
        return 
      }
      //  http://lian3.suishouecai.com/api/clients/
        this.$http({url:'/clients/carts/num',method:"get",hidden_toast:true}).then(res => {
         if(res){
            localStorage.setItem('cartcount',res)
            // this.count = res
              this.$parent.count = res
         }else{
            this.count = ''
         }
         
      })
     
    },
    setActive(name,type, item,widgetlabel,componentlabel) {
       this.cart1[name] = this.cart1[name]||{};
       if(this.cart1[name][type]!=item.value){
        this.cart1.dict[name] = widgetlabel;
       this.cart1.dict[type] = componentlabel;
       this.cart1.dict[item.value] = item.label;
       this.$set(this.cart1[name], `${type}`, item.value);
        this.$forceUpdate();
       }else{
        // this.cart1.dict[name]= undefined;
        this.cart1.dict[type] = undefined;
        this.cart1.dict[item.value]= undefined;
        this.$set(this.cart1[name], `${type}`,undefined);
        this.$forceUpdate();
       }
      
      // if (type == "install_locations") {
      //   selectedTag[type] = item.text;
      //   this.cart.install_locations = item.text;
      //   this.cart.install_location = item.text;
      // } else if (type == "fold_times") {
      //   this.cart.fold_times = item.key;
      // } else if (type == "process_types") {
      
      // }
      // this.update("api_update");
    },
   
    onSearch() {
       this.activeProductIndex =-1;
      this.Productlist =[];
      this.curpage = 1;
      this.getProducts();
    },
    init() {
           

    //   let cart =  {
    //   "baiyechuang": {
    //     "anzhuang": "top",
    //     "catlog": "standard"
    //   },
    //   "guidao": {
    //     "desc": "4444444",
    //     "material": [
    //       "tong",
    //       "tie"
    //     ],
    //     "catlog": "plusLong",
    //     "anzhuang": "side1",
    //     "long": "44",
    //     "width": "444",
    //     "height": "44"
    //   },
    //   "quantity": 5
    // } 
    this.fieldsets = this.design.widgets;
    this.design.res = this.design.res||{};
    let hasKeys = Object.keys(this.design.res)
   
    // this.cart =cart;
      if(hasKeys.length>0){
       this.cart1 =JSON.parse(JSON.stringify(this.design.res));
      }else{
        this.fieldsets.forEach((item)=>{
          this.$set(this.cart1,item.name,{})
           this.cart1.dict[item.name] = item.label;
          item.items.forEach((item1)=>{
            let ele = item1.element[0];
              this.cart1.dict[ele.v_model] =  item1.label;
            if(ele.default){
              this.$set(this.cart1[item.name],ele.v_model,ele.default)
               if(ele.items&&ele.items.length>0){
                this.cart1.dict[ele.default] = (ele.items.find((item2)=>item2.value==ele.default)||{}).label;
               }
               
              // this.cart1.dict[ele.value] =;
            }
            
          })
        
        
      })
     }
      
    this.oldCartStr = JSON.stringify(this.cart1)
       this.isReady = true;
     
       this.$forceUpdate();
      // this.userType =params.get("userType") ==='0'?0:1;
      if (!this.$store.getters.token) {
        this.is_show = false;
        this.$dialog
          .confirm({
            title: "请先登录",
            confirmButtonText: "去登录",
          })
          .then(() => {
            this.$store.dispatch("Logout");
          });
        return false;
      } else {

           let shop = sessionStorage.getItem("shop") || "{}";
      //   shop ='{}';
      //  sessionStorage.setItem("shop","{}")
         if(shop!='{}'){
            shop = JSON.parse(shop)||{config:{}};
            // this.order_show_item = shop.config.order_show_item||[];
              this.allow_toggle_product = shop.config.allow_toggle_product||false;
          }else{
            this.$store.dispatch("GetShop").then(()=>{
         
               shop = sessionStorage.getItem("shop") || "{}";
               shop = JSON.parse(shop)||{config:{}};
            // this.order_show_item = shop.config.order_show_item||[];
               this.allow_toggle_product = shop.config.allow_toggle_product||false;

            })
          }
        this.getClientsOptions();
       
      }
    },
   async show_popup(key, index, type,productid) {
    
       if(type=='product_show'){
          if(!this.allow_toggle_product){
           return 
         }
          // if(productid!=this.productcode&&this.productcode){
            this.Productlist =[];
            this.curpage =1;
            this.keyWord = '';
              this.activeProductIndex =-1;
          // }
        
          if(this.Productlist.length==0){
             await this.getProducts(productid);
           }
          this.productcode = productid;
          this.productModalshow =true;
           this.item_key = key;
           this.item_index = index;
          
           
       }
      this.tmp_pos_items[key][index][type] = true;
      this.product_id = productid;
      this.$forceUpdate();
    },
    updateCheck(key) {
      // this.checked[key] = !this.checked[key]
      this.un_checked_pos = this.un_checked_pos||[]
      this.$http
        .get(
          "/clients/cart/" +
            this.init_cart.index +
            "/price?positions=" +
            this.getPositionStr()
        )
        .then((res) => {
          this.cart.total_price = res.total_price;
           if(!this.un_checked_pos.includes(key)){
             this.un_checked_pos.push(key);
         }else{
             this.un_checked_pos.splice(this.un_checked_pos.indexOf(key),1);
         }
        })
        .catch((err) => {
          this.$toast.fail("系统异常");
        });
      this.$forceUpdate();
      // this.calc()
      // this.$forceUpdate()
    },
    setPosItem2(){
        if(this.activeProductIndex==-1){
          return 
        }
        let item   = this.Productlist[this.activeProductIndex]
         this.update("api_update").then(()=>{
           this.setPosItem(item,this.item_key,this.item_index,'product_id');
         })
     
    },
    initData(res) {
      let that = this
       window.addEventListener("beforeunload",()=>{
       
         that.is_show&&that.goBack2()
       })
      if (!res) return;
      // this.initCount += 1;
      this.init_cart = res;
       this.design.res = res.res||{};
        if (!res) return;
      this.initCount += 1;
      this.init_cart = res;
      this.pos_items = res.pos_items;
      this.cart.price_ratio = res.price_ratio;
      this.cart.total_price = res.total_price_label;
      this.cart.pos_extend_check = res.pos_extend_check || {};
      this.cart.pos_comment = res.pos_comment;
      this.cart.pos_width = res.pos_width;
      this.cart.pos_height = res.pos_height;
      this.cart.pos_fold_gap = res.pos_fold_gap;
      this.cart.pos_open_style = res.pos_open_style;
      //  this.cart =JSON.parse(JSON.stringify(res.res)||{});
    Object.entries(res.pos_items ? res.pos_items : {}).forEach(
        ([key, value]) => {
            this.cart[key] =  this.cart[key]||{};
          this.tmp_pos_items[key] = [];
          
          // if (this.initCount == 1) {
            this.checked[key] = false;
               var un_checked_pos = res.un_checked_pos||[];
             
            if (!un_checked_pos.includes(key)) {
               this.checked[key] = true;
            }
          // }
        
          Object.values(value ? value : {}).forEach((v, k) => {
            let product = this.getProduct(null, v.product_id);
              //  this.$set("pos_price_"+key,res["pos_price_"+key])
            
            this.tmp_pos_items[key][k] = {
              key: v && v.key ? v.key : "",
              index: this.tmp_pos_items[key].length,
              curtain_show: false,
              curtain_type: v.curtain_type,
              curtain: this.getCurtain(null, v.curtain_type)["text"],
              product_show: false,
              product_id: v.product_id,
              product: product["text"]||v.product_code,
              quantity: v.quantity,
              price: v.price,
              subtotal: v.subtotal,
              position: key,
              fold_num: v.fold_num,
            };
          });
        }
      );
      this.$forceUpdate();
    },
    initCart() {
      if (this.design && this.design.id) {
        this.$http
          .patch("/clients/cart/apply/" + this.design.id)
          .then((res) => {
            this.initData(res);
          });
        this.action = "create";
      } else if (this.cart_info) {
        this.initData(this.cart_info);
        this.action = "update";
      }
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      //new 一个FormData格式的参数
      let params = new FormData();
      params.append("file", file.file);
      let config = {
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data",
        },
      };
      //把 uploadUrl 换成自己的 上传路径
      this.$http
        .post("/clients/orders/image", params, config)
        .then((res) => {
          if (res) {
            //如果为真 resolve出去
            this.imgList = [
              {
                url: res,
                status: "done",
                message: "上传完成",
              },
            ];
            this.update("api_update");
          } else {
            //否则 Toast 提示
            this.$toast.fail("上传失败");
          }
        })
        .catch((err) => {
          this.$toast.fail("系统异常");
        });

      // return new Promise((resolve, reject) => {
      //     //把 uploadUrl 换成自己的 上传路径
      //     this.$http.post('/clients/orders/image', params, config).then(res => {
      //         console.log(res)
      //         if (res) {				//如果为真 resolve出去
      //             this.imgList = [{
      //                 url: res,
      //                 status: 'done',
      //                 message: '上传完成',
      //             }]
      //             resolve((new File(res, file.file.name, {type: file.file.type,})));
      //         } else {
      //             //否则 Toast 提示
      //             this.$toast.fail('上传失败')
      //         }
      //     }).catch(err => {
      //         this.$toast.fail('系统异常')
      //         reject(err)
      //     });
      // })
    },
   getProducts(productcode) {
     this.loading = true;
      productcode = productcode||this.productcode
      let {client_id,shop_id} = JSON.parse(localStorage.getItem("user")||'{}')
      let url = "/clients/helpers/products?order_type=design&product_id="+productcode+'&pagesize=20&client_id='+client_id+"&shop_id="+shop_id+"&page="+this.curpage+'&code='+this.keyWord;
      let ret = this.$http.get(url).then((res) => {
           this.loading = false;
        let Productlist = Object.entries(res || {}).map(([k, v]) => {
          return {
            text: v.code,
            _text: v,
            key: v.id,
          };
        });
        this.finished = res.length==0?true:false;
        this.Productlist = this.Productlist.concat(Productlist);
        
      }).catch((res)=>{

           this.loading = false;
      });
      return ret;
    },
    getClientsOptions() {
      this.$http.get("/clients/options").then((res) => {
        let options = {};
        let _this = this;
        Object.entries(res).forEach(([key, value]) => {
          this.showPickerList[key] = false;
          options[key] = Object.entries(value).map(([k, v]) => {
            if (key === "positions") {
              this.tmp_pos_items[k] = [];
            }
            return {
              text: v,
              key: k,
            };
          });
        });
        this.options = options;
         this.initCart();
          // this.initCart();
        
      });
    },
    getCurtain(val, key) {
      let t = this.options.curtain_types.find(
        (_) => (key && _.key == key) || (val && _.text == val)
      );
      return t ? t : { text: "", key: "" };
    },
    getProduct(val, key) {
      this.Productlist = this.Productlist||[];
      let t = this.Productlist.find(
        (_) => (key && _.key == key) || (val && _.text == val)
      );
      return t ? t : { text: "", key: "" };
    },
    checkCalc() {
      let isValid = true;
      let errorMessage ='';
      let pos_arr = this.getPositionStr().split(",")||[];
      try {
        
      this.fieldsets.filter((item)=>pos_arr.includes(item.name)).forEach((item)=>{
        this.cart1[item.name] = this.cart1[item.name]||{};
          item.items.forEach(($item)=>{
            $item.element.forEach(($$item)=>{
              var val = this.cart1[item.name][$$item.v_model];
               if($$item.tag=='multi-select'){
                 val = val.length>0;
               }
             if($$item.required&&!val){
                    isValid = false;
                    errorMessage = `请输入${item.label}的${$item.label}`;
                    throw 'jumpout'
                  
             }
            });
         })
      })
      } catch (error) {
         this.$toast.fail(errorMessage);
      }
     
      return [isValid,errorMessage];
    },
    calc() {

      let [isValid,errorMessage] = this.checkCalc();
      if (!isValid) {
       
        return false;
      }
     return   this.update("api_update",true).then(()=>{
   return this.$http(
         
         { method:"patch",url: "/clients/cart/" + this.init_cart.index + "/resize"
         ,data:this.getData(),
         message:"核算报价中"}
        )
        .then((res) => {
          this.initData(res);
          this.$forceUpdate();
        });
       })
     
     
    },
    fix2(val) {
      let t = val.toString();
      let min = t.indexOf(".");
      if (val && !isNaN(val) && min !== -1 && t.length > t.indexOf(".") + 2) {
        return parseFloat(t.substring(0, min + 2 + 1));
      }
      return val;
    },
    setPosItem(val, key, index, type) {
      let _positem = { ...this.tmp_pos_items[key][index] };
      _positem[type] = val.text;
      _positem["product_id"] = val.key;
       if(type=='product_id'){
        _positem["price"] = val._text.price;
      }
      this.tmp_pos_items[key] = this.tmp_pos_items[key].map((item, $index) => {
        if ($index == index) {
          return _positem;
        } else {
          return item;
        }
      });
      this.update(this.tmp_pos_items[key][index]);
      this.onPosItemClick(key, index, type, false);
    },
    setPicker(val, type) {
      this.cart[type] = val["key"];
      this.tmp_val[type] = val["text"];
      this.update("api_update");
      this.onPickerClick(type, false);
    },
    add(key) {
      this.tmp_pos_items[key].push({
        key: "",
        index: this.tmp_pos_items[key].length,
        curtain_show: false,
        curtain_type: "",
        curtain: "",
        product_show: false,
        product_id: 0,
        product: "",
        quantity: null,
        fold_num: null,
      });
      this.$set(this.tmp_pos_items, `${key}`, this.tmp_pos_items[key]);
      this.$forceUpdate();
    },
    del(key, index) {
      this.tmp_pos_items[key].splice(index, 1);
      this.$forceUpdate();
    },
    getData() {
      let pos_items = {};
      Object.entries(this.tmp_pos_items ? this.tmp_pos_items : {}).forEach(
        ([key, val]) => {
          if (this.checked[key]) {
            pos_items[key] = [];
            pos_items[key] = val.map((_) => {
              return Object.assign(
                this.init_cart["pos_items"][key] &&
                  this.init_cart["pos_items"][key][_.index]
                  ? this.init_cart["pos_items"][key][_.index]
                  : {},
                {
                  key: _.key,
                
                  quantity: parseFloat(_.quantity),
                }
              );
            });
          }
        }
      );
      this.cart.img =
        this.imgList && this.imgList[0] ? this.imgList[0]["url"] : "";
      this.cart.pos_items = pos_items;

      return this.cart;
    },
    update(type,noInitData=false) {
        let user = JSON.parse(localStorage.getItem("user")||'{}')
        let data = this.getData();
          user.client = user.client||{};
        data.brand = user.client.brand;
      if (type == "api_update") {
        // let pos_arr = this.getPositionStr().split(",");
        // let pos_items ={};
        //   pos_arr.forEach((item)=>{
        //    pos_items[item] = data.pos_items[item]
        //  })
        data.un_checked_pos = this.getUnCheckedPos();
        return this.$http
          ({method:"patch",url:"/clients/cart/" + this.init_cart.index,hidden_toast:true, data:{res:this.cart1,...data}})
          .then((res) => {
            if(!noInitData){
                this.initData(res);
            }
           
          });
      } else {
        return this.$http
          .post("/clients/cart/" + this.init_cart.index + "/add", type)
          .then((res) => {
            this.initData(res);
          });
      }
    },
   
    onSubmit() {
      if(this.getPositionStr()==''){
           this.$toast.fail("请至少选择一个部位");
        return 
      }
       let [isValid,errorMessage] = this.checkCalc();
      if (!isValid) {
        return false;
      }
     
      
      let that = this;
      // debugger;
      this.calc().then((_) => {
        
      
        // 
        // this.design.res = _.res;
        // this.goBack2();
        
        const callback =()=>{
             that.getCartNum();
           if(this.$store.getters.confirmorderid&&this.action =="create"){
this.$http.post("/clients/orders/carts?order_type=design_order",{created_at:JSON.stringify(new Date()).replace(/T.*|"/g, ""),index: this.init_cart.index,source:"zhilian",group_order_id:this.$store.getters.confirmorderid,status:'draft',onlyProduct:true})
                            .then(res => {
                             this.is_show = false;
                               this.$emit("hideCartInfoPopup", false);
                            })
           }else{
            this.is_show = false;
                               this.$emit("hideCartInfoPopup", false);
           }
        }
        if(this.$store.getters.confirmorderid&&this.action!='create'){
              let {order_id=''} =this.cart_info;
           this.$http({url:"/clients/orders/"+order_id+"/update/"+ this.init_cart.index,method:"patch",data:{}}).then(()=>{
           
           callback();
        }).catch(()=>{
           that.getCartNum();
          this.is_show = false;
            this.$emit("hideCartInfoPopup", false);
        })
        }else{
           callback();
        }
       
        // this.$router.replace('/cart')
      });
    },
      getUnCheckedPos(){
      let arr =[];
      
      Object.entries(this.pos_items ? this.pos_items : {}).forEach(
        ([key, value]) => {
          if (!this.checked[key]&& this.tmp_pos_items[key].length>0) {
           arr.push(key);
          }
        }
      );
      return arr;
    },
    getPositionStr() {
      let positions1 = "";
      Object.entries(this.pos_items ? this.pos_items : {}).forEach(
        ([key, value]) => {
          if (this.checked[key]) {
            positions1 += key + ",";
          }
        }
      );
      return positions1.slice(0,-1);
    },
    onPickerClick(key, val) {
      this.showPickerList[`${key}`] = !!val;
      this.$set(this.showPickerList, `${key}`, !!val);
      this.$forceUpdate();
    },
    onPosItemClick(key, index, type, val) {
   
        
      if(type!='product_id'){
       this.tmp_pos_items[`${key}`][index][type + "_show"] = !!val;
      }else{
        this.productModalshow = false;
      }
    
    
      this.keyWord = "";
      this.tmp_pos_items[`${key}`][index][type + "_show"] = !!val;
      this.$forceUpdate();
    },
    goBack2() {
      this.newCartStr = JSON.stringify(this.cart1);
      let action =()=>{
         if (this.action == "create") {
       
        this.$http
          .delete("/clients/cart/" + this.init_cart.index,{keepalive: true})
          .then((res) => {
            this.is_show = false;
          });
      }
    
      this.is_show = false;
      this.$emit("hideCartInfoPopup", false);
      this.isReady =false;
     this.$forceUpdate();
     this.$nextTick(()=>{
        //  this.cart ={};
     })
      }
    if( this.oldCartStr!=this.newCartStr){
     Dialog.confirm({
        title: '提示',
        message: '确定要放弃本次编辑吗',
      })
        .then(() => {
          action()
    
        })
        .catch(() => {
         
      
        });
    }else{
  action()
    }

     
   
  
 
    },
  },
};
</script>

<style>
.m-b-10 {
  margin-bottom: 10px;
}
.cart_form .calc_button {
  width: 88%;
  margin: 10px 6%;
}

.position .van-grid-item__content {
  padding: 8px;
}
.series-goods .van-grid-item__content {
  height: auto;
}

.position .van-field {
  padding: 0;
}
.aaa{
  margin-bottom: -5px;
}
.aaa .van-tag {
  margin-bottom: 5px;
  margin-right: 5px;
  min-width: 50px;
  text-align: center;
  /* padding-right: 0; */
  align-items: center;
  box-sizing: border-box;
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
   font-size: 14px;
  background: #F2F2F2;
  border: 0;
  color:#333;
  border-radius: 5px;
    padding:10px!important;
}
.aaa input{
width: 80px;
  border-color: #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
      margin-right: 10px;
          height: 34px;
    box-sizing: border-box;
    margin-bottom: 5px;
}
.section {

  padding: 10px 20px;
  padding-bottom: 0;
  align-items: center;
  
}
.section .label {
  flex: 0 0 70px;
  font-size: 14px;
  margin-right: 10px;
  /* margin-bottom: 5px; */
   text-align: right;
}
.section .van-checkbox {
      margin-right: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    background: #F2F2F2;
     font-size: 14px;
    border-radius: 5px;
    color: #333333;
}
.section .van-checkbox__label {
  margin-left: 0;
}
.section .van-checkbox__icon {
  display: none;
}
.header {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 20px;
}
.header .van-checkbox {
  font-size: 12px;
  margin-right: 10px;
}
.active {
  color: #8AC58A;
  border-color: #8AC58A;
}
.buwei-desc-wrapper {
  padding: 10px;
  box-sizing: border-box;
  /* border: 1px solid #ddd; */
  width: 100%;
}
.buwei-desc {
  width: 100%;
  margin: 0;
  border: 1px solid #ddd !important;
  background: none;
  -o-border-image: none;
  border-image: none;
  padding: 5px;
  box-sizing: border-box;
  /* background: #f5f5f5; */
}
.display-flex {
  display: flex;
  align-items: center;
  padding-left: 20px;
  flex-wrap: wrap;
}
.display-flex .label {
  flex: 0 0 70px;
  font-size: 14px;
  margin-right: 10px;
}
.display-flex input {
  width: 80px;
  border-color: #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
      height: 26px;
}
.van-checkbox--horizontal {
  /* padding: 5px 20px; */
  font-size: 14px;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
  font-size: 12px;
}
.position {
  /* box-shadow: 0 1px 2px 0 rgb(0 4 12 / 10%); */
}
.section .van-checkbox__icon--checked {
}

.section .van-checkbox[aria-checked="true"]{
 
  background: #8AC58A;
}
.section .van-checkbox[aria-checked="true"] .van-checkbox__label{
 color:#fff!important;
}
.aactive{
  color:#fff!important;
  background: #8AC58A!important;
}
.border-color{
         border: 1px solid #8ac58a;
    padding-top: 10px;
    margin: 10px;
    box-sizing: border-box;
    margin-top: 0;
}
.pos-name{
      background: #8ac58a;
    color: #fff;
    padding: 5px;
    position: absolute;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    bottom: -10px;
    min-width: 40px;
    text-align: center;
}
.close-wrapper{
      margin-right: 10px;
    position: absolute;
    right: 0;
}
.close-icon{
      border: 2px solid #9e9e9e;
    border-radius: 100%;
    padding: 3px;
    color: #9e9e9e;
    margin-right: 10px;
    font-weight: 600;
}
.check-pos{
      display: flex;
    align-items: center;
    /* margin-left: 65px; */
    margin-top: 20px;
    flex:1;
    justify-content: flex-end;
}
.van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #8ac58a;
    border-color: #8ac58a;
}
.img-item{

 
    flex: 0 0 25%;
    /* margin-right: 10px; */
    display: block;
   
        border: 2px solid transparent;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 5px;
        font-size: 14px;
        max-height:120px;
      
}
.img-item.active{
      border-color: #8AC58A;
    border-radius: 10px;
    border-width: 2px;
}
.img-item img{
      max-width: 100%;
    display: block;
    margin: 0 auto;
   min-height: 60px;
    background: #f7f7f7;
    min-width: 65px;
    border: 0!important;
    outline: none;
     max-height: 70px;
}
.van-list__finished-text{
  width: 100%;
  text-align: center;
}
 .create-order .van-uploader__preview-image{
    max-width: 80px!important;
}
.error-message{
  font-size: 12px;
    margin-top: 5px;
    color: red;
}
.van-icon-arrow-down{
  font-size: 10px;
  color: #bbb;
}
.ssss .van-sticky{
  background: #fff;
}
</style>
