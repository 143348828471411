<template>
  <div >
    <van-image v-if="cases['image']" :src="cases['image']" fit="contain" lazy-load>
      <template v-slot:loading>
        <van-loading type="spinner" size="20"/>
      </template>
      <template v-slot:error>加载失败</template>
    </van-image>
    <!-- <van-divider/> -->
    <div v-if="cases['desc']" class="desc-contain">
      <div v-html="cases['desc']"></div>
    </div>
    <van-popup v-model="isShowVideo" closeable lazy-render close-on-popstate :style="{ width: '100%' }" >
      <video v-if="isShowVideo" controls autoplay disablePictureInPicture width="100%">
        <source :src="cases.video" type="video/mp4">
        <p>浏览器不支持此视频格式</p>
      </video>
    </van-popup>
    <van-image-preview closeable v-if="cases['gallery'] && cases['gallery']['large'] && cases['gallery']['large'].length" v-model="isShowGallery"
                       :images="cases['gallery']['large']"/>
                     <van-button type="primary" block @click="back()" class="back-btn" >返回</van-button >
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'CaseDetail',
  components: {Footer},
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      isShowVideo: false,
      isShowGallery: false,
      cases: '',
      cases_id: 0,
      current_cases: {},
    }
  },
  mounted() {
    this.cases_id = this.$route.params.cases_id
    this.getCases()
  },
  methods: {
    back(){
     
      this.$router.back(-1);
    },
    getCases() {
      this.$http.get('/clients/case_designs/' + this.cases_id).then(res => {
        console.log(res);
        this.cases = res;
      })
    },
    tagTo(url) {
      if (!url || url.length === 0 || url === undefined || url === 'undefined') {
        return false
      }
      if (url.startsWith('http')) {
        window.location.href = url
      } else {
        this.$router.push(url)
      }
    },
    showVideo() {
      this.isShowVideo = true;
    },
    showGallery() {
      this.isShowGallery = true;
    },
  }
}
</script>
<style>
.btn-list {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 0.5rem auto;
}

.show-btn {
  display: flex;
  text-shadow: black 0.1em 0.1em 0.2em
}

.desc-contain img {
  height: auto !important;
  width: 100% !important;
}
.van-image{
  width: 100% !important;
}
.back-btn{
      position: fixed;
    bottom: 0px;
}
</style>
