<template >
    <div class="index-page">
        <van-swipe :style="{height:swipeHeight+'px'}" :autoplay="3000" >
            <van-swipe-item v-for="image in images" :key="image.id" @click="tagTo(image.url)" >
              <template v-if="image.video">
                  <video ref="design_audio" :src="image.video" loop style="width:100%;"/>
              </template>
              <template v-else>
                <van-image   lazy-load class="img-placeholder" :src="image.img"  fit="contain"  :style="getStyle">
                 <template v-slot:loading>
                    <van-loading type="spinner" size="30" />
                </template>
                </van-image>
               
              </template>
            </van-swipe-item >
        </van-swipe >
        <van-notice-bar style="margin-top:10px" v-if="$store.getters.shop.config.show_scroll"  scrollable color="#1989fa" background="#ecf9ff" left-icon="info-o">
  {{visitNum}}
</van-notice-bar>
        <van-grid style="    margin-top: 10px;" class="aaa" :border="false" :column-num="$device.tablet() ? 6 : 4" >
            <van-grid-item v-for="item in tags" :key="item.id" >
                <van-image lazy-load width="3rem" height="3rem" :src="item.image" fit="contain" @click="tagTotags(item.url,item.id,item.name)" />
                <span style="margin:8px 0px 3px 0px;font-size:14px;    overflow: hidden;
    white-space: nowrap;
    width: 100%;
        text-align: center;
    text-overflow: ellipsis;" >{{ item.name }}</span >
            </van-grid-item>
        </van-grid >
      
        <lazy-component :class="$store.getters.shop.config.windows_mode==2?'flex-5':'flex-10'" v-if="showcases" >
            <van-image :style="getStyle2(item)" v-for="item in showcases" :key="item.id" width="100%" lazy-load fit="contain" :src="item.img" @click="tagTo(item.url)">
         <template v-slot:loading>
                    <van-loading type="spinner" size="30" />
                </template>
         </van-image>
        </lazy-component >
        <div style="text-align:center;" v-if="recommend_series.length>0">
            <span class="good-goods-title">精选样册</span >
        </div>
        <lazy-component v-if="recommend_series" >
            <van-swipe class="series-swipe" >
                <van-swipe-item v-for="(design, index) in recommend_series.slice(0, $device.tablet() ? 6 : 4)" :key="index" >
                    <van-grid :border="false" :column-num="$device.tablet() ? 6 : 4" >
                        <van-grid-item v-for="item in design" :key="item.id" >
                            <van-image lazy-load width="3rem" height="3rem" :src="item.img.large" fit="contain" @click="tagTo('/series/' + $store.getters.shop_id + '/' + item.id)" />
                            <span style="margin:8px 0px 3px 0px;font-size:14px" >{{ item.code }}</span >
                        </van-grid-item >
                    </van-grid >
                </van-swipe-item >
            </van-swipe >
        </lazy-component >
        <div class="good-goods" v-if="list && list.items" style="padding:1% 0.5vw" >
            <span class="good-goods-title" v-if="list.items.length>0" >精选商品</span >
            <van-grid class="goods"
                      :class="[$device.tablet() ? 'goods-tablet' : 'goods-mobile']"
                      style="margin-top:10px;"
                      :border="false"
                      center
                      :column-num="$device.tablet() ? 4 : 2"
                      :safe-area-inset-bottom="true" >
                <van-grid-item v-for="item in list.items" :key="item.id" :to="'/goods/' + $store.getters.shop_id + '/' + item.id + '?toolbar=1'" >
                    <van-image slot="icon" :src="item.img" height="100%" width="100%" lazy-load fit="contain" />
                    <div slot="text" style="text-align:left;width:150px" >
                        <span style="display:block;font-size:1.1rem" >{{ item.code }}</span >
                        <span class="price" style="display:block;font-size:1.1rem;color: red" >
                          <template v-if="item.price_wailian">
                          ¥ {{ price_ratio(item.price_wailian) }}
                          </template>
                          <template v-else>
                          <br/>
                          </template>
                        </span >
                        <!-- <span v-if="item.price" style="display:block;font-size:1.1rem;font-weight: bold;">{{ '¥' + item.price + '元/米' }}</span> -->
                    </div >
                </van-grid-item >
            </van-grid >
        </div >
        <!-- <van-divider >

        </van-divider > -->
        <div :class="fixed?'bottom-text-1':'bottom-text-1 no-fixed'" style=" " v-show="show_suishou">
          <div class="display:block;">随手e裁技术支持</div>
          <!-- &nbsp;&nbsp;&nbsp;&nbsp;
          <span>17091921517</span> -->
        </div>
        <Footer safe-area-inset-bottom :count="count"></Footer >
    </div >
</template >

<script >
    import Footer from '@/components/Footer.vue'
   

    export default {
        name: 'Index',
        components: {Footer},
        data() {
            return {
                isMiniProgram:false,
                orderNum:0,
                visitNum:0,
                swipeHeight:0,
                fixed:true,
                count:'',
                shopId: 0,
                show_suishou: false,
                shop_name: '',
                images: [],
                designs:[],
                tags:   [],
                series: [],
                recommend_series: [],
                showcases: [],
                list: {}
            }
        },
        watch: {
            $route(to, from, next) {
                this.init()
            }
        },
       
        computed:{
           
          getStyle(){
             const obj = {width:'100%',height:'auto'} 
              if(this.images){
                if(this.images.length==0){
                    return  obj
                }else if(this.images[0].h){
                    return  {width:'100%',height:this.images[0].h+'px!important'}
                }else {
                        return  obj
                }
                }
            }
        },
        mounted() {
            this.init()
           // this.visitNum =  parseInt(Math.random(0,1)*3000)+2000
           // this.orderNum =  parseInt(Math.random(0,1)*750)+250
            let that = this
              wx.miniProgram.getEnv(function(res) {
                that.isMiniProgram = res.miniprogram
              });
             // this.$nextTick(function(){
            //     this.show_suishou=true
            // })
        },
        methods: {
          
             getStyle2(item){
             const obj = {width:'100%',height:'auto'} 
              if(this.showcases){
                if(this.showcases.length==0){
                    return  obj
                }else if(item.h){
                    return  {width:'100%',height:item.h+'px!important'}
                }else {
                     return  obj
                }
               }
            },
            calheight(src){
                const evt = window.event;
                 src = src;
                if(!src.includes("w=")){
                    return 
                }
              let aa  = new URLSearchParams(src.split("?")[1])
               return {h:aa.get('h')/aa.get('w')*document.documentElement.clientWidth}
            },
            init() {
                
                this.shopId = this.$store.getters.shop_id
                if (this.shopId) {
                    if (this.$store.getters.shop && this.$store.getters.shop.name && this.$store.getters.shop.name != document.title) {
                        this.shop_name = this.$store.getters.shop.name
                    }
                  
                    
                        document.title = this.shop_name||'';
                    this.getBanner()
                   this.getDesign()
                    this.getTags()
                    this.getNum();
                // this.show_suishou = true
                  this.getRecommendSeries()
                    this.getShowCase()
                    this.count =  localStorage.getItem('cartcount');
                   
                    
                } else {
                    this.$dialog
                        .alert({
                            title: '店铺未知',
                            message: '请登录后访问',
                            confirmButtonText: '去登录'
                        })
                        .then(() => {
                            this.$store.dispatch('Logout')
                            this.$router.replace('/login')
                        })
                }
           
          
            },
            tagTo(url) {
                
                if (!url || url.length === 0 || url === undefined || url === 'undefined') {
                    return false
                }
               
                if (url.startsWith('http')) {

                   this.navigatMap(url);
                 
                   
                } else {
                    this.$router.push(url+(url.includes("?")?"&":"?")+'fromIndex=1')
                }
            },
            navigatMap(url){
                 if(!url.includes('map_demo')){
                         window.location.href = url
                    }else{
                  if(this.isMiniProgram){
                    let shopid = url.split('shop_id=')[1].split("&")[0]
                    wx.miniProgram.navigateTo({url: '/pages/map?url='+encodeURIComponent(url)+'&redirectMap=true'})
                  }else{
                    window.location.href = url
                  }
                 }
            },
            tagTotags(url,tag,title){
              
                if(url){
                  
                    if(!url.includes("appid")&&!url.includes("http")){
                     url =  !url.includes("?")?url+'?title='+title:url
                     this.$router.push({path:url})
                    }else if(url.includes("http")){
                      this.navigatMap(url);
                     
                }else{
                    let appid =url.split("appid=")[1];
                     url =url.split("path=")[1];
                     wx&& wx.miniProgram&&  wx.miniProgram.navigateTo({url: '/pages/navigateotherxcx?page='+encodeURIComponent(url)+'&appid='+appid})
                    }
                  
                }else{
                   this.$router.push({
                        path: "/series/" + this.$store.getters.shop_id,
                        query: {
                            tag: tag
                        }
                    })
                }
               
            },
            getDesign() {
                this.$http.get('/clients/designs?recommand=1&shop_id=' + this.shopId).then(res => {
                    res = res||[];
                    res.items = res.items.map((item)=>{
                        return {
                          img:item.img,
                          id:item.id,
                          code:item.code,
                          price_wailian:item.price_wailian
                        }
                    })
                    this.list = res
                })
            },
            // getSeries() {
            //     this.$http.get('/clients/series?shop_id=' + this.shopId).then(res => {
            //
            //         this.series = res
            //     })
            // },
            getNum() {
                this.$http.get('/clients/dailyOrder/' + this.shopId).then(res => {
                    this.visitNum = res.text;
                    //this.orderNum = res.order;
                })
            },
             getTags() {
                this.$http.get('/clients/tags?recommand=1&shop_id=' + this.shopId).then(res => {
                    this.tags = res
                })
            },
            setBottomText(){
               if(document.documentElement.scrollHeight>=document.documentElement.clientHeight){
                       this.$nextTick(()=>{
                         this.fixed = false;
                  }) 
                }
            },
            getRecommendSeries() {
                this.$http.get('/clients/series?recommand=1&shop_id=' + this.shopId).then(res => {
                    let len = res.length
                    let n = this.$device.tablet() ? 6 : 4 //假设每行显示4个
                    let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1)
                    let arr = []
                    for (let i = 0; i < lineNum; i++) {
                        // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
                        let temp = res.slice(i * n, i * n + n)
                        arr.push(temp)
                    }
                    this.recommend_series = arr
                   setTimeout(()=>{
                        this.show_suishou = true
                         var user =localStorage.getItem("user");
                         user = JSON.parse(user||"{}");
                        let shop_name =  sessionStorage.getItem('shopname')
                         document.title =  shop_name ||"";
                        wx.miniProgram && wx.miniProgram.postMessage({ data: {shop_name:shop_name} })
                        this.setBottomText();
                    },200)
                    setTimeout(()=>{
                       this.setBottomText();
                    },1000)
                     setTimeout(()=>{
                       this.setBottomText();
                    },2000)
                  
                       
                })
            },
            getShowCase() {
                this.$http.get('/clients/showcases?shop_id=' + this.shopId).then(res => {
                    res = res.map((item)=>{
                        let aa = this.calheight(item.img)||{}
                       item.w = aa.w 
                       item.h = aa.h
                       return item;
                    })
                    this.showcases = res
                })
            },
            loadImg(src){
                var img =  new Image();
                img.src = src;
                let that = this;
                img.onload = function(a){
                 let clientWidth = Math.min(document.documentElement.clientWidth,820);
                  that.swipeHeight =img.height/img.width*clientWidth
                }
            },
            getBanner() {
                this.$http.get('/clients/banners?shop_id=' + this.shopId).then(res => {
                   res = res.map((item)=>{
                     
                        let aa = this.calheight(item.img)||{}
                       item.w = aa.w 
                       item.h = aa.h
                       return item;
                    })
                    if(res&&res.length>0){
                        this.loadImg(res[0].img);
                    }
                    this.images = res;
                   
                })
            }
        }
    }
</script >
<style >
    .good-goods,
    .series-swipe {
        text-align: center;
    }

    .good-goods,
    .good-goods-title {
        font-size: 1.4rem;
        margin-top: 2rem;
    }
    .van-grid-item{
          overflow: hidden;
    }

    .goods .van-grid-item {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        padding: 0 0.5vw;
          
    }

    .goods .van-grid-item__content {
        padding: 1% 0;
       
    }

    .goods .van-image__img {
        width: unset;
        margin: 0 auto;
            max-width: 150px;
    }

    .goods-tablet .van-grid-item__icon-wrapper {
        width: 23.75vw;
        height: 23.75vw;
     
    }

    .goods-mobile .van-grid-item__icon-wrapper {
        width: 48vw;
        height: 48vw;
      
    }
    .bottom-text-1{
   color: rgb(193, 194, 195);
    text-align: center;
    margin-bottom: 30px;
    font-size: 12px;
     position: absolute;
    width: 100%;
    bottom: 40px;
    }
    .no-fixed{
       position: initial!important;;
    }
    .aaa .van-grid-item__content{
        padding: 8px;
    }
    .img-placeholder{
             background: #dee0e2;
    }
    .noauth-price .price{
           opacity: 0;
    }
    .index-page .van-grid-item__content{
        height: auto!important;
    }
    .flex-5{
         display: flex;
    width: calc(min(100vw,820px) - 20px);
    justify-content: space-between;
    margin: 10px auto 0;
    flex-wrap: wrap;
    }
    .flex-5 .van-image{
          flex: 0 0 calc(50% - 5px);
            margin-bottom: 10px;
    }
</style >
