<template>
  <div>
        <form action="/">
          <van-search v-model="keyword" placeholder="请输入搜索关键词" show-action @search="onSearch">
            <div slot="action" @click="onSearch" style="padding:0px 8px;">搜&nbsp;&nbsp;索</div>
          </van-search>
        </form>
        <div style="display:flex;flex-direction:row">
          <van-sidebar class="series-sidebar" v-model="activeKey" @change="changeCases">
            <van-sidebar-item v-for="item in cases" :title="item.code" :key="item.id"/>
          </van-sidebar>
          <van-list class="series-goods case-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadDesign" style="flex:min-content">
            <van-grid :border="false" center :column-num="$device.tablet() ? 4 : 2" :safe-area-inset-bottom="true">
              <van-grid-item class="goods-item"
                             :class="[$device.tablet() ? 'goods-item-tablet' : 'goods-item-mobile']"
                             v-for="(item,index) in list"
                             :key="item.id"
                             :to="'/case_detail/' + $store.getters.shop_id + '/' + item.id"
                             style="position:relative;"
              >
                <van-image slot="icon" :src="item.image" lazy-load fit="contain" style="width:100%;height: auto;position:absolute;top:50%;transform:translateY(-50%);"/>
                <div slot="text" style="text-align:left;margin-left:3%;width: 90%;">
                  <span style="display:block;font-size:1.1rem">{{ item.code }}</span>
                  <!-- <span v-if="item.price" style="display:block;font-size:1rem;color:red;">{{ '¥' + item.price_wailian }}</span> -->
                </div>
              </van-grid-item>
            </van-grid>
          </van-list>
        </div>
    <Footer :count="count"></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name      : 'Case',
  components: {Footer},
  data() {
    return {
      count:0,
      loading           : false,
      finished          : false,
      refreshing        : false,
      is_search         : false,
      page              : 1,
      keyword           : '',
      cases             : '',
      shopId            : 0,
      activeKey         : 0,
      current_cases     : {},
      list              : [],
      categories        : '',
      current_categories: {},
      product_loading   : false,
      product_finished  : false,
      product_refreshing: false,
      product_is_search : false,
      product_page      : 1,
      product_keyword   : '',
      product_activeKey : 0,
      product_list      : []
    }
  },
  mounted() {
    this.shopId = this.$store.getters.shop_id;
      this.count =  localStorage.getItem('cartcount')
    this.getCases()
    window.scrollTo(0,0)
  },
  methods   : {
    loadDesign(){
      return this.page>1&&this.getDesign();
    },
    getCases() {
      let that = this;
      this.$http.get('/clients/case_series?shop_id=' + this.shopId).then((res)=>{
        res = res ? res : []
        res.unshift({
                      id  : 0,
                      code: '全部'
                    })
        that.cases = res
        // let _this = that
        if (that.$route.params.case_series_id) {
          that.activeKey = that.cases.findIndex(function (_) {
            return _.id === parseInt(that.$route.params.case_series_id)
          })
          if (that.activeKey === -1) {
            that.activeKey = 0
          }
        }else{
            that.activeKey = 0
            this.getDesign();
        }
      //  that.changeSeries && that.changeSeries()
      })
    },
    changeCases() {
      this.current_cases = this.cases[this.activeKey]
      this.page = 1
      this.list = []
      this.finished = false
      this.is_search = false
      let url = '/case/' + this.$store.getters.shop_id
          url =this.current_cases.id? url+ '/' +this.current_cases.id:url;
     this.$router.replace(url);
     window.scrollTo(0,0);
      if (this.current_cases) {
        // document.title = this.current_series.code;
        this.getDesign()
      }
    },
    onSearch(keyword) {
      this.page = 1
      this.list = []
      this.activeKey = ''
      this.finished = false
      this.is_search = true
      this.getDesign()
    },
    getDesign() {
      this.loading = true
      setTimeout(() => {
        let _this = this
        let url
        if (this.is_search) {
          url = '/clients/case_designs?shop_id=' + this.shopId + '&code=' + this.keyword + '&page=' + this.page
        } else if (this.current_cases && this.current_cases.id && this.current_cases.id != undefined) {
          url = '/clients/case_designs?shop_id=' + this.shopId + '&serie_id=' + this.current_cases.id + '&page=' + this.page
        } else {
          url = '/clients/case_designs?shop_id=' + this.shopId + '&page=' + this.page
        }
        this.$http.get(url).then(res => {
          if (res.items.length === 0) {
            _this.finished = true
          } else {
            // let list = []
            res.items.forEach(item => {
              _this.list.push(item)
            })
            // _this.list = list
          }
          if (res.pages <= _this.page) {
            _this.finished = true
          }
          _this.page++
          _this.loading = false
        })
      }, 500);
    },
    redirectProduct(item) {
      localStorage.setItem('product', JSON.stringify(item))
      this.$router.replace('/products/' + this.$store.getters.shop_id + '/' + item.id)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.changeCases()

      this.product_finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.product_loading = true
      this.changeCategories()
    }
  }
}
</script>
<style>
.series-sidebar {
  flex: none;
  min-height: 100vh;
  background-color: #fafafa;
}

.goods-item {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0 0.5vw;
}

.goods .van-image__img {
  width: unset;
}

.goods-item .van-grid-item__content {
  padding: 1% 0;
}

.goods-item-tablet .van-grid-item__icon-wrapper {
  width: calc(100vw / 4 - 1vw - 21.25px);
  height: calc(100vw / 4 - 1vw - 21.25px);
  background-color: #e2e2e2;
  overflow: hidden;
}

.van-image {
  overflow: hidden;
}

.goods-item-mobile .van-grid-item__icon-wrapper {
  width: calc(100vw / 2 - 1vw - 42.5px);
  height: calc(100vw / 2 - 1vw - 42.5px);
  background-color: #e2e2e2;
  overflow: hidden;
}

.series-sidebar,
.van-sidebar-item--select,
.van-sidebar-item--select:active {
  border-color: #07c160;
}
.case-list{
      flex: 1 1 min-content;
    max-height: calc(100vh - 60px);
    overflow: auto;
}
</style>
