 <template >
 <div>
    <van-sticky>
      <van-nav-bar
  title="定制商品"
  left-text="返回"
   
  left-arrow
  @click-left="onClickLeft"
  
/>
</van-sticky>
    <van-empty description="没有数据" v-if="!list.items||list.items.length==0" />
 <div class="good-goods" v-if="list && list.items" style="padding:1% 0.5vw" >
  
            <span class="good-goods-title" ></span >
            <van-grid class="goods"
                      :class="[$device.tablet() ? 'goods-tablet' : 'goods-mobile']"
                      style="margin-top:10px;"
                      :border="false"
                      center
                      :column-num="$device.tablet() ? 4 : 2"
                      :safe-area-inset-bottom="true" >
                  
                <van-grid-item v-for="item in list.items" :key="item.id" @click.stop="tagTo('/design/' + $store.getters.shop_id + '/' + item.id)" >
                    <van-image slot="icon" :src="item.img" height="100%" width="100%" lazy-load fit="contain" />
                    <div slot="text" style="text-align:left;width:150px" >
                        <span style="display:block;font-size:1.1rem" >{{ item.code }}</span >
                            <div    style="    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;">
    
    
                        <span class="price" style="display:block;font-size:1.1rem;color: red" >
                          <template v-if="item.price_wailian">
                         
                          ¥ {{ price_ratio(item.price_wailian) }}
                          </template>
                          <template v-else>
                          <br/>
                          </template>
                        </span >
                            </div>
                    </div >
                </van-grid-item >
            </van-grid >
        </div >
        </div>
        </template >

<script >
   
    export default {
        name: 'Index',
        components: {},
        data() {
            return {
                swipeHeight:0,
                fixed:true,
                count:'',
                shopId: 0,
                show_suishou: false,
                shop_name: '',
                images: [],
                designs:[],
                tags:   [],
                series: [],
                recommend_series: [],
                showcases: [],
                list: {}
            }
        },
        watch: {
            $route(to, from, next) {
                this.init()
            }
        },
       
        computed:{
           
          getStyle(){
             const obj = {width:'100%',height:'auto'} 
              if(this.images){
                if(this.images.length==0){
                    return  obj
                }else if(this.images[0].h){
                    return  {width:'100%',height:this.images[0].h+'px!important'}
                }else {
                        return  obj
                }
                }
            }
        },
        mounted() {
            this.init()
          
           
        },
        methods: {
          
            
            onClickLeft(){
             this.$router.go(-1)
            },
            init() {
                 this.shopId = this.$store.getters.shop_id
                   this.getDesign()
                 
                
           
          
            },
          
          tagTo(url) {
      if (!url || url.length === 0 || url === undefined || url === 'undefined') {
        return false
      }
      if (url.startsWith('http')) {
        window.location.href = url
      } else {
        this.$router.push(url)
      }
    },
            getDesign() {
                this.$http.get('/clients/designs?custom=1&shop_id=' + this.shopId+"&serie_id="+this.$route.query.serie_id).then(res => {
                    res = res||[];
                    res.items = res.items.map((item)=>{
                        return {
                          img:item.img,
                          id:item.id,
                          code:item.code,
                          price_wailian:item.price_wailian
                        }
                    })
                    this.list = res
                })
            },
           
           
           
        }
    }
</script >
<style >
    .good-goods,
    .series-swipe {
        text-align: center;
    }

    .good-goods,
    .good-goods-title {
        font-size: 1.2rem;
        margin-top: 0rem;
    }
    .van-grid-item{
          overflow: hidden;
    }

    .goods .van-grid-item {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        padding: 0 0.5vw;
          
    }

    .goods .van-grid-item__content {
        padding: 1% 0;
       
    }

    .goods .van-image__img {
        width: unset;
        margin: 0 auto;
            max-width: 150px;
    }

    .goods-tablet .van-grid-item__icon-wrapper {
        width: 23.75vw;
        height: 23.75vw;
     
    }

    .goods-mobile .van-grid-item__icon-wrapper {
        width: 48vw;
        height: 48vw;
      
    }
    .bottom-text-1{
   color: rgb(193, 194, 195);
    text-align: center;
    margin-bottom: 30px;
    font-size: 12px;
     position: absolute;
    width: 100%;
    bottom: 40px;
    }
    .no-fixed{
       position: initial!important;;
    }
    .aaa .van-grid-item__content{
        padding: 8px;
    }
    .img-placeholder{
             background: #dee0e2;
    }
    .noauth-price .price{
           opacity: 0;
    }
   
</style >