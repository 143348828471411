<!-- @format -->

<template >
    <div class="user-order" >
         <van-sticky>
        <div class="order-top" >
            <!-- <van-form @submit="onSubmit"> -->
            <!-- <van-field
        v-model="customer"
        name="name"
        placeholder="请输入客户标识"
        style="width:42%;float:left;"
      /> -->
            <van-field readonly clickable name="datetimePicker" :value="startValue" placeholder="开始时间" @click="showPicker = true" style="flex:120px"  />
            <van-popup v-model="showPicker" position="bottom" >
                <van-datetime-picker v-model="startTimeValue" type="date" @confirm="confirmTime('start')" @cancel="showPicker = false" />
            </van-popup >
            <van-field readonly clickable name="datetimePickerEnd" :value="endValue" placeholder="结束时间" @click="showPickerEnd = true" style="flex:120px"  />
            <van-popup v-model="showPickerEnd" position="bottom" >
                <van-datetime-picker v-model="endTimeValue" type="date" @confirm="confirmTime('end')" @cancel="showPickerEnd = false" />
            </van-popup >
            <!-- <van-field readonly clickable name="datetimePickerEnd" :value="status" placeholder="订单状态" @click="showPickerStatus = true" style="width:24%;float:left;" />
            <van-popup v-model="showPickerStatus" position="bottom" >
                <van-picker v-model="statusModel" show-toolbar overlay="false" :columns="statusArr" @confirm="confirmStatus" @cancel="showPickerStatus = false" />
            </van-popup > -->
            <div style="    padding-top: 0.5rem;  width: 60px;float: left;margin-right: 10px;" >
                <van-button block size="small" type="default" native-type="submit" @click="search" >
                    搜索
                </van-button >
            </div >
            
            <!-- </van-form> -->
            <div style="clear:both;" ></div >
        </div >
         </van-sticky>
<van-tabs @click="onClick" offset-top="44px" sticky>
  <van-tab :key="item.key" v-for="item in statusArr" :title="item.text" >
        <div class="order-list" >
                 <van-empty description="没有数据" v-if="orders.length==0&&!loading" />
            <van-list v-model="loading" :finished="finished" :finished-text="orders.length>0?'没有更多了':''" error-text="请求失败，点击重新加载" @load="getOrderList" >
                <van-cell-group v-for="order in orders" :key="order.id" class="order-list-group order-border" @click="orderDetail(order.id)" >
                    <van-cell class="order-border" style="padding:10px 4px;" >
                        <div slot="title" >**{{ order.order_no.substr(-6) }}.共 {{ order.group.length }} 套{{ order.sale_total_price_label }} <br />{{ order.comment }}</div >
                        <div slot="default" ><van-tag  :type="order.payment_status_label=='已支付'?'success':'danger'" style="margin-right:5px">{{order.payment_status_label}}</van-tag> <span @click="gotodetail(order.mobile_url)">{{ order.status_label }}</span> <van-icon @click="gotodetail(order.mobile_url)" name="arrow" />{{ order.created_at | formatTimer }}</div >
                    </van-cell >
                       <div style="    position: relative;padding: 5px;padding-left:0px
    margin: 0;" class="van-address-item van-contact-card">
                      <div class="van-cell van-cell--borderless"><div class="van-cell__value van-cell__value--alone van-address-item__value">
                      <div role="radio" tabindex="-1" aria-checked="false" class="van-radio"><span style="margin-left:0" class="van-radio__label"><div class="van-address-item__name">{{order.name}} {{order.phone||''}}</div><div class="van-address-item__address">{{order.street}}</div></span></div></div>
                      </div></div>
                     <div style="font-size:14px">
                     <div v-if="order.project_name">业主名称：{{order.project_name}}</div>
                        <div v-if="order.community_name">电话地址：{{order.community_name}}</div>
                        <div v-if="order.delivery_date"> 交付日期：{{order.delivery_date}}</div>
                     
                  </div>
                    <van-card v-for="group in order.group" :key="group.id" style="margin-top:0px;" class="goods-card order-border" :thumb="group.design_url" >
                        <div slot="desc" style="margin-top:1.2rem;margin-left:1.8rem;" >{{ order.customer }}<br /><span style="float:right;" >{{ group.sale_total_price_label }}</span ></div >
                        <!-- <div slot="tags" style="margin-top:0.3rem;margin-left:1.8rem;">尺寸：{{ group.window_width }} * {{ group.window_height }}</div> -->
                    </van-card >
                    <div style="text-align:right;margin:10px;" >
                        总价：{{ order.sale_total_price_label }}
                    </div >
                    <div class="" style="text-align:right">
                          <!-- <van-button style="margin-right: 10px;" type="info"  size="small" class="copy-btn" :data-clipboard-text="order.mobile_url.replace('orders','draft')">
                        分享报价<van-icon style="color:yellow" name="description" />
                    </van-button> -->
                    
                       <van-button @click="openShareModal(order.mobile_url.replace('orders','draft'))" plain type="primary" style="margin-right: 10px;" size="small">
                         分享报价<van-icon style="color:yellow" name="description" />
                     </van-button>
                      <van-button v-if="order.status=='drafted'&&$store.getters.shop.config.zhilian_draft" @click="updateOrderStatus(order.id,order.vouchers.length,'cancel')" style="margin-right: 10px;" plain type="info" size="small">取消提交</van-button> 
                        <van-button v-if="order.status=='created'&&$store.getters.shop.config.zhilian_draft==false" @click="updateOrderStatus(order.id,order.vouchers.length,'cancel')" style="margin-right: 10px;" plain type="info" size="small">取消提交</van-button> 
                      <van-button v-if="order.status=='draft'" @click="updateOrderStatus(order.id,order.vouchers.length)" style="margin-right: 10px;" plain type="info" size="small">提交订单</van-button> 
                     <van-button v-if="order.status=='draft'" @click="deleteOrder(order.id)" style="margin-right: 10px;"  type="default" size="small">删除订单</van-button> 
                    </div>
                </van-cell-group >
            </van-list >
            <van-button type="primary" block to="/user" style="position: fixed;bottom: 0;left: 0;" >返回</van-button >
        </div >
          </van-tab>
</van-tabs>
        <!-- <Footer></Footer> -->
         <van-share-sheet
            v-model="showShare"
            title="分享报价单"
            :options="options2"
            @select="onSelect"
            />
    </div >
</template >

<script >
    // import Footer from "@/components/Footer.vue";
       import { ImagePreview ,Dialog} from 'vant';
         import ClipboardJS from "../copy"
     let isHttps = false;
    export default {
        name: 'UserOrder',
        // components: { Footer },
        data() {
            return {
                   options2: [
        { name: '微信查看', icon: 'wechat' },
        { name: '复制链接', icon: 'link',className:"copy-btn" }
      ],
                  showShare:false,
                ClientOrderPush:false,
                customer: '',
                startValue: '',
                startTimeValue: new Date(),
                showPicker: false,
                endValue: '',
                endTimeValue: new Date(),
                status: '',
                statusModel: '',
                statusKey: '',
                showPickerStatus: false,
                showPickerEnd: false,
                orders: [],
                order_status: [],
                page: 1,
                finished: false,
                loading: false,
                is_search: false
            }
        },
        computed: {
            statusArr: vm =>
               [{text:"全部",key:""}].concat(vm.order_status.map(item => {
                      return {text:item.label,key:item.val}
                }))
                
        },

       
        filters: {
            formatTimer: function (value) {
                if (!value) {
                    return ''
                }
                let date = new Date(value.replace(/\-/g, '/'))
                let y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? '0' + MM : MM
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                let h = date.getHours()
                h = h < 10 ? '0' + h : h
                let m = date.getMinutes()
                m = m < 10 ? '0' + m : m
                let s = date.getSeconds()
                s = s < 10 ? '0' + s : s
                return y + '.' + MM + '.' + d
            }
        },
         mounted() {
               const user = JSON.parse(localStorage.getItem("user"));
                let ClientOrderPush =  user.auth.ClientOrderPush
        
           this.ClientOrderPush = ClientOrderPush;
            this.getClientsOptions();
         
        },
        methods: {
             gotodetail(url){
                url = url.replace('orders','draft');
          
                if(isHttps){url = url.replace("https://","http://").replace("http://","https://")} 
                 window.location.href =url+'?from=zhilian'
            },
             onSelect(e){
              if(e.icon=='wechat'){
                  window.location.href= this.showShare;
              }else if(e.icon=='link'){
                 
              }
            },
            openShareModal(linkurl){
               
                if(isHttps){linkurl = linkurl.replace("https://","http://").replace("http://","https://")} 
              this.showShare = linkurl;
            
       let that = this;
       that.$nextTick(function(){
        var clipboard = new ClipboardJS('.copy-btn', {
            text: function(trigger) {
                return linkurl;
            }
        });
            clipboard.on('success', (e) =>{
                    that && that.$toast.success("报价单复制成功")
                    e.clearSelection();
                });
       })
      
            },
             onClick(index,title){
               
               this.statusKey =this.statusArr[index].key
                this.search();
             
            },
             updateOrderStatus(id,len,type='submit'){
                if(!this.ClientOrderPush){
                      Dialog.confirm({
            title: '提示',
            message: '无权限，请联系管理员',
            showCancelButton:false
            })
                    return;
                }
                if(len==0&&(localStorage.queryShopId=='224'||localStorage.queryShopId=='1')&&type=='submit'){
                      Dialog.confirm({
                    title: '提示',
                    message: '先补充凭证再提交',
                    showCancelButton:false
                    })
                            return;
                }
                 debugger
              Dialog.confirm({
            title: '提示',
            message: type=='submit'?'您确定要提交临时单吗':'您确定要取消提交吗',
            })
            .then(() => {
                
                // on confirm
               let   url =(type=='submit'?'/push':'/unpush');
               
                 this.$http.patch("/clients/orders/"+id+url).then((res) => {
                   
               type=='submit'&&  this.$router.replace("/successorder");
                if(type=='cancel'){
                    this.$router.replace("/user/product_order?status=draft");
                    this.statusKey = 'draft';
                    this.search();

                } 
            // 
                 
              
            }).catch((res)=>{
              
            })
            })
            .catch(() => {
                // on cancel
            });
            },
           
             deleteOrder(id){
                Dialog.confirm({
            title: '提示',
            message: '您确定要删除该订单吗',
            })
            .then(() => {
               this.$http.delete("/clients/orders/"+id).then((res) => {
                 
               let index= this.orders.findIndex((item)=>item.id==id)
                this.orders.splice(index,1)
                 
              
            }).catch((res)=>{
              
            })
            })
            .catch(() => {
                // on cancel
            });
            },
            getOrderList() {
                this.loading = true
                let url
                if (this.is_search) {
                    url = '/clients/orders?type=odd_order&page=' + this.page + '&start=' + this.startValue + '&end=' + this.endValue + '&status=' + this.statusKey
                } else {
                    url = '/clients/orders?type=odd_order&page=' + this.page
                }
                this.$http.get(url).then(res => {
                    if (res.items.length === 0) {
                        this.finished = true
                    } else {
                        res.items.forEach(item => {
                            this.orders.push(item)
                        })
                    }
                    if (res.pages <= this.page) {
                        this.finished = true
                    }
                    this.loading = false
                    this.page++
                })
            },
            getClientsOptions() {
               this.$http.get("/clients/options").then((res) => {
                let options = {};
               
                this.order_status = res.order_status||[];
            });
            },
            formatTimer2(value) {
                if (value == '' || value == ' ') {
                    return ''
                }
                let date = new Date(value)
                let y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? '0' + MM : MM
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                let h = date.getHours()
                h = h < 10 ? '0' + h : h
                let m = date.getMinutes()
                m = m < 10 ? '0' + m : m
                let s = date.getSeconds()
                s = s < 10 ? '0' + s : s
                return y + '-' + MM + '-' + d
            },
            search() {
                this.is_search = true
                this.page = 1
                this.orders = []
                this.getOrderList()
            },
            confirmTime(type) {
                if (type == 'start') {
                    this.startValue = this.formatTimer2(this.startTimeValue)
                    this.showPicker = false
                } else {
                    this.endValue = this.formatTimer2(this.endTimeValue)
                    this.showPickerEnd = false
                }
            },
            confirmStatus(status, key) {
                this.status = status
                let item = this.order_status[key]
                if (!item || !item['val']) {
                    this.$toast.fail('订单状态选择失败')
                    return false
                }
                this.statusKey = this.order_status[key]['val']
                this.showPickerStatus = false
            },
            orderDetail(id) {
                return false
                this.$router.push({path: '/user/order/' + id})
            }
        }
    }
</script >
<style >
    .order-list .order-list-group {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .order-border {
        border: none;
        border-radius: 0.9rem;
    }

    .user-order {
        background-color: #eeeeee;
            min-height: calc(100vh - 40px);
    }

    .order-top {
        background-color: #fff;
           display: flex;
    }
</style >
