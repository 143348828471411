<template >
  <div id="app">
  
    <transition name="fade" mode="out-in">
    <div :class="price_by_login&&!$store.getters.token?'noauth-price':''" key="main" v-if="!landing">
      <router-view v-cloak />
      
      <van-number-keyboard safe-area-inset-bottom />
    </div>
    <div key="land" v-if="landing">
      <div class="landing-page">
           <img v-show="bg" class="bg" :src="bg" />
        <div class="content">
       
          <img v-show="logo" class="logo" :src="logo" />
          <!-- <div class="shop-name">{{ shopName }}</div> -->
        </div>
      </div>
    </div>
   
     
    </transition>
  </div>
</template>
<script>

const generateLink=(rel,logo)=>{
    var link = document.querySelector("link[rel*='"+rel+"']") || document.createElement('link');
          link.href = logo;
          link.rel=rel;
         if(rel=='apple-touch-icon'){link.size ="57x57";}
          document.getElementsByTagName('head')[0].appendChild(link);
}
export default {
  data() {
    return {
    
      shopName: "",
      landing: true,
      logo: "",
      bg:"",
      price_by_login:false
    };
  },
  mounted() {
    sessionStorage.setItem("shop", "{}");
    const confirmorderid =sessionStorage.getItem("confirmorderid")
    var skipAd =  this.$route.query.skipAd;
    if(confirmorderid){
      this.$store.getters.confirmorderid = confirmorderid;
    }
     this.$setMenuText();
    const getShopInfo = () => {
      let shop = sessionStorage.getItem("shop") || "{}";
      shop = JSON.parse(shop);
       shop.config =  shop.config||{}
       this.price_by_login =shop.config.price_by_login;
      
      if(shop.config.logo){
          generateLink('apple-touch-icon', shop.config.logo);
          generateLink('shortcut icon', shop.config.logo);
            // generateLink('icon', shop.config.logo);
           var meta = document.querySelector("meta[property*='og:title']") || document.createElement('meta');
           meta.content = shop.name;
          document.getElementsByTagName('head')[0].appendChild(meta);
      }
      return shop;
    };
    const setShopPic =()=>{
      this.bg = shop.config.product_bg;
      if(!shop.config.product_bg){
          this.logo =  shop.config.index_bg;
      }
      this.shopName = shop.name;
      if((this.bg||this.logo)&&!skipAd){
         setTimeout(() => {
        this.landing = false;
      }, 1000);
      }else{
          this.landing = false;
      }
    }
    let shop = getShopInfo();
    if (shop.name) {
     setShopPic();
     
    } else {
      this.$store.dispatch("GetShop").then((res, b) => {
        shop = getShopInfo();
        shop.config = shop.config||{};
        setShopPic();
      });
    }
  },
  methods: {},
};
</script>


<style >

  .bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
  .slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 2s
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
    opacity: 0
}
.landing-page {
  height: 100vh;
  width: 100vw;
  background: #fff;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top:0;
  overflow: hidden;
}
.landing-page .content {
  transform: translate(0, -50%);
}
.landing-page .content img {
     width: 120px;
    /* height: 100px; */
    display: block;
    margin: 0 auto;
}
.landing-page .content .shop-name {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-top: 10px;
}
@media screen and (min-width: 1000px) {
  body {
    max-width: 820px;
  }
  .bottom-text-1{
      width: 820px!important;
  }
  .van-tabs .van-sticky {
    width: 820px;
  }
  .goods .van-grid-item {
    padding: 0 10px;
    width: 400px;
  }
  .series-goods .van-grid-item {
    padding: 0 10px;
    width: 350px;
  }
  .land-body img{
    width:820px;
  }
  .goods-item-mobile .van-grid-item__icon-wrapper {
    width: 345px !important;
       height: 345px!important;
  }
  .goods .van-grid-item__icon-wrapper {
    width: 390px !important;
        height: 390px!important;
  }
  .index-page .goods-item-mobile .van-grid-item__icon-wrapper {
    width: 345px !important;
    height:auto!important;
  }
  .van-tabbar--fixed,
  .van-button--block,
  .van-submit-bar,
  .van-sticky--fixed,
  .van-popup--bottom {
    max-width: 820px;
    left: 50% !important;
   margin-left: -410px!important;
  }
  .index-page .goods .van-grid-item__icon-wrapper {
    width: 390px !important;
     height:auto!important;
  }
  .goods .van-image__img {
    max-width: 390px !important;
  }
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  padding-bottom: 50px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

[v-cloak] {
  display: none;
}
.van-tabs__line {
  background-color: #07c160;
}
.van-sidebar-item--select:before {
  background-color: #07c160;
}
.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #07c160;
}
.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #07c160;
}
.van-tabbar-item--active {
  color: #07c160;
}
 .bg{
    width: 100vw!important;
    height: 100vh!important;
    background-repeat: no-repeat;
}
</style >
