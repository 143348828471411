<template >
    <div style="    display: block;
    text-align: center;
    margin: 180px auto;
    color: gray;" class="land-body" >
        <img style="    margin: 0 auto;
    display: block;
    width: 100px;
    margin-bottom: 20px;" src="building.png" />
        功能建设中
      
    </div >
</template >

<script >
    import {
    Dialog} from 'vant';
    export default {
        name: "Land",
        data() {
            return {
               
            };
        },
        methods: {
           
        }
    };
</script >
<style >
   .form{
           padding: 20px;
   }
</style >
