<template >
    <div >
        <!-- <van-image class="user-poster" width="100%" fit="contain" src="/user_background.jpg" /> -->
        <!-- <van-cell-group class="user-group">
          <van-cell icon="records" title="我的订单" is-link />
          <van-cell icon="like-o" title="收藏夹" is-link  to="cart" />
        </van-cell-group>-->
        <!-- <van-cell-group v-if="$store.getters.token" style="text-align: center" :border="false" >
            <van-cell  size="large" :title="" :border="false" />
            <van-cell size="large" :title="$store.getters.user.mobile" />
        </van-cell-group > -->
        <div class="mine-bg" style=" ">
          
            <div  @click="toland"   class="avatar">{{$store.getters.user.name}}</div>
            <div v-if="$store.getters.token" @click="toland" style="    display: flex;
    align-items: center;
    justify-content: space-between;
    
    flex: 1;" >{{$store.getters.user.mobile}}<van-icon  v-if="$store.getters.shop.config.change_shop" name="arrow" /></div>
     <div v-if="deposit_balance" style="flex:100%" v-html="deposit_balance"></div>
        </div>
        <van-cell-group v-if="$store.getters.token" >
            <!-- <van-cell icon="manager-o" title="客户列表" is-link to="customer" /> -->
             <van-cell icon="orders-o" title="临时单列表" is-link to="/user/order?status=draft" />
            <van-cell icon="orders-o" title="商品订单列表" is-link to="/user/order?status=created" />
        
            <van-cell icon="orders-o" title="面料订单列表" is-link to="/user/product_order" v-if="$store.getters.shop.config.enable_product_store"/>
                 <van-cell icon="location-o" title="地址管理" is-link to="/user/addresslist" />
            <!-- <van-cell icon="more-o" title="系统设置" is-link /> -->
            <van-cell
                    v-if="$store.getters.shop.phone&&isWeb"
                    :url="'tel:'+$store.getters.shop.phone"
                    icon="phone-o"
                    title="联系我们"
                    is-link
            />
           
            <van-cell icon="gold-coin-o" title="退出登录" @click="logout" is-link />
         
              
           <van-cell v-if="$store.getters.user.auth.AllowTogglePrice" center title="查看工厂价">
            <template #right-icon>
                <van-switch v-model="allow_toggle_price_checked" @input="onInput"  size="24" />
            </template>
            </van-cell> 
        </van-cell-group >
        <div v-else >
       
        <van-button type="primary" block to="/login" class="login-button" >去登录</van-button >
        </div>
        <Footer :count="count"></Footer >
    </div >
</template >

<script >
    import Footer from "@/components/Footer.vue";

    export default {
        name: "User",
       
        components: {Footer},
        data() {
            return {
                checked:false,
                allow_toggle_price_checked:false,
                 actions: [{ name: '打开' }, { name: '关闭' }],
                show:false,
                count:0,
                deposit_balance:'',
                 isWeb:true
            };
        },
        mounted(){
             this.count =  localStorage.getItem('cartcount');
             this.allow_toggle_price_checked = localStorage.getItem('allow_toggle_price_checked')=='true';
             this.$http.get("/clients/clientInfo").then((res)=>{
           
                 this.deposit_balance ='保证金：'+res.deposit_balance
             })
            wx.miniProgram.getEnv((res)=> { 
              
                if(res.miniprogram){
                  this.isWeb = true;
                }
             })
        },
        methods: {
            onInput(){
              localStorage.setItem('allow_toggle_price_checked', this.allow_toggle_price_checked+'' )   
            },
            toland(){
                if(!this.$store.getters.shop.config.change_shop){
                    return 
                }
              this.$router.replace("/land")
                   
                   
                 
            },
            makephone(){

            },
            logout() {
                this.$store.dispatch("Logout");
                this.$store.dispatch("GetShop")
            }
        }
    };
</script >

<style >
    .user-poster {
        width: 100%;
        max-height: 53vw;
        display: block;
    }

    .user-group {
        margin-bottom: 15px;
    }

    .user-links {
        padding: 15px 0;
        font-size: 12px;
        text-align: center;
        background-color: #fff;
    }

    .user-links .van-icon {
        display: block;
        font-size: 24px;
    }

    .login-button {
        margin-top: 1rem;
        transform: translate(10vw,0);
        width: 80%!important;
    }
    .avatar{
   width: 60px;
    height: 60px;
    background: linear-gradient(267deg, #ff8440 2%, #ff6a50 100%);
    border-radius: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    color: rgb(255, 255, 255);
    margin-right: 20px;
    font-weight: 600;
        transition: all .3s ease;
            overflow: hidden;
    }
    .mine-bg{
           min-height: 140px;
    background: #1a67a5 url(//pic.c-ctrip.com/platform/h5/my/home/photo_myctrip@2x_6.22.jpg) no-repeat;
    display: flex;
        background-size: 100% 100%;
    align-items: center;
    padding-left: 20px;
        padding-right: 10px;
    color: #fff;
        flex-wrap: wrap;
    }
</style >
