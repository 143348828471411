import Vue from 'vue';
import VueRouter from 'vue-router';
import Land from '../views/Land.vue';
import Index from '../views/Index.vue';
import Series from '../views/Series.vue';
import SeriesDetail from '../views/SeriesDetail.vue';
import Case from '../views/Case.vue';
import CaseDetail from '../views/CaseDetail.vue';
import Goods from '../views/Goods.vue';
import CustomDesign from '../views/CustomDesign.vue';
import Products from '../views/Products.vue';
import Login from '../views/Login.vue';
import User from '../views/User.vue';
import UserOrder from '../views/UserOrder.vue';
import UserProductsOrder from '../views/UserProductsOrder.vue';
import Customer from '../views/Customer.vue';
import Cart from '../views/Cart.vue';
import OrderDetail from '../views/OrderDetail.vue';
import LiveList from "../views/livelist.vue";
import AddressLists from "../views/AddressLists";
import AddressAdd from "../views/AddressAdd";
import ConfirmOrder from "../views/ConfirmOrder";
import SuccessOrder from "../views/SuccessPay";
import DesignList from "../views/DesignList";
import InProgress from "../views/InProgress";
const originalPush = VueRouter.prototype.push
Vue.use(VueRouter);
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [
    {
        path: '/',
        name: 'default',
        component: Index,
        meta: {
            title: ''
        },
        beforeEnter: (to, from, next) => {
          
            if (to.query.shop_id) {
                next({
                    name: index,
                    params: { shop_id: to.query.shop_id },
                    replace: true
                });
            } else {
                next({
                    name: 'index',
                    replace: true
                });
            }
        }
    },
    {
        path: '/land',
        name: 'land',
        component: Land,
        meta: {
            title: ''
        }
    },
    {
        path: '/shop/:shop_id?',
        name: 'index',
        component: Index,
        meta: {
            title: ''
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '登录'
        }
    },
    {
        path: '/clients/series/:series_id',
        name: 'seriesDetail',
        component: SeriesDetail,
        meta: {
            title: '系列详情',
            is_login: 0
        }
    },
    {
        path: '/clients/livelist',
        name: 'liveList',
        component: LiveList,
        meta: {
            title: '视频监控',
            is_login: 0
        }
    },
    {
        path: '/series/:shop_id/:series_id?/:order_type?',
        name: 'series',
        component: Series,
        meta: {
            title: '分类列表'
        }
    },
    {
        path: '/design/list',
        name: 'designlist',
        component: DesignList,
        meta: {
            title: '换装设计'
        }
    },
    {
        path: '/goods/:shop_id/:design_id',
        name: 'goods',
        component: Goods,
        meta: {
            title: '商品详情'
        }
    },
    {
        path: '/case/:shop_id/:case_series_id?/:order_type?',
        name: 'case',
        component: Case,
        meta: {
            title: '案例列表'
        }
    },
    {
        path: '/case_detail/:shop_id/:cases_id',
        name: 'case_detail',
        component: CaseDetail,
        meta: {
            title: '案例详情'
        }
    },
    {
        path: '/design/:shop_id/:design_id',
        name: 'customDesign',
        component: CustomDesign,
        meta: {
            title: '换装设计'
        }
    },
    {
        path: '/products/:shop_id/:product_id',
        name: 'products',
        component: Products,
        meta: {
            title: '面料详情'
        }
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart,
        meta: {
            title: '购物车',
            is_login: true
        }
    },
    {
        path: '/confirmorder',
        name: 'confirmorder',
        component: ConfirmOrder,
        meta: {
            title: '确认下单'
        }
    },
    {
        path: '/successorder',
        name: 'successorder',
        component: SuccessOrder,
        meta: {
            title: '确认下单'
        }
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        meta: {
            title: '个人中心'
        }
    },
    {
        path: '/customer',
        name: 'customer',
        component: Customer,
        meta: {
            title: '客户管理',
            is_login: true
        }
    },
    {
        path: '/user/order',
        name: 'userOrder',
        component: UserOrder,
        meta: {
            title: '订单中心',
            is_login: true
        }
    },
    {
        path: '/user/product_order',
        name: 'userProductsOrder',
        component: UserProductsOrder,
        meta: {
            title: '订单中心',
            is_login: true
        }
    },
    {
        path: '/user/addresslist',
        name: 'addressList',
        component: AddressLists,
        meta: {
            title: '地址管理',
            is_login: true
        }
    },
    {
        path: '/user/addressadd',
        name: 'addressAdd',
        component: AddressAdd,
        meta: {
            title: '新增地址',
            is_login: true
        }
    },
    {
        path: '/user/order/:order_id',
        name: 'orderDetail',
        component: OrderDetail,
        meta: {
            title: '订单详情',
            is_login: true
        }
    },
    {
        path: '/in-progress',
        name: 'inprogress',
        component: InProgress,
        meta: {
            title: '功能建设中',
            is_login: true
        }
    }
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

export default router;
