<template >
    <div style="background:#f7f7f7;height: 100vh;" class="cart" >
       <van-popup  v-model="showDateModal"
        round
        position="bottom"
        style="padding-top: 40px;">
      <van-datetime-picker
      v-model="delivery_date2"
      type="date"
      @confirm="onSelectDate"
      @cancel="onCancelDate"
     
      title="选择交付日期"
      :min-date="mindate"
     
/>
</van-popup>
       <van-popup
        v-model="showBalance"
        round
        position="bottom"
        style="padding-top: 40px;"
        >
    保证金
    <van-radio-group v-model="radio">
   <van-cell-group>
    <van-cell title="单选框 1" clickable @click="radio = '1'">
      <template #right-icon>
        <van-radio name="1" />
      </template>
    </van-cell>
    <van-cell title="单选框 2" clickable @click="radio = '2'">
      <template #right-icon>
        <van-radio name="2" />
      </template>
    </van-cell>
  </van-cell-group>
    </van-radio-group>
   <van-button type="primary" block>确定</van-button>
</van-popup>
<van-sticky :offset-top="0">
  

    <van-nav-bar
  :title="!orderid?'确认下单':'修改订单'"
  left-text="返回"
 
  left-arrow
  @click-left="onClickLeft"
  
/>
           <van-cell-group>
                 <div @click="gotoaddress" v-if="address.name" class="">
                  <div class="van-address-item van-contact-card">
                      <div class="van-cell van-cell--borderless"><div class="van-cell__value van-cell__value--alone van-address-item__value">
                      <div role="radio" tabindex="-1" aria-checked="false" class="van-radio"><span class="van-radio__label"><div class="van-address-item__name">{{address.name}} {{address.phone}}</div><div class="van-address-item__address">{{address.street}}</div></span></div></div><i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i></div></div>
                </div>
                <!-- <div @click="gotoaddress" style="padding: 20px 10px;justify-content: space-between;display: flex;" v-else>
                    选择一个地址<i class="van-icon van-icon-arrow van-cell__right-icon"></i>
                </div> -->
                <van-contact-card add-text="选择联系人/收货地址" v-else type="add" @click="gotoaddress" />
            </van-cell-group>
            </van-sticky>
            <van-cell-group style="padding-bottom: 80px">
            <template  v-for="item in cardslength">
             <van-skeleton  avatar-size="70px" avatar-shape="shape" title avatar :row="3" :loading="loading">
             </van-skeleton>
            </template>
           
            <div v-if="!orderid||$route.query.order_id" :class="isAll?'scroll-list':'no-max-height scroll-list'">
                <van-card
                     style="background:#fff"
                        v-for="card in cards.slice(0,(isAll?cards.length:3))"
                        :key="card.index"
                        @click.prevent="showCartInfoPopup(card)"
                        :thumb="card.design_url"
                >
                <div slot="title"  style="margin:10px 6px 0 0;font-size:14px;" v-if="!card.design_type">
                    <div  >
                        <span  style="float:left" >{{ card.serie_code }} {{card.design_code?'-':''}} {{card.design_code}}</span >
                        <span style="float:right" >¥{{ price_ratio2(card.total_price_label,card.price_ratio) }}</span >
                        <div style="clear:both;" ></div >
                    </div >
                    <div slot="tags" >
                        <!-- <span
                                style="margin-left:5px;"
                        >尺寸：{{ card.window_width }}x{{ card.window_height }}</span > -->
                        <div v-if="card.install_location" class="">
                            位置：{{ card.install_location }}
                        </div>
                        <div v-if="card.customer">客户：{{ card.customer }}</div>
                    </div >
                    
                    <div v-if=" card.comment" slot="price" >备注：{{ card.comment }}</div >
                  <div :key="item" v-for="(item) in card.pos_items.qita">
                           
                            <van-card
                            @card-thumb-size="40"
                             :num="(item.quantity||0)+item.unit_label"
                            :price="price_ratio2(item.price,card.price_ratio)+'/'+item.unit_label"
                             class="van-hairline--bottom"
                            :title="item.product_code"
                             :thumb="'/storage/'+item.attach"
                            />
                         
                         </div>
                    </div >
                    <div slot="title"  style="margin:10px 6px 0 0;font-size:14px;"  v-else>
                         
                     <div >
                         <span style="float:left" >{{ card.serie_code }}-{{card.design_code}}</span >
                        <span v-if="card.res" style="float:right" >{{price_ratio2(card.total_price,card.price_ratio)}} {{card.res.quantity?'x'+card.res.quantity:''}}</span >
                        <div style="clear:both;" ></div>
                       
                         <div :key="item" v-for="(item,key) in card.res">
                              <template v-if="key!='dict'&&!card.un_checked_pos.includes(key)">
                             {{key!='quantity'?card.res.dict[key]:''}} 
                             <div v-if="key!='quantity'" style="    display: flex;flex-wrap: wrap;">
                             <div style="margin-left:10px" v-for="($item,key) in  item">
                                 
                                  {{card.res.dict[key]}}：
                                  {{card.res.dict[$item]||$item}}
                             </div>
                             </div>
                              </template>
                          </div>
                    </div >

                    
                    </div>
                </van-card >
                <van-divider @click="expandAll" v-if="cards.length>3&&!isAll">还有更多，点击加载</van-divider>

                 
                 </div>
                 <h3  class="h3">订单及业主信息</h3>
               <van-cell-group style="padding-left:10px">
          
             <div  v-if="order_show_item.includes('project_name')" class="item">
                 业主名称 <label v-if="isReuqireItem('project_name')" style="color:red">*</label>
               <input style="flex:1;margin-left:10px" v-model="project_name"  placeholder="业主名称" />
               <span  class="empty-message" v-if="isReuqireItem('project_name')&&!project_name">业主名称不能为空</span>
            </div>
             <div  v-if="order_show_item.includes('community_name')" class="item">
                  电话地址 <label v-if="isReuqireItem('community_name')" style="color:red">*</label>
               <input style="flex:1;;margin-left:10px" v-model="community_name"  placeholder="电话地址" />
               <span  class="empty-message" v-if="isReuqireItem('community_name')&&!community_name">电话地址不能为空</span>

            </div>
            <div v-if="order_show_item.includes('delivery_date')"  class="item">
                  交付日期 <label v-if="isReuqireItem('delivery_date')" style="color:red">*</label>
               <div style="flex:1;;margin-left:10px" @click="openDateModal">{{delivery_date||'选择交付日期'}}</div>
              <span  class="empty-message" v-if="isReuqireItem('delivery_date')&&!delivery_date">交付日期不能为空</span>

            </div>
        </van-cell-group>
             
                <div  v-if="order_show_item.includes('comment')" style="margin-top:8px;padding-left:10px;    font-size: 14px;" >订单备注（厂家可见） <label v-if="isReuqireItem('comment')" style="color:red">*</label></div >
                <van-field v-if="order_show_item.includes('comment')" v-model="$store.getters.confirmorder.comment" placeholder="订单备注" />
                   <span  style="margin-left:10px" class="empty-message" v-if="isReuqireItem('comment')&&!$store.getters.confirmorder.comment">订单备注不能为空</span>
                 <div style="margin-top:8px;padding-left:10px;font-size: 14px;margin-bottom: 10px;" >补充凭证（最多上传3张）</div >
            <div class="aaa" style="padding-left:10px;">
              <template>
                <van-uploader
                  v-model="$store.getters.confirmorder.vouchers"
                  result-type="file"
                  :after-read="afterRead"
                  :max-count="3"
                />
              </template>
             <div style="color: red;font-size: 12px;" v-if="isUploadError">
                 补充凭证必须上传
             </div>
            </div>
            </van-cell-group >
           
           <!-- 
          <van-cell-group class="item-2" style="" inset>
              订单金额：
          </van-cell-group>
             <van-cell-group class="item-2"  inset>
              <van-icon name="balance-o" />保证金：<span @click="openBalance">请选择保<van-icon name="arrow" /></span>
          </van-cell-group> -->
         
                 <div style="height:50px;width:100%"></div>
    <div data-v-2c101ab0=""  v-if="cards.length||orderid" class="van-submit-bar">
       <div style="    padding: 10px" v-if="!is_factory_price" class="item">
                 折扣
                <div><input class="discount" @blur="noeditdiscount=true" @input="noeditdiscount=false"  :min="1/price_ratios" type="number"  placeholder="输入折扣" v-model="sale_discount" />
                <span v-if="noeditdiscount&&price_ratios&&sale_discount<1/price_ratios&&sale_discount" style="font-size: 12px;display:block;color: red;">超出最大折扣率</span></div>
            </div>
        <div data-v-2c101ab0="" class="van-submit-bar__bar">
            <div data-v-2c101ab0="" class="van-submit-bar__text">
                  <span v-if="AllowTogglePrice&&orderid&&is_allow_toggle_price_checked" style="margin-right: 10px;
    display: block;">出厂价：¥{{total_price2}}</span>
                <span data-v-2c101ab0="">{{label}}</span><span data-v-2c101ab0="" class="van-submit-bar__price van-submit-bar__price--integer">¥{{_total_price}}</span></div>
                <button @click="postCard" data-v-2c101ab0="" :disabled="isdisabled" :class="'van-button van-button--danger van-button--normal  van-button--round van-submit-bar__button van-submit-bar__button--danger'+(isdisabled?' van-button--disabled':'')">
                    <div data-v-2c101ab0="" class="van-button__content">
                    <span data-v-2c101ab0="" class="van-button__text">确认下单</span></div></button></div></div>
        <!-- <van-submit-bar  :label="label"    :disabled=""  :price="_total_price" button-text="确认下单" @submit="postCard" > -->
            
        <!-- </van-submit-bar> -->
         <!-- <van-popup position="bottom" :style="{ 'height': '80vh',width:'100%','z-index':'200000!important' }" v-model="showAddress">
             <AddressList />
         </van-popup> -->
        <!-- <van-popup v-model="show" position="bottom" :style="{ 'height': '480px' }" >
            
        </van-popup > -->
        <van-empty v-if="!cardslength&&!loading" >没有商品</van-empty >
        <!-- <Footer :count="cards.length||cardslength"></Footer > -->
    </div >
</template >

<script >

    import { areaList } from '@/area';
  
    export default {
        name: "ConfirmOrder",
        components: {},
        data() {
            return {
              order_show_item:[],
              submitted:false,
              smart_curtain_require_item:[],
              mindate:new Date(),
              community_name:"",
              project_name:"",
              delivery_date:0,
              delivery_date2:new Date(),
              showDateModal:false,
                noeditdiscount:true,
                price_ratios:1,
                total_price2:0,
                AllowTogglePrice:false,
                sale_discount:'',
                orderid:'',
                is_factory_price:false,
                isAll:false,
                hasScroll:false,
                overlay:true,
                showBalance:false,
                radio:2,
                showAddress:false,
                address:{},
                isPhone:true,
                vouchers:[],
                phone:"",
                street:"",
                cardslength:0,
                isShowOtherCartInfoPop:false,
                loading:true,
                customer:"",
                name:"",
                design:{},
                cards: [],
                activeHeader: 'chuanglian',
                show: false,
                isShowCartInfoPop: false,
                isShowCartInfoPop2: false,
                cart_info: {},
                total_price: 0,
                comment: ""
            };
        },
       computed:{
          isReuqireItemValid(){
           
           let aa =   this.smart_curtain_require_item
           
           let bb=  aa.every((item)=>{
              if(item=='comment'){
                return this.$store.getters.confirmorder.comment
              }else{
                return this[item]
              }
             })
             return bb
          },
          is_allow_toggle_price_checked(){
             return  localStorage.getItem('allow_toggle_price_checked')=='true'
           },
           isdisabled(){
              return  (this.noeditdiscount&&this.price_ratios&&this.sale_discount&&this.sale_discount<(1/this.price_ratios))||!this.address.name||!this.address.street||!this.address.phone||!this.isPhone||this.isUploadError||!this.isReuqireItemValid
           },
           label(){
            return   (this.sale_discount?'折后价：':'合计：')
           },
          _total_price(){
            let {sale_discount=1} =  this;
            if(sale_discount==''){
                sale_discount = 1;
            }
            let total_price = parseFloat(this.total_price*sale_discount)
           return  total_price.toFixed(2)
          },
          isUploadError(){
         
              return  this.$store.getters.confirmorder.vouchers.length==0&&(localStorage.queryShopId=='224'||localStorage.queryShopId=='1')&&this.orderid!=''&&this.orderid!=undefined
          }
       },
        watch: {
            isShowCartInfoPop(newVal, oldVal) {
                newVal === false && this.getList();
            },
            isShowCartInfoPop2(newVal, oldVal) {
                newVal === false && this.getList();
            }
        },
        mounted() {
          let confirmorder = this.$store.getters.confirmorder
          let orderid=  this.$route.query.orderid||confirmorder.orderid||this.$route.query.order_id;
           if(orderid=='undefined'){
             orderid = false
           }
            const user = JSON.parse(localStorage.getItem("user"));
             let shop = sessionStorage.getItem("shop") || "{}";
     
         if(shop!='{}'){
            shop = JSON.parse(shop)||{config:{}};
            this.order_show_item = shop.config.order_show_item||[];
            let smart_curtain_require_item =shop.config.smart_curtain_require_item|| [];
            this.smart_curtain_require_item = smart_curtain_require_item.filter((item)=>["project_name","community_name","delivery_date","comment"].includes(item))
          
          }
          this.sale_discount = confirmorder.sale_discount;
          this.orderid = orderid;
            let is_factory_price = user.is_factory_price;
           this.is_factory_price = is_factory_price;
           this.price_ratios = user.price_ratio;
            if(!orderid){
             
            this.getList();
             this.cardslength = parseInt(localStorage.getItem('cartcount')||0);
            if(sessionStorage.getItem("rightOpenModal")=='true'){
               this.address =JSON.parse(sessionStorage.getItem("selectedAddress"));
               sessionStorage.setItem("rightOpenModal",undefined)
               sessionStorage.setItem("selectedAddress",undefined)
            }else{
              this.getMyAddress()
            }
            }else{
             
                if(orderid){
                      this.getList();
                }
                this.AllowTogglePrice =user.auth.AllowTogglePrice ||false
              this.$http.get("/clients/orders/"+orderid+'/info').then((res)=>{
                    this.total_price2 = res.total_price;
                      confirmorder = this.$store.getters.confirmorder
                  if(sessionStorage.getItem("rightOpenModal")=='true'){
                    this.address =JSON.parse(sessionStorage.getItem("selectedAddress"));
                    sessionStorage.setItem("rightOpenModal",undefined)
                    sessionStorage.setItem("selectedAddress",undefined)
                    }else{
                    let address ={phone:res.phone,street:res.address,name:res.name} ;
                        this.address = address;
                    }
              
                let sale_discount =res.sale_discount||'';
                let comment =res.comment;
                this.total_price = is_factory_price?res.total_price : res.sale_actual_pre_price;
                let vouchers = res.vouchers;
                if(orderid){
                confirmorder.orderid = orderid
                confirmorder.sale_discount =sale_discount;
                this.sale_discount = confirmorder.sale_discount;
                this.community_name = res.community_name
                this.project_name = res.project_name
                this.delivery_date = res.delivery_date
                confirmorder.vouchers =vouchers?vouchers.split(",").map((item)=>{return {status:"done",url:item}}):[];
                confirmorder.comment =comment;
                }
               
              })
            }
           
           
         
             
        },
        
        methods: {
          
          isReuqireItem(name){
            return  this.smart_curtain_require_item.includes(name)&&this.order_show_item.includes(name);
          },
    openDateModal(){
       this.delivery_date2 = new Date(this.delivery_date)
     this.showDateModal = true;
    },
          onSelectDate(e){
            let aa = new Date(e).toLocaleString().replace("上午",' ').replace("下午",' ') 
            this.delivery_date2 = aa.split(" ")[0].replace(/\//g,'-') 
            this.delivery_date = this.delivery_date2
           this.showDateModal = false;
          },
          onCancelDate(){
           this.showDateModal = false;
          },
            expandAll(){
               this.isAll = true;
            },
            price_ratio2(v='',price_ratio){
                if(v&&v!=0&&v!=''){
                    v=v+''
                    v = parseFloat(v.replace("¥",""))
                       v = Number(v* (price_ratio||1)).toFixed(2)
                      if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='round'){
       v = Math.round(v)
      
    }
    if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='floor'){
        v = Math.floor(v)
         
    }
                   return v;
                }else if(v==0){
                    return 0
                }else if(v==''||v==null){
                    return ''
                }
            
            },
            openBalance(){
              this.showBalance = true
            },
            onClickLeft(){
               this.$router.replace(!this.orderid?'/cart':'/user/order?status=draft')
            },
            gotoaddress(){
               // this.showAddress = true;
             this.$router.replace('/user/addresslist?from=cart&orderid='+this.$route.query.orderid)
            },
            getMyAddress(){
            this.$http.get("/clients/address").then((res)=>{
           res = res||[];
           const {province_list,city_list,county_list} = areaList;
           res =  res.map((item)=>{
               const {province_code,city_code,district_code} = item;
               const province_str =  province_list[province_code]||'';
               const city_str =  city_list[city_code]||'';
                const country_str =  county_list[district_code]||'';
               item ={
                   ...item,
                   street:province_str+''+city_str+''+country_str+''+item.street,
                   tel:item.phone,
                   name:item.name,
                   isDefault:item.default,
                   id:item.id,
                   default:item.default,
                   district_code:item.district_code,
               }
            return item;
           }).filter((item)=>item.default)
           this.address = res[0]||{};
        })
        },
    isphoneAction(){
     this.phone =this.phone||'';
      let regx =/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
     this.isPhone = regx.test(this.address.phone);
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      //new 一个FormData格式的参数
      let params = new FormData();
      params.append("file", file.file);
      let config = {
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data",
        },
      };
      //把 uploadUrl 换成自己的 上传路径
      this.$http
        .post("/clients/orders/image", params, config)
        .then((res) => {
          
          if (res) {
           
            this.$store.getters.confirmorder.vouchers =  this.$store.getters.confirmorder.vouchers.filter((item)=>item.url)
             this.$store.getters.confirmorder.vouchers =  this.$store.getters.confirmorder.vouchers.concat([
              {
                url: res,
                status: "done",
                message: "上传完成",
              },
            ]);
           
          } else {
            //否则 Toast 提示
            this.$toast.fail("上传失败");
          }
        })
        .catch((err) => {
          this.$toast.fail("系统异常");
        });

    },
            getList() {
                this.cards =[];
                 this.loading =true;
                   let {orderid}= this;
                
                  
                 let url ="/clients/carts?order_type=" +this.getType()
                 url=!orderid?url:(url+"&order_id="+orderid)
               return  this.$http.get(url).then(res => {
                    this.loading =false
                    this.cards = res;
                    let that = this;
                    var all_total_price = 0.00;
                    this.cards =this.cards.map(function (item1, index) {
                         item1.dict =item1.dict||{};
                           item1.un_checked_pos =   item1.un_checked_pos||[]
                        all_total_price =parseFloat(all_total_price)+parseFloat(that.price_ratio2.call(that,item1.total_price,item1.price_ratio));
                      return item1
                    });
                    this.cardslength  = this.cards.length;
                      localStorage.setItem('cartcount',this.cards.length)
                    this.total_price = parseFloat(all_total_price);
                   !this.orderid&& this.$nextTick(()=>{
                       var scrollList = document.querySelector(".scroll-list")||{}
                       if(scrollList.clientHeight <scrollList.scrollHeight){
                           this.hasScroll = true;
                       }
                    })
                });
            },
            getType() {
                let isMianliao= this.$route.query.isMianliao ||sessionStorage.getItem("isMianliao");
                let type = 'design_order'
                if (isMianliao&&isMianliao!='false') {
                    type = 'odd_order'
                }
                return type
            },
            showPopup(addressload=true) {
                    addressload&& this.getMyAddress();
                this.show = true;
                let user = JSON.parse(localStorage.getItem("user")||'{}')
                 this.phone = user.client.mobile;
                 this.street = user.client.address;
                 this.name = user.client.person;
            },
            hideCartInfoPopup() {
                this.isShowCartInfoPop = false;
                  this.isShowOtherCartInfoPop = false;
            },
            showCartInfoPopup(cart) {
               
              
                this.cart_info = cart;
                if(cart.design_type){
                 this.isShowOtherCartInfoPop = true;
                }else{
                  this.isShowCartInfoPop = true;
                }
              
            },
            hideCartInfoPopup2() {
                this.isShowCartInfoPop2 = false;
            },
            showCartInfoPopup2(cart,design) {
                this.cart_info = cart;
                this.design =design;
                this.isShowCartInfoPop2 = true;
            },
            editCard(brand,urls){
                 let that = this;
                const {vouchers,comment} =  this.$store.getters.confirmorder
               this.$http
                            .patch("/clients/orders/" + this.orderid, {source:"zhilian",
                              community_name:this.community_name,
                          project_name:this.project_name,
                          delivery_date:this.delivery_date?this.delivery_date:undefined,
                            sale_discount:this.sale_discount,brand,status:'draft',comment:comment,name:this.address.name,street:this.address.street,phone:this.address.phone,vouchers:urls})
                            .then(res => {
                                sessionStorage.removeItem("confirmorderid");
                                 this.$store.getters.confirmorderid = undefined;
                                this.$router.replace("/user/order?status=draft");
                                

                            });
            },
            postCard() {
                
                let that = this;
                const {vouchers,comment} =  this.$store.getters.confirmorder
                let urls =vouchers.map((item)=>{
                     let url ='/storage'+ item.url.split("storage")[1];
                     return url;
                    }).join(',')
                   
                      let user = JSON.parse(localStorage.getItem("user")||'{}')
                        user.client = user.client||{};
                        let brand =  user.client.brand;
                        this.submitted = true;
                        if(this.orderid){
                    this.editCard(brand,urls);
                    return ;
                }
                        this.$http
                            .post("/clients/orders/carts?order_type=" + this.getType(), {
                                   community_name:this.community_name,
              project_name:this.project_name,
                delivery_date:this.delivery_date?this.delivery_date:undefined,
                              source:"zhilian",sale_discount:this.sale_discount,brand,status:'draft',comment:comment,name:this.address.name,street:this.address.street,phone:this.address.phone,vouchers:urls})
                            .then(res => {
                              if(res&&res.success==false){
                                return 
                              }
                            let queryShopId = localStorage.getItem("queryShopId")
                          
                            let {shop,user} = this.$store.getters;
                                if(this.total_price>0&&shop.wepay&&user.auth.AllowTogglePrice&&this.is_allow_toggle_price_checked){
                                let token =  localStorage.getItem("token")
                                wx&& wx.miniProgram&&  wx.miniProgram.navigateTo({url: '/pages/pay?id='+res.id+'&token='+token})
                                }
                                this.deleteLocationIndex();
                            
                                this.show = false;
                                this.getList().then(()=>{
                                      sessionStorage.removeItem("confirmorderid");
                                 this.$store.getters.confirmorderid = undefined;
                                     this.$toast.success("保存为临时单成功，等待提交订单");
                                      this.$router.replace("./successorder?isDraft=true");
                                });
                            
                                

                            }).catch((err)=>{
                                   //this.$toast.fail("下单失败！");
                            });
                  
                    
            },
            deleteLocationIndex() {
             
                // if (this.getType() === 'odd_order') {
                localStorage.removeItem('product_index');
                // }
            },
            deleteCard(id) {
                let _this = this;
                this.$dialog
                    .confirm({
                        title: "确认删除该窗帘？"
                    })
                    .then(() => {
                        _this.$http.delete("/clients/cart/" + id).then(res => {
                            _this.deleteLocationIndex();
                            _this.getList();
                        });
                    })
                    .catch(() => {
                        // on cancel
                    });
                return false;
            }
        }
    };
</script >

<style scoped>
.van-radio__icon{
    display: block;
}
   .cart .van-tabs--line .van-tabs__wrap {
    height: 44px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    left: 0;
}
 .cart .van-tabs__content{
        padding-top: 44px;
}
.one-tab .van-tabs__wrap{
  height: 0!important;
}
.one-tab .van-tabs__content{
    padding-top:0 ;
}
.cart .van-uploader__preview-image{
    max-width: 80px!important;
        min-width: 60px;
}
.item{
        display: flex;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center;
        font-size: 14px;
}
.item input{
        border: 0;
    height: 34px;
}
.item-2{
    padding: 12px 0;
    text-align: right;
}
.discount{
        background: #f7f7f7;
    border-radius: 20px;
    width: 120px;
    padding-left:20px ;
}
.scroll-list{
        max-height: calc(100vh - 450px);
    overflow: hidden;
    overflow-y: auto;
}
.no-max-height{
       max-height: initial;
}
.h3{

    background: #f7f7f7;
    font-size: 14px;
    margin: 0;
    padding-left: 10px;
    padding: 10px;
    color: #666;

}
.empty-message{
      color: red;
    margin-right: 10px;
    font-size: 12px;
}
</style >
