<!-- @format -->

<template >
    <div >
         <van-popup
              
                      position="bottom"
                    :style="{ height: '60%',overflow:'hidden' }"
                     v-model="productModalshow"
                    >
                      <van-search
                        v-model="keyWord"
                        placeholder="请输入搜索关键词"
                          @clear="onCancel"
                        @change="onSearch"
                      />
                      <van-list
                      offset="10"
                      style="display:flex;flex-wrap:wrap;height:calc(100% - 98px);overflow-y: auto;padding: 0 10px;"
                      v-model="loading"
                      :finished="finished"
                      :finished-text="Productlist.length>0?'没有更多了':'没有数据'"
                      @load="onLoad"
                >
           <div @click="setActiveProduct(index)" :class="activeProductIndex==index?'img-item active':'img-item'" v-for="(item,index) in  Productlist">
                          <img  v-if="item._text.attach&&item._text.attach!=null" :src="'/storage/'+item._text.attach"/>
                            <img  v-else />
                          <div style="text-align:center;max-height: 42px;overflow: hidden;">{{item.text}}</div>
                     </div> 
    </van-list>
                   
                   
                 
                       <div style="display:flex;">
                     <van-button :disabled="activeProductIndex==-1" style="flex:1"  @click="val => setPosItem(tmp_pos_items[item_key][item_index],item_key, item_index, 'product')" type="primary" size="normal">确定</van-button>
                      <van-button style="flex:1" plain @click="productModalshow=false;activeProductIndex=-1" type="default" size="normal">取消</van-button>
                       </div>
                    
              </van-popup>
        <van-popup v-model="is_show" :overlay="true" get-container="body" position="bottom" style="height: 90%;" @opened="init" >
             
            <van-form class="cart_form" style="margin-bottom:50px;padding-bottom:30px;" >
                <!-- 附加信息 -->
                <!-- <van-cell-group >
                    <van-field v-model="cart.customer" @change="update()" rows="1" autosize label="客户" type="textarea" placeholder="请输入客户" />
                    <van-field v-model="cart.comment" @change="update()" rows="1" autosize label="备注" type="textarea" placeholder="请输入备注" />
                </van-cell-group > -->
                <!-- 辅料 -->
                <template v-if="init_cart" >
                    <template v-for="item in options.positions" >
                        <van-cell-group class="position" v-if="tmp_pos_items[item['key']] && item['key'] === 'qita'" :key="item['key']" >
                           <van-sticky   offset-top="10vh"  >
                            <van-grid  column-num="5" >
                                <!--                                <van-grid-item>-->
                                <!--                                    <template>-->
                                <!--                                        <van-checkbox v-model="checked[item['key']]" @click="updateCheck(item['key'])" shape="square" icon-size="12px"-->
                                <!--                                                      style="color: #646566;font-size: 12px;">-->
                                <!--                                            货号-->
                                <!--                                        </van-checkbox>-->
                                <!--                                    </template>-->
                                <!--                                </van-grid-item>-->
                                <van-grid-item text="货号" />
                                <van-grid-item text="价格" />
                                <van-grid-item text="用料" />
                                <van-grid-item text="小计" />
                                <van-grid-item text="操作" >
                                    <template >
                                        <van-icon @click="add(item['key'])" name="plus" />
                                    </template >
                                </van-grid-item >
                            </van-grid >
                           </van-sticky>
                            <van-grid column-num="5" v-for="(pos, index) in tmp_pos_items[item['key']]" ref="pos" :key="index" >
                                <van-grid-item  @click="openModal(item['key'],index,pos['product_id'])">
                                    <template >
                                        <span     style="    width:45px;font-size: 12px;"   input-align="center"   >
                                        
                                            {{tmp_pos_items[item['key']][index]['product']}}
                                             <van-icon v-if="allow_toggle_product" style="    position: absolute;
    right: 5px;
    color: #bbb;
    font-size: 10px;" name="arrow-down" />
                                        </span>
                                    
                                        <!-- <van-popup position="bottom" v-model="tmp_pos_items[item['key']][index]['product_show']" >
                                            <van-picker show-toolbar :columns="Productlist" @cancel="onPosItemClick(item['key'], index, 'product', false)"
                                                        @confirm="val => setPosItem(val, item['key'], index, 'product')" />
                                        </van-popup > -->
                                    </template >
                                </van-grid-item >
                                <van-grid-item >
                                    <template >
                                        <!-- <van-field :value=""
                                                   :readonly="true"
                                                   input-align="center" placeholder="0" >
                                        </van-field > -->
                                    
                                        <span style="width: 45px; font-size: 12px;">{{price_ratio2(tmp_pos_items[item['key']][index]['price'])+'元'}}</span>
                                    </template >
                                </van-grid-item >
                                <van-grid-item >
                                    <template >
                                        <van-field v-model="tmp_pos_items[item['key']][index]['quantity']" @change="addItem(item['key'],index)" input-align="center"
                                                   placeholder="--" >
                                            <template #extra >
                                                {{tmp_pos_items[item['key']][index]['unit_label']}}
                                            </template >
                                        </van-field >
                                    </template >
                                </van-grid-item >
                                <van-grid-item >
                                    <template >
                                                                                <span style="width: 45px; font-size: 12px;">{{price_ratio2(tmp_pos_items[item['key']][index]['subtotal'])+'元'}}</span>

                                        <!-- <van-field v-model="tmp_pos_items[item['key']][index]['subtotal']" :readonly="true" type="number"
                                                   input-align="center"
                                                   :formatter="fix2" placeholder="--" /> -->
                                    </template >
                                </van-grid-item >
                                <van-grid-item >
                                    <template >
                                        <van-icon @click="del(item['key'],index)" name="cross" />
                                    </template >
                                </van-grid-item >
                            </van-grid >
                        </van-cell-group >
                    </template >
                </template >
            </van-form >
            <van-tabbar active-color="#7d7e80" :safe-area-inset-bottom="true" >
                <van-tabbar-item @click="goBack" icon="replay" >返回</van-tabbar-item >
                <van-tabbar-item @click="onSubmit" style="color: #fff; background-color: #07c160; border: 1px solid #07c160;" >保存</van-tabbar-item >
                <van-tabbar-item @click="calc" >
                    <template #icon="props" >
                        <span :formatter="fix2" >{{ price_ratio2(total) }}</span >元
                    </template >
                    总价
                </van-tabbar-item >
            </van-tabbar >
        </van-popup >
    </div >
</template >

<script >
    import { Dialog } from 'vant';
    export default {
        props: {
            show: {
                type: Boolean,
                required: true,
                default: false
            },
            design: {
                type: Object,
                default: {}
            },
            cart_info: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
            
                action: '',
                initCount: 0,
                showPickerList: {},
                Productlist: {},
                PosItemlist: {},
                pos_items: {},
                imgList: [],
                keyWord:"",
                allow_toggle_product:false,
                curpage:1,
                index:0,
                loading:false,
                finished:false,
                activeProductIndex:-1,
                productModalshow:false,
                action: '',
                initCount: 0,
                showPickerList: {},
               item_key:"",
                  item_index:0,
                  Productlist:[],
                options: {
                    fold_times: [],
                    curtain_types: [],
                    install_crafts: [],
                    install_locations: [],
                    open_styles: [],
                    craft_wailian: [],
                    craft_shalian: [],
                    positions: [],
                    process_types: [],
                    strap_dirs: []
                },
                tmp_val: {
                    checked: {}
                },
                checked: {},
                init_cart: {
                    pos_items: {}
                },
                tmp_pos_items: {},
                cart: {
                    strap_dir: '',
                    install_craft: '',
                    open_style: '',
                    craft_wailian: '',
                    craft_shalian: '',
                    install_location: '',
                    process_type: '',
                    fold_times: '',
                    total_price: 0,
                    fold_gap: '',
                    window_width: '',
                    window_height: '',
                    pos_items: {}
                }
            }
        },
        computed: {
            is_show: {
                get: function () {
                 
                    return this.show
                },
                set: function () {
                    this.show = false
                   // this.$parent.isShowCartInfoPop2 = false
                    this.$emit("hideCartInfoPopup2",false);
                }
            },
            total: function () {
                let total = 0;
                this.pos_items && this.pos_items['qita'] && this.pos_items['qita'].forEach(_ => total += this.calc(_.price, _.quantity))
                return total
            },
        },
        mounted() {
           
              if (this.design && this.design.id) {
                    this.action = 'create'
                    this.index = localStorage.getItem('product_index')
                } else if (this.cart_info) {
                    this.action = 'update'
                     this.index = this.cart_info.index
                  
                }
        },
        methods: {
             price_ratio2(v=''){
      if(v&&v!=0&&v!=''){
         v=v+''
        v = parseFloat(v.replace("¥",""))
       return Number(v* (this.cart.price_ratio||1)).toFixed(2);
      }else if(v==0){
        return 0
      }else if(v==''||v==null){
        return ''
      }
     
    },
              onCancel(){
        this.resetInfo();
         // this.getProducts();
    },
            async  onLoad(e){
       
      if(this.Productlist.length>0){
       this.curpage ++
        this.loading = true;
       this.getProducts(0,this.productcode);
      
      }
     
    },
             onSearch(e) {
       this.activeProductIndex =-1;
      this.Productlist =[];
      this.curpage = 1;
      this.getProducts(0);
      // this.Productlist = this.allProductlist.filter((item) =>
      //   item.text.includes(event.target.value)
      // );
     
    },
             setActiveProduct(index){
    
       this.activeProductIndex = index;
    },
    resetInfo(){
      this.activeProductIndex =-1;
      this.Productlist =[];
      this.curpage = 1;
      this.keyWord =''
    },
     async  openModal(key,index,productid){
                this.resetInfo();
            debugger
         this.tmp_pos_items[key][index]['product_show'] = true
          this.productcode = productid;
          this.productModalshow =true;
          await  this.getProducts(0)
           this.item_key = key;
           this.item_index = index;
         this.$forceUpdate()
            },
            init() {
                if (!this.$store.getters.token) {
                    this.is_show = false
                    this.$dialog
                        .confirm({
                            title: '请先登录',
                            confirmButtonText: '去登录'
                        })
                        .then(() => {
                            this.$store.dispatch('Logout')
                            this.$router.path('/login')
                        })
                    return false
                } else {
                    this.tmp_pos_items = {};
                       let shop = sessionStorage.getItem("shop") || "{}";
                    if(shop!='{}'){
            shop = JSON.parse(shop)||{config:{}};
      
              this.allow_toggle_product = shop.config.allow_toggle_product||false;
             
            this.$store.dispatch("GetShop").then(()=>{
         
               shop = sessionStorage.getItem("shop") || "{}";
               shop = JSON.parse(shop)||{config:{}};
                
          
               this.allow_toggle_product = shop.config.allow_toggle_product||false;

            })
          }
                    this.getClientsOptions()
                }
            },
            updateCheck(key) {
                this.checked[key] = !this.checked[key]
                this.$http.get('/clients/cart/' + this.init_cart.index + '/price?positions=' + this.getPositionStr()).then(res => {
                    this.cart.total_price = res.total_price
                }).catch(err => {
                    this.$toast.fail('系统异常')
                })
                this.$forceUpdate()
            },
            initData(res) {
                this.initCount += 1
                this.init_cart = res ||{}
                this.pos_items = res.pos_items||{}
                this.cart.price_ratio = res.price_ratio;
                this.cart.customer = res.customer
                this.cart.comment = res.comment
                Object.entries(res.pos_items ? res.pos_items : {}).forEach(([key, value]) => {
                    this.tmp_pos_items[key] = []
                    // if (this.initCount == 1) {
                    //     this.checked[key] = false
                    //     if (value.length > 0) {
                    //         this.checked[key] = true
                    //     }
                    // }
                    Object.values(value ? value : {}).forEach((v, k) => {
                        this.tmp_pos_items[key][k] = {
                            key: v && v.key ? v.key : '',
                            index: this.tmp_pos_items[key].length,
                            curtain_show: false,
                            curtain_type: v.curtain_type,
                            curtain: this.getCurtain(null, v.curtain_type)['text'],
                            product_show: false,
                            product_id: v.product_id,
                            product: this.getProduct(null, v.product_id)['text']||v.product_code,
                            price: v.price ? v.price : 0,
                            quantity: v.quantity,
                            subtotal: this.calc(v.price, v.quantity),
                            unit_label: v.unit_label,
                            fold_num: v.fold_num
                        }
                    })
                })
                this.$forceUpdate()
            },
            initCart() {
               
                if (this.design && this.design.id) {
                    this.action = 'create'
                    this.$http.get('/clients/cart/' + this.index + '?order_type=odd_order').then(res => {
                        this.initData(res)
                      
                      if(this.action === 'create'&&(res.pos_items.qita.length === 0 || !res.pos_items.qita.find(_ => _.product_id === this.design.id))) {
         this.add('qita',this.design.id,this.design.code);
           this.addItem('qita', this.tmp_pos_items.qita.length-1)
                       }   
                        if (this.action === 'create' && (res.pos_items.qita.length === 0 || !res.pos_items.qita.find(_ => _.product_id === this.design.id))) {
                           // this.add('qita');
                          //  this.setPosItem({text: this.design.code, key: this.design.id}, 'qita', 0, 'product');
                        }
                    })
                } else if (this.cart_info) {
                    this.action = 'update'
                      //this.index = this.cart_info.index
                    this.initData(this.cart_info)
                }
            },
            getClientsOptions() {
                this.$http.get('/clients/options').then(res => {
                    let options = {}
                    let _this = this
                    Object.entries(res).forEach(([key, value]) => {
                        this.showPickerList[key] = false
                        options[key] = Object.entries(value).map(([k, v]) => {
                            if (key === 'positions') {
                                this.tmp_pos_items[k] = []
                            }
                            return {
                                text: v,
                                key: k
                            }
                        })
                    })
                    this.options = options
                    this.getProducts()
                })
            },
            getProducts(type=1) {
                
                 let {client_id,shop_id} = JSON.parse(localStorage.getItem("user")||'{}')
                 let url = "/clients/helpers/products?order_type=odd_order"+'&pagesize=20&client_id='+client_id+"&shop_id="+shop_id+"&page="+this.curpage+'&code='+this.keyWord;
               let  that = this;
                let ret = this.$http.get(url).then(res => {
                  
                   let Productlist = Object.entries(res).map(([k, v]) => {
                        return {
                            text: v.code,
                            _text: v,
                            key: v.id,
                        }
                    })
                     this.Productlist = this.Productlist.concat(Productlist);
                    if(this.productModalshow){
                     that.finished = res.length==0?true:false;
                     this.loading = false;
                      this.$forceUpdate()
                    }
                     this.index = localStorage.getItem('product_index')
                
                    if (this.index && this.index !== null && this.index.length === 10) {
                      
                       type&& this.initCart();
                    } else {
                        
                        this.$http.post('/clients/cart/init', {order_type: 'odd_order'}).then(res => {
                            this.index = res.index;
                            localStorage.setItem('product_index', res.index);
                             type&&   this.initCart()
                            // this.initData(res)
                        })
                    }
                }).catch((res)=>{

                    this.loading = false;
                });
                return ret
            },
            getCurtain(val, key) {
                let t = this.options.curtain_types.find(_ => (key && _.key == key) || (val && _.text == val))
                return t ? t : {text: '', key: ''}
            },
            getProduct(val, key) {
                let t = this.Productlist.find(_ => (key && _.key == key) || (val && _.text == val))
                return t ? t : {text: '', key: ''}
            },
            calc(a, b) {
                return isNaN(parseFloat(a) * parseFloat(b)) ? 0 : parseFloat((parseFloat(a) * parseFloat(b)).toFixed(2));
            },
            fix2(val) {
                let t = val.toString()
                let min = t.indexOf('.')
                if (val && !isNaN(val) && min !== -1 && t.length > t.indexOf('.') + 2) {
                    return parseFloat(t.substring(0, min + 2 + 1))
                }
                return val
            },
            setPosItem(val, key, index, type) {
                
                 if(this.productModalshow&&type=='product'){
                   val.text = this.Productlist[this.activeProductIndex].text;
                    this.tmp_pos_items[key][index].product_id  = this.Productlist[this.activeProductIndex]._text.id;
                     this.tmp_pos_items[key][index].price =   this.Productlist[this.activeProductIndex]._text.price;
                    this.productModalshow = false
                 }else{
                  this.tmp_pos_items[key][index][type + '_id'] = val.key
                 }
               
                this.tmp_pos_items[key][index][type] = val.text
              // this.update
                this.addItem(key, index)
                this.onPosItemClick(key, index, type, false)
            },
            setPicker(val, type) {
                this.cart[type] = val['key']
                this.tmp_val[type] = val['text']
                this.update()
                this.onPickerClick(type, false)
            },
            add(key,product_id=0,product='') {
                this.tmp_pos_items[key].push({
                    key: '',
                    index: this.tmp_pos_items[key].length,
                    curtain_show: false,
                    curtain_type: '',
                    curtain: '',
                    product_show: true,
                    product_id:product_id,
                    price: this.design.price,
                    product: product,
                    quantity: '',
                    subtotal: 0.00,
                    unit_label: '',
                    order_type: 'odd_order',
                    fold_num: null
                })
                this.$set(this.tmp_pos_items, `${key}`, this.tmp_pos_items[key])
                this.$forceUpdate()
            },
            del(key, index) {
                let item = this.tmp_pos_items[key][index];
                this.$http.delete('/clients/cart/' + this.index + '/remove?key=' + item.key + '&position=qita').then(res => {
                    this.initData(res);
                });
            },
            getData() {
                // let pos_items = {}
                // Object.entries(this.tmp_pos_items ? this.tmp_pos_items : {}).forEach(([key, val]) => {
                //     if (this.checked[key]) {
                //         pos_items[key] = []
                //         pos_items[key] = val.map(_ => {
                //             return Object.assign(
                //                 this.init_cart['pos_items'][key] && this.init_cart['pos_items'][key][_.index] ? this.init_cart['pos_items'][key][_.index] : {},
                //                 {
                //                     key: _.key,
                //                     product_id: _.product_id,
                //                     curtain_type: _.curtain_type,
                //                     fold_num: _.fold_num,
                //                     quantity: _.quantity
                //                 }
                //             )
                //         })
                //     }
                // })
                this.cart.pos_items = this.pos_items
                this.cart.positions = this.getPositionStr()
                return Object.assign(this.init_cart, this.cart)
            },
            addItem(key, index) {
                let item = this.tmp_pos_items[key][index];
                return this.$http.post('/clients/cart/' + this.index + '/add', {
                    key: item.key,
                    product_id: item.product_id,
                    quantity: item.quantity,
                    price:item.price,
                    order_type: 'odd_order',
                    position: 'qita',
                }).then(res => {
                    this.initData(res);
                });
            },
            update() {
                return this.$http.patch('/clients/cart/' + this.index, this.getData()).then(res => {
                    this.initData(res)
                });
            },
            updateFoldNum(key1, key2) {
                let value = this.tmp_pos_items[key1][key2]['fold_num']
                if (this.cart.fold_gap && !isNaN(this.cart.fold_gap) && value && !isNaN(value)) {
                    this.tmp_pos_items[key1][key2]['quantity'] = this.fix2(value * this.cart.fold_gap)
                }
                this.$forceUpdate()
                this.update()
            },
            onSubmit() {
            
                if(this.pos_items.qita.length==0){
                    this.$toast.fail('请至少填写一条货号')
                    return 
                }
                this.update().then(_ => {
                    this.is_show = false
                    this.$emit('hideCartInfoPopup2', false);
                    this.$router.replace('/cart?a=b&activeTab=fabric')
                })
            },
            getPositionStr() {
                let positions1 = ''
                Object.entries(this.pos_items ? this.pos_items : {}).forEach(([key, value]) => {
                    if (this.checked[key]) {
                        positions1 += key + ','
                    }
                })
                return positions1
            },
            onPickerClick(key, val) {
                this.showPickerList[`${key}`] = !!val
                this.$set(this.showPickerList, `${key}`, !!val)
                this.$forceUpdate()
            },
            onPosItemClick(key, index, type, val) {
               
                this.tmp_pos_items[`${key}`][index][type + '_show'] = !!val
                this.$forceUpdate()
            },
            goBack() {
                // if (this.action == 'create') {
                //     this.$http.delete("/clients/cart/" + this.init_cart.index).then(res => {
                //         this.is_show = false
                //     });
                // }

  
      Dialog.confirm({
        title: '提示',
        message: '确定要放弃本次编辑吗',
      })
        .then(() => {
         this.is_show = false
        this.$emit('hideCartInfoPopup2', false);
        })
        .catch(() => {
         
      
        });
               
            }
        }
    }
</script >

<style >
    .cart_form .calc_button {
        width: 88%;
        margin: 10px 6%;
    }

    .position .van-grid-item__content {
        padding: 8px;
    }

    .position .van-field {
        padding: 0;
    }
    html,
body {
  height: 100%
}
.van-overflow-hidden{
    overflow:auto!important;
}
</style >
