<template >
    <div class="land-body" >
       <img style="  margin: 0 auto;
    display: block;width: 100vw;" src="/user_background.jpg" />
    <div class="form">
        <van-field v-model="shopnum" type="number" placeholder="请输入商户代号" />
        <van-button style="    margin-top: 10px;" @click="gotoShop" :disabled="!shopnum" type="primary" block>进入店铺</van-button>
         <van-button style="    margin-top: 30px;
    float: right;"  url="/#/user" type="info" size="mini">返回个人中心</van-button>

    </div>

    </div >
</template >

<script >
    import {
    Dialog} from 'vant';
    export default {
        name: "Land",
        data() {
            return {
                shopnum:''
            };
        },
        methods: {
            gotoShop(){
              
                this.$http.get('/clients/shops/' + this.shopnum).then(res => {
                    if (!res || !res.id) {
                         this.$toast.clear();
                         Dialog.alert({
                            message: '没有代号为【'+this.shopnum+'】的店铺，重新试试吧',
                            theme: 'round-button',
                            }).then(() => {
                             this.shopnum ='';
                            });
                    }else{
                         this.$store.dispatch('StorageClear').then(()=>{
                       this.$store.commit('SET_TOKEN', '')
                         setTimeout(()=>{
                          this.$router.replace('/shop/' +this.shopnum)
                        
                      },0)
                    })
                   
                    }
                   
                })
            }
        }
    };
</script >
<style >
   .form{
           padding: 20px;
   }
</style >
