<template>
  <div class="outFrame">
    <van-popup closeable   round  bind:close="toggle" position="bottom"
     class="setting" style="" v-model="showDetail">
  <h4 class='sel-color'>{{ select_curtain_type.curtain_type_label}}·选择色块</h4>
        <div style="
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    overflow-y:auto;  max-height: 50vh;
     -webkit-overflow-scrolling: touch;">
<div  style="margin-right:5px;" class="large"  v-for="(item,index) in select_curtain_type.products" >
        <img  style="max-width:100%;border-radius:8px;border: 1px solid transparent;"   v-if="item.color_img"
                      :key="item.id"
                      :src="item.color_img"
                       v-bind:class="[{ sel: select_product.id === item.id },'rexButton ']"
                      @click="changeProduct(item)" >
                      <div style="font-size: 12px;">{{item.code}}</div>
                     </div> 
        </div>
    </van-popup>
    <div class="mainContent">
      <div id="showBox" >
        <img id="backImg" class="backgroundImage" :src='select_back.img' v-if="design && !showWall && design.type != 'soft_decoration'"/>
        <span v-if="design && showWall && design.type != 'soft_decoration'">
            <img id="backImg" class="backgroundImage" :src='back_img[0]' v-if="design && back_img[0]"/>
            <div v-if="design"
                 id="curtainDetails"
                 class="draggable"
                 style="width: 100%;"
            >
                <img v-for="(item,index) in back_img" style="width:100%" class="partImg" id="partImg" :src="item">
            </div>
        </span>
        <vue-draggable-resizable v-if="design && design.curtain_type_images && designStatus && design.type != 'soft_decoration'"
                                  id="curtainDetails"
                                  class="draggable"
                                  style="height: 100%;width: 100%;"
                                  axis="both"
                                  :prevent-deactivation="true"
                                  @click.self.prevent
                                  @activated="onActivated"
                                  @dragstop="onDragstop" :parent="false"
                                  :maximize="true"
                                  :w="w"
                                  :snap="true" :snap-tolerance="20"
                                  :handles="['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml']" >
            <img v-for="(item,index) in design_img" class="partImg" id="partImg" :src="item" :style="getStyle(index)">
        </vue-draggable-resizable>
        <img id="backImg" class="backgroundImage" :src='design_img[0]' v-if="design && design.type == 'soft_decoration' && design_img[0]"/>
        <div v-if="design && design.curtain_type_images && design.type == 'soft_decoration'"
        id="curtainDetails"
        class="draggable"
        style="width: 100%;"
        >
            <img v-for="(item,index) in design_img" class="partImg" id="partImg" :src="item" :style="getStyle(index)">
        </div>
      </div>
      <ul class="setting nohead">
        <li>
          <label>样式</label>
          <van-button type="default" size="normal" @click="selectBack = !selectBack" v-if="design && design.type != 'soft_decoration'">背景</van-button>
          <van-button type="default" size="normal" @click="selectCurtain = !selectCurtain">款式</van-button>
          <van-button type="default" size="normal" @click="designStatus = !designStatus" v-if="design && design.type != 'soft_decoration'">{{ designStatus ? '隐藏窗帘' : '显示窗帘' }}</van-button>
        </li>
        <li  style="  overflow-x: auto;overflow-y:hidden" id="parts" v-if="design && design.curtain_type_images && design.curtain_type_images.length">
          <label>部件</label>
          <div style="display:flex;  ">
        
           <van-button type="default"
                      size="small"
                       style="flex: 0 0 calc(25vw - 10px);"
                       class="btn-sss"
                      v-for="(item,index) in design.curtain_type_images"
                      v-bind:class="[{ sel: select_curtain_type.curtain_type === item.curtain_type },'rexButton']"
                      :key="index"
                      v-if="item.curtain_type_label"
                      @click="changeCurtainType(item)">
            {{ item.curtain_type_label }}
          </van-button>
          </div>
            <div style="
       display: flex;
      width: 100%;
">
   <div style=";margin-right:5px;    flex: 0 0 calc(25vw - 10px);"   class="large"   v-for="(item,index) in design.curtain_type_images">
              <img  style="width:100%;border-radius:8px; height: calc(25vw - 12px); border: 1px solid transparent;" 
                        v-bind:class="[{ sel: select_curtain_type.curtain_type === item.curtain_type },'rexButton']"
                       @click="toggle(item)"
                      :src="select_products[item.curtain_type].color_img"
                      class="rexButton  "
                      />
                   
                      
           <div style="font-size: 12px;">{{select_products[item.curtain_type].code}}</div>
   </div>
                      
                      </div>
        </li>
        <!-- <li id="colors" v-if="select_curtain_type && select_curtain_type.products && select_curtain_type.products.length">
          <label>颜色</label>
          <span @click="toggle()" style=" display: block;font-size: 12px;">
            <span style="color: #1e80ff;" v-if="mode!='detail'">切换到材质细节<van-icon name="eye-o" /></span>
            <span v-else>切换到正常模式<van-icon name="closed-eye" /></span>
          </span>
         
      
        </li> -->
        <li id="parts" v-if="design && showWall">
          <label>壁纸部件</label>
          <van-button type="default"
                      size="small"
                      v-for="(item,index) in back_curtain_types2"
                      v-bind:class="[{ sel: select_background_type.curtain_type === item.curtain_type },'rexButton']"
                      :key="index"
                      v-if="item.curtain_type_label && item.products && (item.products.length > 0)"
                      @click="changeBackGroudType(item)">
            {{ item.curtain_type_label }}
          </van-button>
        </li>
        <li id="colors" v-if="select_background_type && showWall && select_background_type.products && select_background_type.products.length">
          <label>壁纸颜色</label>
          <van-button type="default"
                      size="small"
                      v-for="(item,index) in select_background_type.products"
                      v-bind:class="[{ sel: select_background.id === item.id },'rexButton']"
                      v-if="item.color_img"
                      :key="item.id"
                      :icon="item.color_img"
                      @click="changeBackGroudImg(item)">
          </van-button>
        </li>
      </ul>
    </div>
    <van-tabbar route>
      <van-tabbar-item @click="goHistory()" icon="replay">返回</van-tabbar-item>
      <van-tabbar-item replace to="/cart" icon="shopping-cart">购物车</van-tabbar-item>
    </van-tabbar>
    <!-- 操作记录详细页 -->
    <van-popup v-model="selectBack" position="bottom" closeable :style="{ height: '60%' }">
      <div class="extMenu bottom" id="selectBack">
        <div class="dialogFrame">
          <div class="dialog-title">
            <h4>请选择一个背景</h4>
          </div>
          <div class="dialog-content">
            <ul class="nohead" id="backContentView">
              <li class="backItem" v-for="item in realshow_background" v-bind:class="{ sel: select_back.id === item.id }" @click="changeBack(item)">
                <img :src="item.img" fit="cover"/>
                <span>{{ item.title }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="selectCurtain" position="bottom" closeable :style="{ height: '60%' }">
      <div class="extMenu bottom" id="selectCurtain">
        <div class="dialogFrame">
          <div class="dialog-title">
            <h4>请选择一个款式</h4>
          </div>
          <div class="dialog-content">
            <ul class="nohead" id="curtainContentView">
              <li class="backItem" v-for="item in curtain_series" v-bind:class="{ sel: design.id === item.id }" @click="changeCurtainSeries(item)">
                <img :src="item.img" fit="cover"/>
                <span>{{ item.code }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
const SS = function (e) {
        e.preventDefault();
      }
export default {
  name   : "CustomDesign",
  data() {
    return {
      mode:'normal',
      showDetail:false,
      activeImg:'',
      w                     : 300,
      showWall              : false,
      designStatus          : true,
      design                : {id: this.$route.params.design_id, curtain_type_images: [], realshow_options: {}},
      selectBack            : false,
      selectCurtain         : false,
      resizableImg          : true,
      select_back           : {id: 0, img: '', title: ''},
      select_curtain_series : {id: 0, img: {thumb: ''}, code: ''},
      select_curtain_type   : {
        curtain_type      : "",
        curtain_type_label: "",
        products          : []
      },
      select_products:{},
      select_product        : {
        id       : 0,
        code     : "",
        color    : '',
        color_img: ''
      },
      select_background_type: {
        curtain_type      : "",
        curtain_type_label: "",
        products          : []
      },
      select_background     : {
        id       : 0,
        code     : "",
        color    : '',
        color_img: ''
      },
      realshow_background   : [],
      curtain_series        : [],
      design_data           : {},
      design_img            : [],
      back_img              : [],
      backgroud_data        : {},
      back_curtain_types    : [],
      back_curtain_types2   : [],
    }
  },
  mounted() {
    this.resetPartData()
    this.getRealshowBackground();
    this.getDesigns(this.$route.params.design_id);
   
  },

  methods: {
    toggle(item){
      this.changeCurtainType(item);
      this.showDetail = !this.showDetail;
    },
    getStyle(index) {
      return 'width:' + this.design.realshow_image_width + '%;z-index:' + (100 + index)+';left:'+(100-this.design.realshow_image_width)/2+'%'
    },
    getRealshowBackground() {
      this.$http.get('/clients/realshow_background?shop_id=' + this.$store.getters.shop_id).then(res => {
        this.realshow_background = res.items;
        if (this.realshow_background[0]) {
          this.changeBack(this.realshow_background[0])
        }
      })
    },
    getRealshowSeries() {
      this.$http.get('/clients/designs/shows/index?shop_id=' + this.$store.getters.shop_id + '&serie_id=' + this.design.serie_id).then(res => {
        this.curtain_series = res;
      })
    },
    getDesigns(design_id) {
      
      this.$http.get('/clients/designs/' + design_id).then(res => {
        this.design = res
        this.getRealshowSeries();
        if (this.design.curtain_type_images && this.design.curtain_type_images.length) {
          Object.keys(this.design.curtain_type_images).forEach(
              (_,) => {

                
                let item = this.design.curtain_type_images[_];
                var a = this.design.realshow_options[this.design.curtain_type_images[_]['curtain_type']]||{};
                if (item) {
                  if (this.design.realshow_options[this.design.curtain_type_images[_]['curtain_type']] && a['products'] && a['products'][0]) {
                    this.design_data[this.design.curtain_type_images[_]['curtain_type']] =a.default_product||  a['products'][0]
                   
                  } else {
                    this.design.curtain_type_images[_]['show_type'] = 'image'
                    this.design_data[this.design.curtain_type_images[_]['curtain_type']] = this.design.curtain_type_images[_]
                  }
                }
                 var _item = a.default_product||(a['products']? a['products'][0]:{});
                  this.select_products[item.curtain_type] = {
                     code:_item.code,
                     color_img:_item.color_img
                  }
              }
          );
          this.design_img = Object.keys(this.design_data).map(
                    _ => {
                        if (this.design_data[_]['show_type'] && this.design_data[_]['show_type'] == 'image') {
                            return this.design_data[_]['image']
                        } else {
                            return this.$baseURL
                                    + 'clients/designs/'
                                    + this.design.id
                                    + '/realshow?product_id=' + this.design_data[_].id
                                    + '&curtain_type='
                                    + _
                                    + '&api_token='
                                    + this.$store.getters.token
                        }
                    }
          );
          this.changeCurtainType(this.design.curtain_type_images[0])
        }
      })
    },
    changeBack(item) {
      if (item.curtainTypes && item.curtainTypes.length > 0) {
        this.showWall = true
        this.select_back = item
        this.back_curtain_types2 = item.curtainTypes
        Object.keys(item.curtainTypes).forEach(
            _ => {
              if (item.curtainTypes[_]['curtain_type'] && item.curtainTypes[_]['products'] && item.curtainTypes[_]['products'][0]) {
                this.backgroud_data[item.curtainTypes[_]['curtain_type']] = item.curtainTypes[_]['products'][0]
                this.back_curtain_types[item.curtainTypes[_]['curtain_type']] = item.curtainTypes[_]
              } else {
                item.curtainTypes[_]['show_type'] = 'image'
                this.backgroud_data[item.curtainTypes[_]['curtain_type']] = item.curtainTypes[_]
              }
            }
        );
        this.changeBackGroudType(item.curtainTypes[0])
      } else {
        this.showWall = false
        this.select_back = item
      }
    },
    changeBackGroudType(item) {
      if (this.back_curtain_types[item.curtain_type]) {
        this.select_background_type = this.back_curtain_types[item.curtain_type];
        console.log(this.select_background_type)
        if (this.select_background_type.products.length > 0) {
          this.changeBackGroudImg(this.select_background_type.products[0]);
        }
      } else {
        this.select_background_type = {
          curtain_type      : item.curtain_type,
          curtain_type_label: item.curtain_type_label,
          products          : []
        };
        this.select_background = {
          id       : 0,
          code     : "",
          color    : '',
          color_img: ''
        };
      }
    },
    changeBackGroudImg(item) {
        this.select_background = item;
        this.backgroud_data[this.select_background_type.curtain_type] = item;
        this.back_img = Object.keys(this.backgroud_data).map(
                  _ => {
                        if (this.backgroud_data[_]['show_type'] && this.backgroud_data[_]['show_type'] == 'image') {
                            return this.backgroud_data[_]['image']
                        } else {
                            return this.$baseURL
                                    + 'clients/designs/'
                                    + this.select_background_type.design_id
                                    + '/realshow?product_id=' + this.backgroud_data[_].id
                                    + '&curtain_type='
                                    + _
                                    + '&api_token='
                                    + this.$store.getters.token
                        }
                    }
        );
    },
    changeCurtainSeries(item) {
      this.select_curtain_series = item;
      this.getDesigns(item.id)
    },
    changeCurtainType(item) {
      if (this.design.realshow_options[item.curtain_type]) {
        this.select_curtain_type = this.design.realshow_options[item.curtain_type];
        if (this.design_data[this.select_curtain_type.curtain_type]) {
          this.changeProduct(this.design_data[this.select_curtain_type.curtain_type]);
        }
      } else {
        this.select_curtain_type = {
          curtain_type      : item.curtain_type,
          curtain_type_label: item.curtain_type_label,
          products          : []
        };
        this.select_product = {
          id       : 0,
          code     : "",
          color    : '',
          color_img: ''
        };
      }
    },
    changeProduct(item) {
    
      this.select_product = item;
      this.select_products ={...this.select_products,[this.select_curtain_type.curtain_type]:{color_img:item.color_img,code:item.code}}
      this.design_data[this.select_curtain_type.curtain_type] = item;
      this.showDetail = false
   
      this.design_img = Object.keys(this.design_data).map(
          _ => {
            if (this.design_data[_]['show_type'] && this.design_data[_]['show_type'] == 'image') {
              return this.design_data[_]['image']
            } else {
                return this.$baseURL
                  + 'clients/designs/'
                  + this.design.id
                  + '/realshow?product_id=' + this.design_data[_].id
                  + '&curtain_type='
                  + _
                  + '&api_token='
                  + this.$store.getters.token
            }
          }
      );
    },
    goHistory() {
      this.$router.go(-1)
    },
    onActivated() {
      document.removeEventListener('touchmove',SS , false);
      document.body.style.overflowY = 'hidden';
      document.addEventListener("touchmove",SS, {passive: false});
    },
    onDragstop() {
      document.body.style.overflowY = 'visible';
      document.removeEventListener("touchmove",SS, false);
    },
    resetPartData() {
      this.select_back = {id: 0, img: '', title: ''}
      this.select_curtain_series = {id: 0, img: ''}
      this.select_curtain_type = {
        curtain_type      : "",
        curtain_type_label: "",
        products          : []
      }
      this.select_product = {
        id       : 0,
        code     : "",
        color    : '',
        color_img: ''
      }
      this.select_background_type = {
        curtain_type      : "",
        curtain_type_label: "",
        products          : []
      }
      this.backgroud_data = {}
      this.select_product = {
        id       : 0,
        code     : "",
        color    : '',
        color_img: ''
      }
      this.realshow_background = []
      this.curtain_series = []
    }
  }
}
</script>

<style scoped>
.rexButton[color=green] {
  border: 1px solid #4b9647;
  background-color: #4b9647;
  color: #fff;
}

.rexButton[color=green]:hover {
  border-color: #73b970;
  background-color: #73b970;
}

.rexButton[color=green]:active {
  border-color: #4b9647;
  background-color: #cff0cd;
  color: #4b9647;
}

.outFrame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #eee;
}

.pageTitle {
  position: relative;
  height: 50px;
  background-color: #1d5095;
  color: #fff;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.bottomButtonBar {
  background: #fff;
  line-height: 50px;
  text-align: center;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.bottomButtonBar .rexButton {
  width: 80%;
}

.bottomButtonBar .rexButton .fa {
  font-size: 1.2em;
  opacity: .9;
  margin-right: .5em;
}

.mainContent {
  position: relative;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 50px);
     overflow: hidden;
  background: repeating-linear-gradient(90deg, rgba(255, 255, 255, .3), rgba(255, 255, 255, .3) 12px, transparent 0, transparent 24px);
  background-color: #ccc;
  display: flex;
  flex-direction: column;
}

#showBox {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #fff;
      flex: 1;
}


.backgroundImage {
  max-width: 100%;
  max-height: 556px;
}

#backImg {
  display: block;
  margin: auto;
  width: 100%;
  pointer-events: none;
}

#backImg2 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

/* 设置区域 */
.setting {
  margin-top:2rem;
}
.setting li {
  padding: 10px;
  margin: 3px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.setting label {
  display: inline-block;
  background: #1d5095;
  color: #fff;
  margin-left: -42px;
  padding: 2px 3px;
  position: relative;
  top: 1px;
  display: none;
}

#colors .rexButton > span {
  display: inline-block;
  height: 14px;
  width: 14px;
  line-height: 14px;
}

#colors .rexButton.default > span {
  background: #ddd;
}

#colors .rexButton.c1 > span {
  background: #9B9B9B;
}

#colors .rexButton.c2 > span {
  background: #c3b79d;
}

#colors .rexButton.c3 > span {
  background: #3894a2;
}

#colors .van-button--small {
  width: 2.5em;
  height: 2.5em;
  padding: 0.1em;
}

#colors .van-button__content {
  overflow: hidden;

}

#colors .van-button__icon {
  font-size: 1.1em;

}

#colors .van-button__icon .van-icon__image {
  width: 2.8em;
  height: 2.8em;
  object-fit: fill;
}

.setting .rexButton.sel {
  border-color: #f90!important;
  background: #ffeacb;
  color: red;
}

.setting .rexButton {
  margin: 5px 5px 0 0;
}

/* 背景可选 */
#backContentView {
  padding: 10px;
  text-align: center;
}

#backContentView li {
  display: inline-block;
  float: left;
  width: 50%;
}

#backContentView img {
  display: block;
  width: 90%;
  height: 8rem;
  min-width: 100px;
  max-width: 240px;
  border: 2px solid transparent;
  margin: 0 auto;
}

#backContentView span {
  display: block;
  padding-top: 5px;
}

#backContentView li.sel img {
  border-color: #f90;
}

#backContentView li.sel span {
  color: red;
}

/* 窗帘可选 */
#selectCurtain .dialogFrame {
  height: 70%;
}

#curtainContentView {
  padding: 10px;
  text-align: center;
}

#curtainContentView li {
  display: inline-block;
  float: left;
  width: 50%;
  /* height: calc(#{width}); */
}

#curtainContentView img {
  display: block;
  width: 90%;
  max-height: 10rem;
  min-width: 100px;
  max-width: 240px;
  border: 2px solid transparent;
  margin: 0 auto;
}

#curtainContentView span {
  display: block;
  padding-top: 5px;
}

#curtainContentView li.sel img {
  border-color: #f90;
}

#curtainContentView li.sel span {
  color: red;
}

/* 窗帘详情 */
#curtainDetails {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

#curtainDetails img {
  /* height: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

#curtainDetails #ctSource {
  position: static;
}
.opcatiy-5{
      opacity: .5;
          pointer-events: none;
}
.van-popup .large{
     width: calc(25vw - 12px);
    /* height: calc(25vw - 12px); */
}
.van-popup .large img{
        box-sizing: border-box;
    height: calc(25vw - 14px);
}
.van-popup .large:nth-child(4){
   margin-right: 0!important;
}
.normal{
  width:30px;height:30px;
}
.sel-color{
      margin: 0;
    padding: 10px;
    margin-top: 5px;
    padding-bottom: 0;
}
.btn-sss{
   margin-right: 5px!important;
}
.btn-sss:last-child{
  margin-right: 0!important;
}
.setting{
  overflow: hidden;
}
</style>
