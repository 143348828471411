<template >
    <div class="user-order-detail" >
        <van-swipe :autoplay="3000" v-if="images" >
            <van-swipe-item v-for="(image, index) in images" :key="index" >
                <img v-lazy="image" fit="contain" style="width:100%" />
            </van-swipe-item >
        </van-swipe >
        <div style="line-height:24px;text-align:center;padding:8px;" >
            {{ order.design_label }}
        </div >
        <van-cell-group >
            <van-row >
                <van-col span="8" >
                    <van-cell title="窗户尺寸" />
                </van-col >
                <van-col span="2" >
                </van-col >
                <van-col span="3" >
                    <van-cell :title="order.window_width" />
                </van-col >
                <van-col span="5" >
                    <van-cell title="X" />
                </van-col >
                <van-col span="3" >
                    <van-cell :title="order.window_height" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell title="褶倍" />
                </van-col >
                <van-col span="14" >
                    <van-cell :title="order.fold_times" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell title="褶距" />
                </van-col >
                <van-col span="14" >
                    <van-cell :title="order.fold_gap" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell title="购买方式" />
                </van-col >
                <van-col span="14" >
                    <van-cell :title="order.process_type_label" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell title="安装位置" />
                </van-col >
                <van-col span="14" >
                    <van-cell :title="order.install_location_label" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell title="帘头方向" />
                </van-col >
                <van-col span="14" >
                    <van-cell :title="order.strap_dir_label" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell title="打开方式" />
                </van-col >
                <van-col span="14" >
                    <van-cell :title="order.open_style_label" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell title="加工方式" />
                </van-col >
                <van-col span="14" >
                    <van-cell :title="order.install_craft_label" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell >
                        <div slot="title" >
                            客户<br />(门店可见)
                        </div >
                    </van-cell >
                </van-col >
                <van-col span="13" >
                    <van-cell :title="order.customer" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell >
                        <div slot="title" >
                            备注<br />(厂家备注)
                        </div >
                    </van-cell >
                </van-col >
                <van-col span="13" >
                    <van-cell :title="order.comment" />
                </van-col >
            </van-row >
            <van-row >
                <van-col span="8" >
                    <van-cell >
                        <div slot="title" >
                            图片
                        </div >
                    </van-cell >
                </van-col >
                <van-col span="13" >
                    <van-image
                            v-for="(index,image) in order.images"
                            :key="index"
                            width="10rem"
                            height="10rem"
                            fit="contain"
                            :src="image"
                    />
                </van-col >
            </van-row >
        </van-cell-group >

        <div class="" v-for="(group, index) in order.group" :key="index" >
            <van-cell-group v-for="(details, key) in group.items" :key="key" >
                <van-grid column-num="4" style="margin-top:10px;" >
                    <van-grid-item >
                        {{ positions[key] }}
                    </van-grid-item >
                    <van-grid-item text="货号" />
                    <van-grid-item text="用料" />
                    <van-grid-item text="褶数" />
                </van-grid >
                <van-grid v-for="(detail, key2) in details" :key="key2" column-num="4" >
                    <van-grid-item :text="detail.curtain_type_label" />
                    <van-grid-item :text="detail.product_code" />
                    <van-grid-item :text="detail.quantity" />
                    <van-grid-item :text="detail.fold_num" />
                </van-grid >
            </van-cell-group >
        </div >
        <van-button type="primary" block to="/user/order" style="z-index:999;position: fixed;bottom: 0;left: 0;" >返回</van-button >
        <!-- <Footer></Footer> -->
    </div >
</template >

<script >
    // import Footer from "@/components/Footer.vue";
    export default {
        name: "UserOrder",
        // components: { Footer },
        data() {
            return {
                order: [],
                images: [],
                positions: [],
            };
        },
        mounted() {
            this.getDetail();
            this.getClientsOptions();
        },
        methods: {
            getDetail() {
                this.$http.get("/clients/orders/" + this.$route.params.order_id).then(res => {
                    this.order = res;
                    this.images = [];
                    this.images.push(res.design_url);
                });
            },
            getClientsOptions() {
                this.$http.get("/clients/options").then(res => {
                    this.positions = res.positions;
                });
            },
        }
    };
</script >
<style >
    .detail-van {
        line-height: 24px;
        text-align: center;
        padding: 4px;
    }

    .user-order-detail .van-cell__title {
        text-align: center;
    }
</style >
