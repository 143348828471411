<template>
  <div   v-cloak v-if="design && design.id">
 
      <van-sticky v-if="(!is_show_bar && !design.no_toolbar) || $route.query.toolbar" style="float:left"   :z-index="2000" :offset-top="10">
         <van-icon @click="gotoback" class='back-icon' name="arrow-left" />
      </van-sticky>
    <van-sticky style="text-align: right;float: right;" v-if="design && design.audio" class="audio" :z-index="2000" :offset-top="10">
      <audio ref="design_audio" :src="design.audio" loop autoplay/>
      <van-icon v-bind:class="{music_icon: is_spin}" name="music" size="2.5rem" color="#07c160"
                @click="is_paused"/>
    </van-sticky>
    <van-swipe :autoplay="3000" v-if="design && design.imgs && design.imgs.larges">
      <van-swipe-item  v-for="(image, index) in design.imgs.larges" :key="index">
        <img @click="previewImgs(index)" v-lazy="image" fit="none" style="width:100%"/>
      </van-swipe-item>
    </van-swipe>
    <van-cell center v-show="design" style="flex:0.5">
      <template slot="title">
                <span style="display:block;font-size:1.2rem;font-weight: bold;">
                  <!-- ¥{{ design.price }} -->
                </span>
      </template>
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <!-- <van-button type="default" v-if="design['curtain_type_images'].length > 0" @click="tagTo('/design/' + $store.getters.shop_id + '/' + design.id)">换装</van-button>
      <van-button type="default" v-if="design['realshow_url']" @click="tagTo(design['realshow_url'])">试挂</van-button> -->
      <!-- <van-button type="default" v-if="design['3d_url']" @click="tagTo(design['3d_url'])">3D</van-button> -->
      <!-- <van-button type="default" size="small" v-if="design['video']" @click="tagTo(design['video'])" >视频</van-button > -->
    </van-cell>
    <van-cell center size="large" title-style="font-size:2rem" v-show="design" style="flex:0.5">
      <template slot="title">
        <span style="display:block;font-size:1.2rem;"><span v-if="design.brand_label">{{ design.brand_label }}-</span>{{ design.code }}</span>
      </template>
    </van-cell>
    <van-cell center title="款式选择：" title-class="width-left" value-class="series-image" v-if="design && design.series && design.series.items&&allow_same_design==1">
      <van-image v-for="item in design.series.items" :key="item.id" class="series-image-item" width="2.5rem" height="2.5rem" style="padding:3px;2.5rem!important" :src="item.img" @click="changeDesign(item.id)"/>
    </van-cell>
    <van-cell-group v-for="(goodss, index) in goodsList" :key="index" style="border-top:1px solid lightgrey;">
      <van-cell :title="index" title-class="width-left" value-class="cell-value" >
        <template slot="default">
            <div style="float:left;width:49%;">型号</div><div v-if="priceList[index]" class="price" style="float:left;width:49%;">单价：{{price_ratio(priceList[index]) }}元/米</div>
        </template>
      </van-cell>
      <van-cell v-for="(item, i) in goodss" :key="i" title-class="width-left" value-class="cell-value" :title="item.curtain_type_label" >
        <template slot="default">
            <div style="float:left;width:49%;">{{ showLabelValue(item) }}</div><div v-if="item.price&&!priceList[index]" class="price"  style="float:left;width:49%;">单价：{{item.price}}元/{{item.unit_label}}</div>
        </template>
      </van-cell>
    </van-cell-group>
    <div v-if="design && design.desc" v-html="design.desc" class="design-desc"></div>
   
    <div :class="noFixed?'bottom-text-3 postion-initial':'bottom-text-3 '" style="color:rgb(193, 194, 195);text-align:center;margin-bottom:30px;font-size:12px" v-show="show_suishou">
      <div class="display:block;"  style="margin-top:20px;">随手e裁技术支持</div>
      <!-- &nbsp;&nbsp;&nbsp;&nbsp;
      <span>17091921517</span> -->
    </div>
    <!-- 下单内容 -->
    <template >
      <CreateOrderOther   v-bind:show.sync="isShowOtherCartInfoPop" v-bind:cart_info="{}" v-bind:design="design"></CreateOrderOther>
      <CreateOrder   v-bind:show.sync="isShowCartInfoPop" v-bind:cart_info="{}" v-bind:design="design"></CreateOrder>
      <van-tabbar  route v-model="active">
        <van-tabbar-item @click="tagTo('/design/' + $store.getters.shop_id + '/' + design.id)" v-if="design['curtain_type_images'].length > 0&&design.custom!=1"  >
    <div  style="display: flex;
    align-items: center;    flex-direction: column;" >
          <img  style="    width: 20px;
    margin-bottom: 5px;" src="/a.jpg" />{{$store.state.menutexts["换装"]||'换装'}}
    </div>
        </van-tabbar-item>
          <van-tabbar-item v-if="design['realshow_url']"   >
      <div @click="tagTo(design['realshow_url'])"  style="    display: flex;
    align-items: center;    flex-direction: column;" >
          <img style="width:25px; margin-bottom: 8px" src="/b.jpg" />{{$store.state.menutexts["试挂"]||'试挂'}}
          </div>
        </van-tabbar-item>
          <van-tabbar-item v-if="design.custom==1"  @click="tagTo('/design/list?serie_id='+design.serie_id)">
           <img  style="width: 20px;
           margin: 0 auto;
    margin-bottom: 5px;;display:block" src="/c.png" />
          {{$store.state.menutexts["定制"]||'定制'}}</van-tabbar-item>
        <van-tabbar-item  v-if="(!is_show_bar && !design.no_toolbar) || $route.query.toolbar" @click="addCart" style="flex:1.5;color: #fff; background-color: #07c160; border: 1px solid #07c160;">
            <van-icon size="22" style="    text-align: center;
    display: block;
    margin-bottom: 2px;" color="#fff" name="cart-circle-o" />
          加入购物车</van-tabbar-item>
       
        <van-tabbar-item style="flex:1.5;" v-if="(!is_show_bar && !design.no_toolbar) || $route.query.toolbar" replace :to="'/cart?orderid='+$store.getters.confirmorderid" icon="shopping-cart"><van-badge class="badge-detail" :content="count">
  购物车
</van-badge></van-tabbar-item>
      </van-tabbar>
    </template>
  </div>
</template>

<script>
import CreateOrder from '@/components/CreateOrder.vue'
import CreateOrderOther from '@/components/CreateOrderOther.vue'
    import { ImagePreview } from 'vant';
export default {
  name: 'Goods',
  components: {CreateOrder,CreateOrderOther},
  data() {
    return {
     
      allow_same_design:0,
      noFixed:false,
      count:'',
      goodsList: {},
      design: {},
      realshow: false,
      is_show_bar: false,
      goods: {
        goods_id: 0,
        // 商品标题
        title: '',
        // 默认商品 sku 缩略图
        picture: ''
      },
      priceList:{},
      show_suishou: false,
      isShowCartInfoPop: false,
      isShowOtherCartInfoPop:false,
      is_spin: true,
      active: 0
    }
  },
  watch: {
    $route(to, from) {
      
      this.getList(to.params.design_id)
    }
  },

  mounted() {
    this.getIsShowBar()
     this.getCartNum();
    this.getList(this.$route.params.design_id)
    
  },
  methods: {
      // price_ratio(v){
      //         return (this.$store.getters.shop.config.price_ratio||10)*v;
      //      },
    previewImgs(index){
      ImagePreview({
    images: 
    this.design.imgs.larges
  ,
  startPosition: index,
});
        // ImagePreview([img], startPosition: 1);
    },
     setBottomText(){
      //  alert(document.documentElement.scrollHeight+'scrollHeight');
      //   alert(document.documentElement.clientHeight+'clientHeight');
      //  document.documentElement.clientHeight = document.documentElement.clientHeight||
               if(document.documentElement.scrollHeight>document.documentElement.clientHeight){
                       
                           this.noFixed = true
                  
                }
            },
    gotoback(){
     
      let url = '/series/' + this.$store.getters.shop_id ;
      let {activeKey,activeSerieType} = this.$route.query;
      activeKey =activeKey=='undefined'||activeKey==undefined?'0':activeKey;
      url = url+ ((activeKey!='0')?'/' +activeKey:'/0');
      url = url+ ((activeSerieType!='0')?'/' +activeSerieType:'');
      this.$router.replace(url)
    },
    getCartNum(){
      if(!this.$store.getters.token) {
        return 
      }
      //  http://lian3.suishouecai.com/api/clients/
       this.$http.get('/clients/carts/num').then(res => {
         if(res){
            localStorage.setItem('cartcount',res)
            this.count = res
         }else{
            this.count = ''
         }
         
      })
     
    },
    getIsShowBar() {
      this.$http.get('/clients/options/no_toolbar_shops').then(res => {
          for(let i=0; i<res.length;i++) {
              if (res[i] == this.$store.getters.shop_id) {
                  this.is_show_bar = true
              }
          }
      })

    },
    getList(design_id) {
      this.$http.get('/clients/designs/' + design_id).then(res => {
        this.design = res
        this.$nextTick(()=>{
          if( document.querySelector(".design-desc")){
document.querySelector(".design-desc").onclick =function(e){
            if(e.target.nodeName.toLowerCase()=='img'&&e.target.src){
               ImagePreview({
              images: [e.target.src],
               startPosition: 0,
            });
            }  
          }
          }
         
        })
        this.title = this.design.code ? this.design.code : '商品详情'
        document.title = this.design.code
        let goods = {}
        let priceList = {}
        res.items.forEach(item => {
          if (!goods[item.position_label]) {
            goods[item.position_label] = []
          }
          goods[item.position_label].push(item)
          priceList[item.position_label]=res['price_'+item.position]
        })
        this.priceList = priceList
        this.goodsList = goods
        var user =localStorage.getItem("user");
        user = JSON.parse(user||"{}");
        let shop_name =  sessionStorage.getItem('shopname')
        wx.miniProgram && wx.miniProgram.postMessage({ data: {shop_name:shop_name,title:this.title} })
        //显示技术支持
         setTimeout(()=>{
              this.setBottomText();
          },200)
           setTimeout(()=>{
              this.setBottomText();
          },1000)
            setTimeout(()=>{
                       this.setBottomText();
            },3000)
        this.show_suishou=true
          
                    let shop = sessionStorage.getItem("shop") || "{}";
                        if(shop!='{}'){
                        shop = JSON.parse(shop)||{config:{}};
                        this.allow_same_design = shop.config.allow_same_design;
                        if(shop.config.add_cart==1&&this.$store.getters.token){
                          setTimeout(this.addCart.bind(this),0)
                        
                         }
                        }
      })
    },
    playerPause() {
      if (!this.$refs.design_audio) {
        return false;
      }
      this.$refs.design_audio.pause();
      this.is_spin = false;
    },
    changeDesign(id) {
      let url = '/goods/' + this.$store.getters.shop_id + '/' + id+'?activeKey='+this.$route.query.activeKey
      if (this.$route.query.toolbar) {
        url += '&toolbar=' + this.$route.query.toolbar
      }
      this.$router.replace(url)
    },
    tagTo(url) {
      if (!url || url.length === 0 || url === undefined || url === 'undefined') {
        return false
      }
      if (url.startsWith('http')) {
        const fullPath = this.$route.fullPath;
        let _url = !fullPath.includes('skipAd')?(fullPath+'&skipAd=true'):fullPath
        this.$router.replace(_url)
        window.location.href = url
      } else {
        this.$router.push(url)
      }
    },
    showLabelValue(item) {
      let value = ''
      if (item.product_code) {
        value += item.product_code
      }
      if (item.strap_dir_label) {
        value += ' ' + item.strap_dir_label
      }
      if (item.open_style_label) {
        value += ' ' + item.open_style_label
      }
      if (item.install_craft_label) {
        value += ' ' + item.install_craft_label
      }
      if (item.process_type_label) {
        value += ' ' + item.process_type_label
      }
      if (item.status_label) {
        value += ' ' + item.status_label
      }
     
      return value
    },
    is_paused() {
      if (this.$refs.design_audio.paused) {
        this.$refs.design_audio.play();
        this.is_spin = true;
      } else {
        this.$refs.design_audio.pause();
        this.is_spin = false;
      }
    },
    addCart(res) {
      if(!this.$store.getters.token) {
        this.$dialog
            .confirm({
              title: '请先登录',
              confirmButtonText: '去登录'
            })
            .then(() => {
              this.$store.dispatch('Logout')
            })
        return false
      } else {
      this.playerPause()
     
      if(this.design.design_type&&this.design.design_type!=''){
         
          this.isShowOtherCartInfoPop = true
      }else{
         this.isShowCartInfoPop = true
      }
     
      this.active = ''
      }
    }
  }
}
</script>
<style>
.iframe {
  height: 100%;
  width: 100%;
  z-index: 1000000000;
  position: absolute;
  top: 0;
}

.cell-value {
  text-align: left;
}

.series-image {
  text-align: left;
}

.width-left {
  -webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
  width: 30%;
}

.design-desc {
  padding: 0.2rem 0.5rem;
}

.design-desc * {
  max-width: 100%;
}

.design-desc img {
  height:auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.van-sku-actions .van-button--danger {
  background: -webkit-linear-gradient(left, #07c160, #07c160);
  background: linear-gradient(to right, #07c160, #07c160);
}

.audio > .van-sticky {
  left: unset;
  right: 20px;
}

.audio > .van-sticky > .music_icon {
  -webkit-animation: run 6s linear 0s infinite;
}

@-webkit-keyframes run {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.series-image-item{
  width: 2.5rem!important;
}
.badge-detail .van-badge {
      right: -20px;
    top: -20px
}
.bottom-text-3{
      margin-bottom: 30px;
    font-size: 14px;
    position: absolute;
    text-align: center;
    bottom: 50px;
    width: 100%;
}
.postion-initial{
  position: initial!important;;
}
.back-icon{
  padding: 10px;
    background: #a5a5a5;
    margin-left: 10px;
    border-radius: 100%;
    color: #fff;
}
</style>
