<template>
  <div >
    <van-tabs class="tab-page" v-model="activeHeader" change="changeSeriesTab">
      <van-tab title="窗帘商城" name="chuanglian">
        <form action="/">
          <van-search v-model="keyword" placeholder="请输入搜索关键词" show-action @search="onSearch">
            <div slot="action" style="display: flex;justify-content: flex-end">
              <van-button @click.prevent="onSearch" size="small">搜&nbsp;&nbsp;索</van-button>
              <div @click.prevent="showFilterTagPop"  style="margin:0 5px;">
               
                <template v-for="tag in tag_list">
                   <van-tag @click="" size="small" color="#1989fa" plain round style="margin-left:2px;padding: 5px;" class="primary" v-if="change_tag_list && change_tag_list.slice(0,3).includes(tag.id)">{{tag.name}}</van-tag>

                </template>
                 <van-tag @click="" size="small" color="#1989fa" plain round style="margin-left:2px;padding: 5px;" class="primary" v-if="change_tag_list && change_tag_list.length>2">...</van-tag>

              </div>
              <van-button v-if="tag_list.length>0" style="margin-left: 8px;" @click.prevent="showFilterTagPop" size="small" icon="wap-nav"></van-button>
            </div>
          </van-search>
        </form>
        <div style="overflow-x:auto;width:100%;white-space: nowrap;padding: 5px 20px;    box-sizing: border-box;    box-shadow: 0px 1px 0px 0px #f5f5f5;">
      <div class="type-list">
       <div @click="setActiveSeriseType(key)" :class="activeSeriesType==key?'active':''" v-for="(item,key) in seriesTypes">
          {{item}}
       </div>
      </div>
      </div>
        <div style="display:flex;flex-direction:row;    margin-top: 1px;">
          <van-sidebar class="series-sidebar series-sidebar2" v-model="activeKey" @change="changeSeries">
            <van-sidebar-item v-for="item in series" :title="item.code" :key="item.id"/>
          </van-sidebar>
          <div style="flex: 1;">
          <div class="price-bar"  style="">
           <span> {{current_series?current_series.code:''}}</span>
            <span :class="priceSort?'active2':''" @click="sortPrice">价格<span :class="priceSort=='asc'?'up arrow active1':'up arrow'"></span><span :class="priceSort=='desc'?'arrow down active1':'arrow down'"></span></span></div>
          <van-list class="series-goods right-content" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDesignLoad" style="flex:min-content">
            <van-grid :border="false" center :column-num="$device.tablet() ? 4 : 2" :safe-area-inset-bottom="true">
              <van-grid-item class="goods-item" :class="[$device.tablet() ? 'goods-item-tablet' : 'goods-item-mobile']" v-for="(item,index) in list" :key="index"
                             :to="'/goods/' + $store.getters.shop_id + '/' + item.id + '?toolbar=1&activeKey='+activeKey+'&activeSerieType='+activeSeriesType" style="position:relative;">
                <van-image slot="icon" :src="item.img" lazy-load fit="contain" style="width:100%;height: auto;position:absolute;top:50%;transform:translateY(-50%);"/>
                <div slot="text" style="text-align:left;margin-left:3%;width: 90%;">
                  <span style="display:block;font-size:1.1rem">{{ item.code }}</span>
                  <span style="display:block;font-size:1.1rem;color: red" class="price">
                    <template v-if="item.price_wailian">
                    ¥ {{ price_ratio(item.price_wailian) }}
                    </template>
                    <template v-else>
                    <br/>
                    </template>
                  </span>
                  <div style="display: flex;justify-content: flex-start">
                    <!--                    <van-button v-if="item.tags_label && item.tags_label.length" v-for="tag in item.tags_label" round size="mini" type="info">{{ tag }}</van-button>-->
                    <van-tag style="display: flex;margin-right: 5px;" v-if="item.tags_label && item.tags_label.length" v-for="(tag,index) in item.tags_label" :key="index" plain round>{{ tag }}</van-tag>
                    <van-tag style="display: flex;margin-right: 5px;" v-else plain round><br/></van-tag>
                  </div>
                  <!-- <span v-if="item.price" style="display:block;font-size:1.1rem;font-weight: bold;">{{ '¥' + item.price + '元/米' }}</span> -->
                </div>
              </van-grid-item>
            </van-grid>
          </van-list>
          </div>
        </div>
      </van-tab>
      <van-tab title="面料商城" name="mianliaoshangcheng" v-if="$store.getters.shop.config.enable_product_store">
        <form action="/">
          <van-search v-model="product_keyword" placeholder="请输入搜索关键词" show-action @search="onSearchProducts">
            <div slot="action" @click="onSearchProducts" style="padding:0px 8px;">搜&nbsp;&nbsp;索</div>
          </van-search>
        </form>
         <div style="overflow-x:auto;width:100%;white-space: nowrap;padding: 5px 20px;    box-sizing: border-box;">
      <div class="type-list">
       <div @click="setActiveSeriseType(key)" :class="activeSeriesType==key?'active':''" v-for="(item,key) in seriesTypes">
          {{item}}
       </div>
      </div>
      </div>
        <div style="display:flex;flex-direction:row">
          <van-sidebar class="series-sidebar series-sidebar2 series-sidebar3" v-model="product_activeKey" @change="changeCategories">
            <van-sidebar-item v-for="item in categories" :title="item.name" :key="item.id"/>
          </van-sidebar>
          <van-list class="series-goods right-content"  :finished="product_finished" finished-text="没有更多了" @load="getProducts" style="flex:min-content">
            <van-grid :border="false" center :column-num="$device.tablet() ? 4 : 2" :safe-area-inset-bottom="true">
              <van-grid-item class="goods-item"
                             :class="[$device.tablet() ? 'goods-item-tablet' : 'goods-item-mobile']"
                             v-for="item in product_list"
                             :key="item.id"
                             @click="redirectProduct(item)"
                             style="position:relative;"
              >
                <van-image slot="icon" :src="item.attach" lazy-load fit="contain" style="width:100%;height: auto;position:absolute;top:50%;transform:translateY(-50%);"/>
                <div slot="text" style="text-align:left;margin-left:3%;width: 90%;">
                  <span style="display:block;font-size:1.1rem">{{ item.code }}</span>
                 
                </div>
              </van-grid-item>
            </van-grid>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup
        v-model="filter_show"
        closeable
        position="bottom"
        :style="{ height: '30%' }"
    >
      <div style="display: flex;justify-content: flex-start;margin: 20px;flex-wrap: wrap;" v-if="tag_list && tag_list.length">
        <template v-for="tag in tag_list">
          <van-button v-if="change_tag_list && change_tag_list.includes(tag.id)" style="display: flex;margin-right: 10px;margin-bottom:10px" color="#1989fa" plain round size="small"
                      @click.prevent="changeTag(tag.id)">{{ tag.name }}
          </van-button>
          <van-button v-else style="display: flex;margin-right: 10px;margin-bottom:10px" plain round size="small" @click.prevent="changeTag(tag.id)">{{ tag.name }}</van-button>
        </template>
      </div>
    </van-popup>
    <Footer :count="count"></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Series',
  components: {Footer},
  data() {
    return {
      priceSort:"",
      firstLoad:true,
      count:0,
      activeSeriesType:"",
      seriesTypes:[],
      activeHeader: 'chuanglian',
      loading: true,
      finished: false,
      refreshing: false,
      is_search: false,
      filter_show: false,
      tag_list: [],
      change_tag_list: [],
      page: 1,
      keyword: '',
      series: '',
      shopId: 0,
      activeKey: 0,
      current_series: {},
      list: [],
      categories: '',
      current_categories: {},
      product_loading: false,
      product_finished: false,
      product_refreshing: false,
      product_is_search: false,
      product_page: 1,
      product_keyword: '',
      product_activeKey: 0,
      product_list: [],
      menutexts:{}
    }
  },
 
  mounted() {
  
 
           this.$setMenuText(()=>{
               this.shopId = this.$store.getters.shop_id
                this.getSeriesType().then(()=>{
                  this.getTagList()
  
   
      this.count =  localStorage.getItem('cartcount')
    this.getCategories();
    if(this.$route.query.tag){
   this.change_tag_list = [parseFloat(this.$route.query.tag)];
    }
   
   
    // this.$route.params.series_id = this.$route.params.series_id||'';
    // if(this.$route.query.serie_type){
    //   this.activeSeriesType = this.$route.query.serie_type;
    // }
     if (this.$route.params.order_type == 'mianliaoshangcheng') {
      this.activeHeader = 'mianliaoshangcheng'
       this.activeSeriesType = 'mianliaoshangcheng'
        this.product_loading = false
    }else{
      const order_type=  this.$route.params.order_type
      this.activeSeriesType = order_type&&order_type!='undefined'? this.$route.params.order_type:'';
    }
    if (this.$route.params.order_type != 'mianliaoshangcheng') {
      this.loading = false
      this.design_loading = false
        this.product_loading = false
     this.getSeries()
      
      
    }else{
       this.loading = false
      this.design_loading = false
        this.product_loading = false
     this.getSeries();
    //  this.changeTag(this.$route.query.tag)
    }
                })
            });
   
    
  
  },
  methods: {
    
    setActiveSeriseType(key){
     this.priceSort ='';
      if(key =='mianliaoshangcheng'){
        
        this.activeHeader = key
          this.activeSeriesType = key;
           let url = '/series/' + this.$store.getters.shop_id
          url = url+ '/0/'+key;
     this.$router.replace(url)
      }else{
          this.activeHeader = 'chuanglian'
     this.activeSeriesType = key;
     this.series =[];
      this.activeKey = 0;
      this.current_series ={}
      this.page =1;
      this.$route.params.series_id=0;
         let url = '/series/' + this.$store.getters.shop_id
          url = url+ '/' + this.activeKey+'/'+key;
     this.$router.replace(url)
     this.getSeries(()=>  this.onSearch());
   

       
      }
     
      
    },
     getSeriesType(){
     let that = this
    return   this.$http.get('clients/serieTypes?shop_id=' + this.shopId).then(res => {
        res = res ? res : [];
         if(this.$store.getters.shop.config.enable_product_store){
          // delete res[""]
          res["mianliaoshangcheng"] = that.$store.state.menutexts['面料商城']||'面料商城'
        }
      
        that.seriesTypes = res;
       
      })
  },
    getTagList() {
      this.$http.get('/clients/tags?shop_id=' + this.shopId).then(res => {
        res = res ? res : [];
        res =  res.filter((item)=>{
          if(item.url&&item.url.includes("tag")){
             return true;
          }else{
            return false;
          }
        })
        this.tag_list = res;
      })
    },
    changeTag(tag) {
      if(this.firstLoad){
        return 
      }
      if (this.change_tag_list.includes(tag)) {
        // 去除
        this.change_tag_list.splice(this.change_tag_list.findIndex(_ => _ === tag), 1);
      } else {
        this.change_tag_list.push(tag);
      }
      this.page = 1;
      this.getDesign();
    },
    showFilterTagPop() {
      this.firstLoad = false;
      this.filter_show = true;
    },
    getSeries(callback) {
      let url =  '/clients/series?shop_id=' + this.shopId;
     if(this.activeSeriesType&&this.activeSeriesType!=undefined&&this.activeSeriesType!='undefined'){
      url  = url +'&serie_type='+this.activeSeriesType
     }else{
      let activeSeriesType = Object.keys(this.seriesTypes||{})[0]
      this.activeSeriesType = activeSeriesType
        url  = url +'&serie_type='+activeSeriesType
     }
      this.$http.get(url).then(res => {
        res = res ? res : []
        res.unshift({
          id: 0,
          code: '全部'
        })
        // res = res.sort((a,b)=>a.id-b.id)
        this.series = res
        let _this = this
          let {series_id} = this.$route.params;
          let from_index = this.$route.query.fromIndex
        if (series_id&&series_id!='0') {
        
         from_index && (this.activeKey = this.series.findIndex( (_)=> {
            return _.id === parseInt(series_id)
          }))
//           if(!this.series[series_id]){
           
//           }else{
// this.activeKey = this.$route.params.series_id;
//           }
          
          // 
          this.product_loading = false;
          if (this.activeKey === -1||!from_index) {
            this.activeKey = series_id;
            this.series =this.series||[]
               this.current_series ={id:this.series[series_id].id}
          }else{
        
          
          
          }
        
        }else{
       this.getDesign()
          //  this.current_series ={id:this.series[0].id}
        }
        callback &&callback();
        // this.changeSeries()
      })
    },
    sortPrice(){
       var sort=["asc","desc",""];
       let index = sort.indexOf(this.priceSort);
       this.priceSort = sort[(index+1)%3];
         this.page = 1
          this.finished = false
         this.list = [];
          this.loading = false
           this.getDesign()
    },
    changeSeries(e) {
      this.priceSort ='';
      this.current_series = this.series[this.activeKey]
      this.page = 1
      this.list = []
      this.finished = false
      this.is_search = false
      this.loading = false
      this.design_loading = false
      var serie_type ='';
      // if( window.location.hash.includes("serie_type=")){
      // serie_type =  window.location.hash.split("serie_type=")[1].split("&")[0]
      // }
     
      if (this.current_series) {
        let url = '/series/' + this.$store.getters.shop_id;
         url  = url+ '/' + this.activeKey+'/'+this.activeSeriesType
        // if(this.activeKey>0){
        
        // }
         this.$router.replace(url+'?serie_type='+serie_type)
        this.getDesign()
      }
    },
    onSearch() {
      this.page = 1
      this.list = [];
      this.priceSort ='';
      // this.activeKey = ''
      this.finished = false
      this.is_search = true
      this.getDesign()
    },
    getDesignLoad(){
      this.page>1&&this.getDesign();
    },
    getDesign(e) {
    
      if (this.design_loading) {
        return true;
      }
      this.design_loading = true
      // setTimeout(() => {
      let _this = this
      // let url
      // if (this.is_search) {
      //   url = '/clients/designs?shop_id=' + this.shopId + '&code=' + this.keyword + '&page=' + this.page
      // } else if (this.current_series && this.current_series.id && this.current_series.id != undefined) {
      //   url = '/clients/designs?shop_id=' + this.shopId + '&serie_id=' + this.current_series.id + '&page=' + this.page
      // } else {
      //   url = '/clients/designs?shop_id=' + this.shopId + '&page=' + this.page
      // }
      this.$http.get('/clients/designs', {
        params: {
          page: _this.page,
          shop_id: _this.shopId,
          // code: _this.is_search ? _this.keyword : '',
             code:  _this.keyword,
             price_sort:this.priceSort,
          serie_type:this.activeSeriesType,
          serie_id: (_this.current_series && _this.current_series.id && _this.current_series.id != undefined) ? _this.current_series.id : '',
          tags: _this.change_tag_list.join(',')
        }
      }).then(res => {
        if (res.items.length === 0) {
          _this.finished = true
        } else {
          res.items = res.items||[];
         res.items = res.items.map((item)=>{
            return {
              id:item.id,
               price_wailian:item.price_wailian,
               code:item.code,
               img:item.img,
               tags_label:item.tags_label
            }
          })
          if (parseInt(res.page) === 1) {
            _this.list = res.items;
          } else {
            _this.list = _this.list.concat(res.items);
          }
        }
        if (parseInt(res.pages) <= _this.page) {
          _this.finished = true
        }
        _this.page = parseInt(res.page)+1
        _this.loading = false
        _this.design_loading = false
        this.filter_show = false
      }).catch(() => {
        _this.loading = false
        _this.design_loading = false
      })
      // }, 500);
    },
    redirectProduct(item) {
      localStorage.setItem('product', JSON.stringify(item))
      this.$router.replace('/products/' + this.$store.getters.shop_id + '/' + item.id)
    },
    getCategories() {
      this.$http.get('/clients/categories?page=1&shop_id=' + this.shopId).then(res => {
        res = res ? res : []
        res.unshift({
          id: 0,
          name: '全部'
        })
        this.categories = res
        let _this = this
        if (this.$route.params.series_id) {
          this.product_activeKey = this.categories.findIndex(function (_) {
            return _.id === parseInt(_this.$route.params.series_id)
          })
          if (this.product_activeKey === -1) {
            this.product_activeKey = 0
          }
        }
        this.getProducts()
      })
    },
    changeCategories() {
      this.current_categories = this.categories[this.product_activeKey]
      this.product_page = 1
      this.product_list = []
      this.product_finished = false
      this.product_is_search = false
      this.product_loading = false
      if (this.current_categories) {
        // document.title = this.current_series.code;
        this.getProducts()
      }
    },
    onSearchProducts(keyword) {
      this.product_page = 1
      this.product_list = []
      // this.product_activeKey = ''
      this.product_finished = false
      this.product_is_search = true
      this.getProducts()
    },
    changeSeriesTab() {

    },
    getProducts(e) {
     
      if (this.product_loading) {
        return true;
      }
      this.product_loading = true
      // setTimeout(() => {
      let _this = this
      let url
      if (this.product_is_search) {
        url = '/clients/products?shop_id=' + this.shopId + '&code=' + this.product_keyword + '&page=' + this.product_page
      } else if (this.current_categories && this.current_categories.id && this.current_categories.id != undefined) {
        url = '/clients/products?shop_id=' + this.shopId + '&category_id=' + this.current_categories.id + '&page=' + this.product_page+ '&code=' + this.product_keyword 
      } else {
        url = '/clients/products?shop_id=' + this.shopId + '&page=' + this.product_page+ '&code=' + this.product_keyword 
      }
      this.$http.get(url).then(res => {
        if (res.items.length === 0) {
          _this.product_finished = true
        } else {
          if (parseInt(res.page) === 1) {
            _this.product_list = res.items;
          } else {
            _this.product_list = _this.product_list.concat(res.items)
          }
        }
        console.log(_this.product_list)
        if (res.pages <= _this.product_page) {
          _this.product_finished = true
        }
        _this.product_page++
        _this.product_loading = false
      }).catch(() => {
        _this.product_finished = true
        _this.product_loading = false
      })
      // this.product_loading = false
      // }, 500);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.changeSeries()

      this.product_finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.product_loading = true
      this.changeCategories()
    }
  }
}
</script>
<style>

.series-sidebar {
  flex: none;
  min-height: 100vh;
  background-color: #fafafa;
}

.goods-item {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0 0.5vw;
}

.goods .van-image__img {
  width: unset;
}

.goods-item .van-grid-item__content {
  padding: 1% 0;
}

.goods-item-tablet .van-grid-item__icon-wrapper {
  width: calc(100vw / 4 - 1vw - 21.25px);
  height: calc(100vw / 4 - 1vw - 21.25px);
  background-color: #e2e2e2;
  overflow: hidden;
}

.van-image {
  overflow: hidden;
}

.goods-item-mobile .van-grid-item__icon-wrapper {
  width: calc(100vw / 2 - 1vw - 42.5px);
  height: calc(100vw / 2 - 1vw - 42.5px);
  background-color: #e2e2e2;
  overflow: hidden;
}

.series-sidebar,
.van-sidebar-item--select,
.van-sidebar-item--select:active {
  border-color: #07c160;
}
.right-content{
  max-height: calc(100vh - 142px);
  overflow-y:auto ;
  width: 100%;
}
.tab-page {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 50px);
}
.series-sidebar2{
    overflow: auto;
     height: calc(100vh - 136px);
    min-height: initial!important;
}
.series-sidebar3{
    max-height: calc(100vh - 106px)!important;
}
.type-list{
  display: flex;
  /* flex-wrap: wrap; */
    /* padding: 5px 20px; */
}
 .type-list div{
  
        text-align: center;
    /* margin-bottom: 5px; */
    margin-right: 10px;
    padding: 0 5px;
    color: #777;
        padding-bottom: 3px;
    /* transition: all .3s ease; */
  }
.type-list div.active{
      border-bottom: 3px solid #07c160;
          color: #333;
}
.van-sidebar-item{
  padding: 15px 12px!important;
}
.tab-page .van-tabs__wrap{
  height: 0;
}
 .noauth-price .price{
           opacity: 0;
    }
    .price-bar{
      text-align: right;position:relative;    padding: 8px 20px 8px;
      font-size: 14px;
      box-shadow: rgb(245 245 245) 0px 1px 0px 0px;
      display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    }
    .arrow{
     width: 0;
    border: 4px solid transparent;
    position: absolute;
    right: 5px;
         top: 10px;
    position: absolute;
    }
    .up{
             top: 8px;
           border-bottom: 5px solid #c8c9cc; 
      
    }
    .active2{
      color:#07c160;
    }
      .arrow.up.active1{
           
           border-bottom-color:#07c160; 
      
    }
      .arrow.down.active1{
           border-top-color:#07c160; 
    }
    .down{
       border-top: 5px solid #c8c9cc;
      top: 20px;
   
    }
</style>
