<!-- @format -->

<template>
  <div>
      <van-popup
                      position="bottom"
                         :style="{ height: '60%',overflow:'hidden' }"
                     v-model="productModalshow"
                    >
                      <van-search
                        v-model="keyWord"
                        placeholder="请输入搜索关键词"
                          @clear="onCancel"
                        @change="onSearch"
                      />
                    
                      <van-list
                      offset="10"
                      style="display:flex;flex-wrap:wrap;height:calc(100% - 98px);overflow-y: auto;    padding: 0 10px;"
                       v-model:loading="loading"
                      :finished="finished"
                      :finished-text="Productlist.length>0?'没有更多了':'没有数据'"
                      @load="onLoad"
                >
           <div @click="setActiveProduct(index)" :class="activeProductIndex==index?'img-item active':'img-item'" v-for="(item,index) in  Productlist">
                          <img  v-if="item._text.attach&&item._text.attach!=null" :src="'/storage/'+item._text.attach"/>
                            <img  v-else />
                          <div style="text-align:center;max-height: 42px;overflow: hidden;">{{item.text}}</div>
                     </div> 
    </van-list>
                   
                   
                 
                       <div style="display:flex;">
                     <van-button :disabled="activeProductIndex==-1" style="flex:1"  @click="setPosItem2" type="primary" size="normal">确定</van-button>
                      <van-button style="flex:1" plain @click="productModalshow=false;activeProductIndex=-1" type="default" size="normal">取消</van-button>
                       </div>
                    
              </van-popup>
    <van-popup
      v-model="show"
      overlay-class="mask"
      
      :overlay="true"
       :close-on-click-overlay="clickOverlay"
      get-container="body"
         class="create-order"
      position="bottom"
      :style="{ height: '90%' ,    overflow: 'hidden'}"
      @opened="init"
    >
      <van-row style="margin-top: 10px">
        <van-col style="" span="7">
          <van-image style="width: 100%; margin: 3px ;   border-radius: 5px;margin-left: 10px;" :src="design.img||cart_info.design_url" />
        </van-col>
        <van-col span="15" style="padding: 5px 0 5px 15px">
          <div v-if="!cart_info.total_price_label" style="color: #9e9e9e;    font-size: 14px;">外帘{{cart_info.total_price_label|| (design.price_wailian?('¥ '+design.price_wailian):'')}}</div>
          <div style="color: #777;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;">款式：{{ design.code ||cart_info.design_code}}</div>
          <div style="display: flex; justify-content: flex-start">
            <van-tag
              :key="tag"
              style="display: flex;"
              v-if="design.tags_label && design.tags_label.length"
              v-for="tag in design.tags_label"
             
              >{{ tag }}</van-tag
            >
          </div>
        </van-col>
        <van-col class="close-wrapper" span="2">
          <van-icon name="cross" class="close-icon" @click="goBack" />
        </van-col>
      </van-row>
      <van-form
        class="cart_form"
        style="margin-bottom: 50px; padding-bottom: 30px"
      >
      <van-tabs class="customer-tab">

         
        <!-- <van-cell-group>
          <van-cell title="图片上传">
            <template #default>
              <van-uploader v-model="imgList" result-type="file" :after-read="afterRead" :max-count="1"/>
            </template>
          </van-cell>
        </van-cell-group> -->
        <!-- 计算用料按钮 -->
        <!-- <van-button block class="calc_button" @click="calc" type="default">计算用料</van-button> -->

        <!-- 辅料 -->
         <van-tab  title="下单信息">
        <template v-if="init_cart">
          <div class="positions">
          <template v-for="item in options.positions">
            <div
              class="position"
              v-if="
                tmp_pos_items[item['key']] &&
                tmp_pos_items[item['key']].length > 0
              "
              :key="item['key']"
            >
              <template>
                <div class="header">
                  <div style="display:flex;position:relative;height: 30px;width:100%">
                  <div class="pos-name"> {{ item["text"] }}</div>
                 
                    <div v-if="design.whole_price!=null&&cart['pos_quantity'][item['key']]" style="    flex: 1;
    margin-left: 66px;
    margin-top: 14px;">用料：{{ cart['pos_quantity'][item['key']]||'--' }}米</div>
                  <div class="check-pos">
                  <van-checkbox
                    v-model="checked[item['key']]"
                    @click="updateCheck(item['key'])"
                    shape="square"
                    icon-size="16px"
                    style="color: #646566; font-size: 12px"
                  >
                   
                  </van-checkbox>
                  <div v-if="cart['pos_price'][item['key']]" style="color:#e73333">{{ price_ratio2(cart["pos_price"][item["key"]]) }}元/米</div>
                   </div>
                  </div>
                  <div></div>
                  <!-- <div  v-if="checked[item['key']]" style="font-size: 12px">
                    小记： {{ cart["pos_subtotal_label"][item["key"]] }}
                  </div> -->
                </div>
              </template>
              <div v-if="checked[item['key']]" class="border-color">
              <div >
              <div v-if="(item['key']=='liantou'||item['key']=='wailian'||item['key']=='shalian')&&(order_show_item.includes(item['key']+'_width')||order_show_item.includes(item['key']+'_height'))" class="display-flex" >
                <div style="      flex: 0 0 70px;" class="label">尺寸（米）</div>
               <div style="display:flex;align-items: center;">
              
                 <van-field type="number"  @input="inpuchange2" v-if="order_show_item.includes(item['key']+'_width')" v-model="cart.pos_width[item['key']]"  placeholder="宽" /><div v-if="order_show_item.includes(item['key']+'_height')&&order_show_item.includes(item['key']+'_width')" style="margin:0 10px">x</div><van-field type="number"  v-if="order_show_item.includes(item['key']+'_height')" @input="inpuchange2"
                 v-model="cart.pos_height[item['key']]"  placeholder="高" />
               </div> </div>
              <div v-if="item['key']=='liantou'" class="section">
                 <div  class="label">
                  方向
                </div>
                 <div class="aaa">
                   <van-tag
                :key="$item.key"
                size="medium"
              
                @click="setPosActive(item['key'],'pos_strap_dir', $item)"
                v-for="($item, index) in options.strap_dirs"
                v-if="item.text"
                
                :class="
                  cart.pos_strap_dir[item['key']] == $item.key
                    ? 'aactive'
                    : ''
                "
                tag-padding="10"
                >{{ $item.text }}</van-tag
              >
                 </div>
              </div>
              <!-- &&order_show_item.includes('corner') -->
               <div v-if="item['key']=='liantou'" class="display-flex">
                <div style="    flex: 0 0 70px;" class="label">转角</div>
                   <div style="display:flex;align-items: center;">
                <van-field type="number"  @input="inpuchange2" v-model="cart.corner_left"  placeholder="左" /><div style="margin:0 10px">+</div><van-field type="number" @input="inpuchange2"   v-model="cart.corner_right"  placeholder="右" />
                   </div>
              </div>
               <!-- <div style="margin-top:20px" v-if='item["key"]!="wailian"&&item["key"]!="shalian"' class="display-flex">
                <div style="    flex: 0 0 100%;margin-bottom: 10px;" class="label">褶距(米)</div>
                <input  v-model="cart.pos_fold_gap[item['key']]"  placeholder="褶距" />
              </div> -->
              <!-- &&order_show_item.includes('open_style') -->
              <div  v-if="(item.key=='wailian'||item.key=='shalian')" class="section">
                <div  class="label">
                 打开方式
                </div>
              
                 <div class="aaa">
                <van-tag
                :key="$item.key"
                size="medium"
              
                @click="setPosActive(item['key'],'process_types', $item)"
                v-for="($item, index) in options.open_styles"
                v-if="item.text"
             
                :class="
                  cart.pos_open_style[item['key']] == $item.key
                    ? 'aactive'
                    : ''
                "
                tag-padding="10"
                >{{ $item.text }}</van-tag
              >
              </div>
              <!-- &&order_show_item.includes('process_method') -->
                <!--  -->
              </div>
               <div  v-if="(item.key=='wailian'||item.key=='shalian')&&order_show_item.includes(item.key+'_dingxing')" class="section">
                <div class="label">
                  是否定型
                </div>
                <div class="aaa">
                <van-tag
                :key="$item.key"
                size="medium"
                
                @click="setPosActive(item['key'],'dingxing', $item)"
                v-for="($item, index) in options.dingxing"
                v-if="item.text"
               
                :class="
                  cart['dingxing_'+item['key']] == $item.key
                    ? 'aactive'
                    : '#333'
                "
                tag-padding="10"
                >{{ $item.text }}</van-tag>
               </div>
                 </div>
               <div  v-if="(item.key=='wailian'||item.key=='shalian')" class="section">
                <div class="label">
                  加工方式
                </div>
                <div class="aaa">
                <van-tag
                :key="$item.key"
                size="medium"
                
                @click="setPosActive(item['key'],'install_crafts', $item)"
                v-for="($item, index) in options.install_crafts"
                v-if="item.text"
               
                :class="
                  cart.pos_craft[item['key']] == $item.key
                    ? 'aactive'
                    : '#333'
                "
                tag-padding="10"
                >{{ $item.text }}</van-tag
              >
               </div>
                <!--  -->
              </div>
              <div  v-if="postion_extend_check(item['key']).length>0&&userType&&order_show_item.includes('position_fast_comment')" class="section m-b-10">
             <div class="label">快速备注</div>
             <div class="aaa">
              <van-checkbox-group
                v-if="postion_extend_check(item['key']).length>0"
                direction="horizontal"
                v-model="cart.pos_extend_check[item['key']]"
                 @change="change2(cart.pos_extend_check[item['key']],item['key'])"
              
              >
                <van-checkbox
                
                  :key="item.text.value"
                  v-for="item in postion_extend_check(item['key'])"
                  :name="item.text.value"
                  >{{ item.text.label }}</van-checkbox
                >
              </van-checkbox-group>
               </div>
                 </div>
                   </div>
              <van-grid style=" margin-top: 10px;" v-if="checked[item['key']]" column-num="4">
                <van-grid-item :text="item['text']" />
                <van-grid-item text="货号" />
                <van-grid-item text="单价" />
                <van-grid-item :text="'用料'" />
              </van-grid>
          
              <van-grid
                v-if="checked[item['key']]"
                column-num="4"
                v-for="(pos, index) in tmp_pos_items[item['key']]"
                ref="pos"
                :key="index"
              >
            
                <van-grid-item>
                  <template>
                    <van-field
                      readonly
                      clickable
                      input-align="center"
                      v-model="tmp_pos_items[item['key']][index]['curtain']"
                    />
                  
                    <van-popup position="bottom" v-model="pos['curtain_show']">
                      <van-picker
                        show-toolbar
                        :columns="options.curtain_types"
                        @cancel="
                          onPosItemClick(item['key'], index, 'curtain', false)
                        "
                        @confirm="
                          (val) =>
                            setPosItem(val, item['key'], index, 'curtain')
                        "
                      />
                    </van-popup>
                    <!-- <span style="color: #323233;font-size:10px;">单价：{{ pos['price'] }}</span> -->
                  </template>
                </van-grid-item>
              
                <van-grid-item  @click="
                        () => show_popup(item['key'], index, 'product_show',pos['product_id'])
                      ">
                  <template>
                     <span
                      readonly
                      clickable
                      input-align="center"
                      style="    width: 70px;font-size: 12px;"
                      
                     
                    >{{tmp_pos_items[item['key']][index]['product']}}</span>
                    <van-icon v-if="allow_toggle_product" style="    position: absolute;
    right: 5px;
    color: #bbb;
    font-size: 10px;" name="arrow-down" />
                  </template>
                </van-grid-item>
                <van-grid-item>
                  <template>
                 
                   <label v-if="(tmp_pos_items[item['key']][index]['independ_price']&&design.whole_price&&design.whole_price!=null)||design.whole_price==null||!design.whole_price">
                    {{price_ratio2(tmp_pos_items[item['key']][index]['price'])}}
                   </label>
                    <!-- <van-field
                      v-model="tmp_pos_items[item['key']][index]['price']"
                      @change="update(tmp_pos_items[item['key']][index])"
                      type="number"
                      :readonly="true"
                      input-align="center"
                      :formatter="fix3"
                      placeholder="--"
                    /> -->
                  </template>
                </van-grid-item>
                <van-grid-item>
                  <template>
                    <van-field
                      v-model="tmp_pos_items[item['key']][index]['quantity']"
                      @change="updateFoldNum(item['key'], index)"
                      type="number"
                       :readonly="(!tmp_pos_items[item['key']][index]['independ_price']&&design.whole_price)||!design.whole_price"
                      input-align="center"
                      :formatter="fix2"
                      placeholder="--"
                    />
                    <span style="    font-size: 12px;
    position: absolute;
    right: 5px"> {{pos_items[item['key']][index].unit_label}}</span>
                  </template>
                </van-grid-item>
              </van-grid>
              <div v-if="checked[item['key']]&&userType" class="buwei-desc-wrapper">
                <input @blur="updateField" @input="inpuchange2"  v-model="cart.pos_comment[item['key']]"   class="buwei-desc" type="text" placeholder="部位备注，选填" />
              </div>
               </div>
            </div>
          </template>
          </div>
        </template>
        </van-tab>
  <van-tab title="基本信息">
        <!-- 计算用料 -->
        
          <!-- <van-row>
            <van-col span="14">
              <van-field label="窗户尺寸"
                         v-model="cart.window_width"
                         type="number"
                         :formatter="fix2"
                         @blur="update('api_update')"
                         clearable
                         required
                         input-align="center"
                         placeholder="窗宽"/>
            </van-col>
            <van-col span="3">
              <van-cell title="X"/>
            </van-col>
            <van-col span="7">
              <van-field v-model="cart.window_height" :formatter="fix2" @blur="update('api_update')" type="number" clearable input-align="center" placeholder="窗高"/>
            </van-col>
          </van-row> -->
          
          <div v-if="order_show_item.includes('install_location')" class="section">
            <div class="label">安装位置</div>

            <div class="aaa">
              <van-tag
                :key="item.key"
                size="medium"
                @click="setActive('install_locations', item)"
               
                v-for="item in options.install_locations"
                
                type=""
                :class="
                  selectedTag.install_locations == item.text
                    ? 'aactive'
                    : ''
                "
                tag-padding="10"
                >{{ item.text }}</van-tag
              >
              <van-tag
              
                size="medium"
                click=""
                   :class="
                 selectedTag.install_locations&& selectedTag.install_locations == customer_locations
                    ? 'aactive'
                    : ''
                "
                
                
                type=""
                @click="setActive('install_locations',{text:customer_locations})"
                tag-padding="10"
                v-if="!editStatus"
            
                >{{customer_locations||'自定义'}}<van-icon @click="setInputStatus" name="edit" /></van-tag>
                <van-tag
                size="medium"
                click=""
                color="#F2F2F2"
                
                type=""
                tag-padding="10"
                v-if="editStatus">
                <input v-model="_customer_locations"  style="max-width: 60px;border:0"/>
                <van-icon @click="dosave" size='20' name="completed" />
                </van-tag>
            </div>
          </div>
          <div v-if="order_show_item.includes('fold_times')" class="section">
            <div class="label">褶倍</div>

            <div class="aaa">
              <van-tag
                :key="item.key"
                size="medium"
                @click="setActive('fold_times', item)"
               
                v-for="(item, index) in options.fold_times"
               
                :class="
                  selectedTag.fold_times == item.key
                    ? 'aactive'
                    : '#F2F2F2'
                "
                tag-padding="10"
                >{{ item.text }}</van-tag
              >
            </div>
          </div>
          <div v-if="order_show_item.includes('price_method')" class="section">
            <div class="label">购买方式</div>

            <div class="aaa">
              <van-tag
                :key="item.key"
                size="medium"
               
                @click="setActive('process_types', item)"
                v-for="(item, index) in options.process_types"
                v-if="item.text"
              
                :class="
                  selectedTag.process_types == item.key
                    ? 'aactive'
                    : ''
                "
                tag-padding="10"
                >{{ item.text }}</van-tag
              >
            </div>
          </div>
          <div v-if="userType&&order_show_item.includes('suborder_comment')" class="section">
            <div class="label">备注</div>
<!-- @blur="updateField"  -->
            <div class="aaa">
              <textarea
                @input="inpuchange2"
                v-model="cart.comment"
                style="      width: 250px;
    border-color: #aaa;
    border-radius: 3px;"
                placeholder="选填 请和商家协商一致"
              />
            </div>
          </div>
          <div v-if="userType&&order_show_item.includes('image_upload')" class="section">
            <div class="label">图片上传</div>

            <div class="aaa">
              <template>
                <van-uploader
                  v-model="imgList"
                  result-type="file"
                  :after-read="afterRead"
                  :max-count="1"
                />
              </template>
            </div>
          </div>
          <div v-if="userType" class="section m-b-10">
             <div class="label">快速备注</div>
             <div class="aaa">
            <van-checkbox-group  direction="horizontal" v-model="cart.order_extend_check">
             <div  :key="item.text.value" v-for="item in order_extend_check_all">
              <van-checkbox
                :name="item.text.value"
                >{{ item.text.label }}</van-checkbox>
             </div>
            </van-checkbox-group>
             </div>
          </div>
   </van-tab>
 
</van-tabs>
      </van-form>
      <van-tabbar active-color="#7d7e80" :safe-area-inset-bottom="true">
        <van-tabbar-item @click="goBack" icon="replay">返回</van-tabbar-item>
        <!-- <van-tabbar-item @click="$router.replace('/cart')" icon="shopping-cart">购物车</van-tabbar-item> -->
        <van-tabbar-item
          @click="onSubmit"
          style="
            color: #fff;
            background-color: #07c160;
            border: 1px solid #07c160;
          "
          >加入购物车</van-tabbar-item
        >
        <van-tabbar-item @click="calc" style="flex:1;padding: 0 10px;position:relative;font-size: 18px;">
            <img style="width: 20px;
              height: 20px;
              position: absolute;
              left: 5px;
              top: 12px;" src="/jisuanqi.png" />
          <template #icon="props">
            <span :formatter="fix2">¥{{ price_ratio2(cart.total_price||0) }}</span
            >
          </template>
          计算
        </van-tabbar-item>
      </van-tabbar>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant';
var scalarArrayEquals = function (array1 = [], array2 = []) {
  return (
    array1.length == array2.length &&
    array1.every(function (v, i) {
      return v === array2[i];
    })
  );
};
var isClick2 = true;
var isDirty = false;
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    design: {
      type: Object,
      default: {},
    },
    cart_info: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      onSubmited:false,
      allow_toggle_product:false,
      curpage:1,
      loading:false,
      finished:false,
      activeProductIndex:-1,
       productModalshow:false,
      item_key:"",
      item_index:0,
      order_show_item:[],
      clickOverlay:false,
      userType:1,
      last_pos_extend: {},
      _customer_locations: "",
      customer_locations: "",
      editStatus: false,
      pos_comment: {},
      selected_extend_check: {},
      pos_strap_dir: {},
      pos_fold_gap: {},
      pos_open_style: {},
      pos_craft: {},
      pos_width: {},
      pos_height: {},
     
      selectedTag: {
        install_locations: "",
        fold_times: "",
        process_types: "",
      },
      keyWord: "",
      action: "",
      initCount: 0,
      showPickerList: {},
      Productlist: [],
      PosItemlist: {},
      pos_items: {},
      imgList: [],
      options: {
        order_extend_check: [],
        fold_times: [],
        curtain_types: [],
        install_crafts: [],
        install_locations: [],
        open_styles: [],
        craft_wailian: [],
        craft_shalian: [],
        positions: [],
        process_types: [],
        strap_dirs: [],
      },
      tmp_val: {
        checked: {},
      },
      checked: {},
      init_cart: {
        pos_items: {},
      },
      tmp_pos_items: {},
      order_extend_check: [],
      cart: {
        
         _dingxing_wailian:{},
          _dingxing_shalian:{},
       dingxing_wailian:"",
       dingxing_shalian:"",
        pos_extend_check: {
          wailian: "",
          shalian: "",
          ruanzhuangbu: "",
          qita: "",
          fengrenbu: "",
        },
        pos_strap_dir: {},
        pos_craft: {},
        pos_fold_gap: {},
        pos_comment: {},
        pos_open_style: {},
        order_extend_check: [],
        customer: "",
        comment: "",
        strap_dir: "",
        install_craft: "",
        open_style: "",
        craft_wailian: "",
        craft_shalian: "",
        install_location: "",
        process_type: "",
        fold_times: "",
        total_price: 0,
        fold_gap: "",
        window_width: "",
        window_height: "",
        pos_price: {},
        pos_quantity: {},
        pos_subtotal: {},
        pos_subtotal_label: {},
        pos_items: {},
        corner_left:"",
        corner_right:"",
        pos_width: {},
        pos_height: {},
      },
    };
  },
  watch: {
   
  },
  computed: {
    order_extend_check_all() {
      this.options.order_extend_check = this.options.order_extend_check || [];
      return this.options.order_extend_check.filter((item) => {
        return !item.text.position;
      });
    },
    is_show: {
      get: function () {
        return this.show;
      },
      set: function () {
        this.$parent.isShowCartInfoPop = false;
      },
    },
  },
  destroyed(){
    if(this.is_show){
      this.goBack();
      window.removeEventListener("beforeunload",this.goBack)
    }
      
  },
  methods: {
   

 

 

 preventD() {

    return

  },
    delCart(){
       
// /
    },
    price_ratio2(v=''){
      if(v&&v!=0&&v!=''){
        v=v+''
        v = parseFloat(v.replace("¥",""))
        v = Number(v* (this.cart.price_ratio||1)).toFixed(2);
          if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='round'){
       v = Math.round(v)
       
    }
    if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='floor'){
        v = Math.floor(v)
         
    }
       return v;
      }else if(v==0){
        return 0
      }else if(v==''||v==null){
        return ''
      }
     
    },
    onCancel(){
         this.Productlist =[];
            this.curpage =1;
            this.keyWord = '';
            this.activeProductIndex =-1;
          this.getProducts();
    },
    onLoad(e){
  
      if(this.Productlist.length>0){
       this.curpage ++
       this.getProducts(this.productcode);
      }
     
    },
      setActiveProduct(index){
    
       this.activeProductIndex = index;
    },
   inpuchange2(){
     isDirty = true
    }, 
    dosave() {
      this.customer_locations = this._customer_locations;
      this.editStatus = false;
      this._customer_locations = "";
      this.cart.install_location = this.customer_locations;
      this.selectedTag.install_locations = this.customer_locations;
    },
    setInputStatus() {
      this._customer_locations = this.customer_locations;
      this.editStatus = true;
    },
    change2(value, key) {
      // debugger
      if (
        event &&
        event.currentTarget &&
        event.currentTarget.constructor &&
        event.currentTarget.constructor.name == "XMLHttpRequest"
      ) {
        return;
      }
      if (!isClick2) {
      
        return;
      }
    
      // clearTimeout(timer);
      var timer = setTimeout(function () {
        isClick2 = true;
      }, 800); //一秒内不能重复点击
      isClick2 = false;
        isDirty = true;
      this.cart.pos_extend_check = {
        ...this.cart.pos_extend_check,
        [key]: value,
      };
       this.$forceUpdate();
        // this.update("api_update");
    
    },
    updateField() {
      this.update("api_update");
    },
    setInput(type, pos) {
      isDirty = true;
      if (type == "fold_gap") {
        this.pos_fold_gap[pos] = event.target.value;
      } else if (type == "pos_width") {
        this.cart.pos_width[pos] = event.target.value;
      } else if (type == "pos_height") {
        this.cart.pos_height[pos] = event.target.value;
      }
      // this.update("api_update");
    },
    setPosActive(pos, type, item) {
   
       isDirty = true;
      if (type == "process_types") {
        let pos_open_style = { ...this.cart.pos_open_style };
        pos_open_style[pos] = pos_open_style[pos]!= item.key?item.key:undefined;
        this.cart.pos_open_style = pos_open_style;
      } else if (type == "install_crafts") {
        let pos_craft = { ...this.cart.pos_craft };
        pos_craft[pos] =  pos_craft[pos]!= item.key?item.key:undefined;
        this.cart.pos_craft = pos_craft;
      }else if (type == "dingxing") {
        let dingxing =this.cart['dingxing_'+pos]||{};
        dingxing =  dingxing!= item.key?item.key:undefined;
         this.cart['dingxing_'+pos]=dingxing;
          this.cart['_dingxing_'+pos]=dingxing?{key:dingxing,text:item.text} :{};
      } else if (type == "pos_strap_dir") {
        let pos_strap_dir = { ...this.cart.pos_strap_dir };
        pos_strap_dir[pos] = pos_strap_dir[pos]!= item.key?item.key:undefined;
        this.cart.pos_strap_dir = pos_strap_dir;
      }
      // this.update("api_update");
    },
    setActive(type, item) {
       isDirty = true;
      let { selectedTag } = this;
      selectedTag[type] = item.key;
      this.selectedTag = selectedTag;
      if (type == "install_locations") {
        selectedTag[type] = item.text;
        this.cart.install_locations = item.text;
        this.cart.install_location = item.text;
      } else if (type == "fold_times") {
        this.cart.fold_times = item.key;
      } else if (type == "process_types") {
        this.cart.process_type = item.key;
      }
      // this.update("api_update");
    },
    postion_extend_check(key) {
      this.options.order_extend_check = this.options.order_extend_check || [];
      return this.options.order_extend_check.filter((item) => {
        return item.text.position == key;
      });
    },
    onSearch(e) {
       this.activeProductIndex =-1;
      this.Productlist =[];
      this.curpage = 1;
      this.getProducts();
      // this.Productlist = this.allProductlist.filter((item) =>
      //   item.text.includes(event.target.value)
      // );
     
    },
    init() {
      
    
      isDirty = false;
      var params = new URLSearchParams(window.location.href);
      this.userType =params.get("userType") ==='0'?0:1;
      if (!this.$store.getters.token) {
        this.is_show = false;
        this.$dialog
          .confirm({
            title: "请先登录",
            confirmButtonText: "去登录",
          })
          .then(() => {
            this.$store.dispatch("Logout");
          });
        return false;
      } else {
     
        let shop = sessionStorage.getItem("shop") || "{}";
      //   shop ='{}';
      //  sessionStorage.setItem("shop","{}")
         if(shop!='{}'){
            shop = JSON.parse(shop)||{config:{}};
            this.order_show_item = shop.config.order_show_item||[];
              this.allow_toggle_product = shop.config.allow_toggle_product||false;
              this.smart_curtain_require_item = shop.config.smart_curtain_require_item||[]
          }else{
            this.$store.dispatch("GetShop").then(()=>{
         
               shop = sessionStorage.getItem("shop") || "{}";
               shop = JSON.parse(shop)||{config:{}};
                   this.smart_curtain_require_item = shop.config.smart_curtain_require_item||[]
            this.order_show_item = shop.config.order_show_item||[];
               this.allow_toggle_product = shop.config.allow_toggle_product||false;

            })
          }
        this.getClientsOptions();
       
      }
    },
   async  show_popup(key, index, type,productid) {
       
       if(type=='product_show'){
       
         if(!this.allow_toggle_product){
        
           return 
         }
        
          if(productid!=this.productcode&&this.productcode){
            this.Productlist =[];
            this.curpage =1;
            this.keyWord = '';
              this.activeProductIndex =-1;
          }
          if(this.Productlist.length==0){
             await this.getProducts(productid);
           }
          this.productcode = productid;
          this.productModalshow =true;
           this.item_key = key;
           this.item_index = index;
          
           
       }
      this.tmp_pos_items[key][index][type] = true;
      this.$forceUpdate();
    },
    checkData(){
        
       
       let checkedPos = Object.keys(this.tmp_pos_items||{}).filter((item)=>{
         if(this.tmp_pos_items[item].length>0&&this.checked[item]){
        return item
       }})
      let requiredItems = this.smart_curtain_require_item.filter((item)=>this.smart_curtain_require_item.includes(item))||[]
    let requiredItems2 =[];
      if(this.smart_curtain_require_item.includes("install_location")){
       !this.cart.install_location&& requiredItems2.push({code:"install_location",message:"【基本信息中】安装位置不能为空"})
     }
    checkedPos.map(($item)=>{
      let posname ="【"+ this.options.positions.find((item)=>item.key==$item).text+"】"
      requiredItems.filter((item)=>{
          if(item.includes($item)&&(item.includes("width")||item.includes("height"))){   //判断部位上的宽高
            let w =item.includes("width")?item.slice(-5):"";
            let h = item.includes("height")?item.slice(-6):"";
            let t = this.cart['pos_'+w]||this.cart['pos_'+h]
            if(!t[$item]){
              requiredItems2.push({code:item,message:posname+(w?'宽不能为空':'高不能为空')})
            }
            
            
          }
          // if(($item=='shalian'||$item=='wailian')&&item=='dingxing'){    //check dingxing
          //    let v = this.cart['dingxing_'+$item]
          //     !v&& requiredItems2.push({code:item,message:posname+"定型不能为空"})
          // }
          if(($item=='shalian')&&item=='shalian_dingxing'){    //check dingxing
             let v = this.cart['dingxing_shalian']
              !v&& requiredItems2.push({code:item,message:"【纱帘】定型不能为空"})
          }
           if(($item=='wailian')&&item=='wailian_dingxing'){    //check dingxing
             let v = this.cart['dingxing_wailian']
              !v&& requiredItems2.push({code:item,message:"【外帘】定型不能为空"})
          }
          if(($item=='shalian'||$item=='wailian')&&item=='process_method'){    //check 加工方式
              let v = this.cart.pos_craft[$item]
                !v&&requiredItems2.push({code:item,message:posname+"加工方式不能为空"})
          }
          if(($item=='shalian'||$item=='wailian')&&item=='open_style'){    //check 打开方式
              let v = this.cart.pos_open_style[$item]
                !v&&requiredItems2.push({code:item,message:posname+"打开方式不能为空"})
          }
          if(($item=='liantou')&&item=='corner'){    //check 转角
              
             !this.cart.corner_left&& requiredItems2.push({code:item,message:posname+"左转角不能为空"})
              !this.cart.corner_right&& requiredItems2.push({code:item,message:posname+"右转角不能为空"})
          }
          if(($item=='liantou')&&item=='strap_dir'){    //check 方向
         
              let v = this.cart.pos_strap_dir[$item]
              !v&&requiredItems2.push({code:item,message:posname+"方向不能为空"})
          }
          
      })||[]
        
    })
   
       if(requiredItems2.length>0){
        let str = requiredItems2.map((item)=>'<div class="van-hairline--bottom sss">'+item.message+'</div>').join("");
         this.$dialog
          .alert({
            allowHtml:true,
            title: '错误提示',
           message: "<div style='text-align: left;'><div style='    color: red;    margin-bottom: 10px;'>请修改完成后进行提交</div>"+str+"</div>",
            confirmButtonText: "确认",
          })
          
       }
        return requiredItems2.length==0
    },
    updateCheck(key) {
      // this.checked[key] = !this.checked[key]
      this.un_checked_pos = this.un_checked_pos||[]
      this.$http
        .get(
          "/clients/cart/" +
            this.init_cart.index +
            "/price?positions=" +
            this.getPositionStr()
        )
        .then((res) => {
          this.cart.total_price = res.total_price;
         if(!this.un_checked_pos.includes(key)){
             this.un_checked_pos.push(key);
         }else{
             this.un_checked_pos.splice(this.un_checked_pos.indexOf(key),1);
         }
        })
        .catch((err) => {
         
          this.$toast.fail("系统异常");
        });
      this.$forceUpdate();
      // this.calc()
      // this.$forceUpdate()
    },
    initData(res) {
      if (!res) return;
      this.initCount += 1;
      this.init_cart = res;
      this.pos_items = res.pos_items;
      this.cart.window_height = res.window_height;
      this.cart.window_width = res.window_width;
      this.cart.customer = res.customer;
      this.cart.fold_gap = res.fold_gap;
      this.cart.comment = res.comment;
      this.cart.price_ratio = res.price_ratio;
      this.cart.pos_price = res.pos_price;
      this.cart.pos_quantity = res.pos_quantity;
      this.cart.pos_subtotal = res.pos_subtotal;
      this.cart.pos_subtotal_label = res.pos_subtotal_label;
      this.cart.corner_left = res.corner_left;
      this.cart.corner_right = res.corner_right;
      this.cart.total_price = res.total_price;
      this.cart.fold_times = res.fold_times;
      this.cart.process_type = res.process_type;
      this.cart.dingxing_wailian = res.dingxing_wailian;
         this.cart.dingxing_shalian = res.dingxing_shalian;
      this.cart._dingxing_wailian = res._dingxing_wailian;
         this.cart._dingxing_shalian = res._dingxing_shalian;
      this.cart.install_location =
        res.install_location || res.install_locations;
      this.cart.order_extend_check = res.order_extend_check;
      this.cart.pos_extend_check = res.pos_extend_check || {};
      this.selectedTag.install_locations =
        res.install_location || res.install_locations;
      this.selectedTag.fold_times = res.fold_times;
      this.selectedTag.process_types = res.process_type;
      this.cart.install_craft = res.install_craft;
      this.cart.strap_dir = res.strap_dir;
      this.cart.open_style = res.open_style;
      this.cart.craft_shalian = res.craft_shalian;
      this.cart.craft_wailian = res.craft_wailian;
      this.cart.pos_comment = res.pos_comment;
      this.cart.pos_width = res.pos_width;
      this.cart.pos_height = res.pos_height;
      this.cart.pos_fold_gap = res.pos_fold_gap;
      this.cart.pos_open_style = res.pos_open_style;
      this.cart.pos_craft = res.pos_craft;
      this.cart.pos_strap_dir = res.pos_strap_dir;
      if (res.img && res.img != "") {
        this.imgList = [{ url: res.img }];
      }
      if (this.options) {
        if (this.options.fold_times && res.fold_times) {
          this.tmp_val.fold_times = this.options.fold_times.find(
            (_) => _.key == res.fold_times
          );
          if (this.tmp_val.fold_times) {
            this.tmp_val.fold_times = this.tmp_val.fold_times["text"];
          }
        }

        if (this.options.process_types && res.process_type) {
          this.tmp_val.process_type = this.options.process_types.find(
            (_) => _.key == res.process_type
          );
          if (this.tmp_val.process_type) {
            this.tmp_val.process_type = this.tmp_val.process_type["text"];
          }
        }

        if (this.options.install_locations && res.install_location) {
          this.tmp_val.install_location = this.options.install_locations.find(
            (_) => _.key == res.install_location
          );
          if (this.tmp_val.install_location) {
            this.tmp_val.install_location =
              this.tmp_val.install_location["text"];
          }
          let install_location = res.install_location || res.install_locations;
          if (
            !this.options.install_locations
              .map((item) => item.text)
              .includes(install_location)
          ) {
            this.customer_locations = install_location;
          }
        }

        if (this.options.install_crafts && res.install_craft) {
          this.tmp_val.install_craft = this.options.install_crafts.find(
            (_) => _.key == res.install_craft
          );
          if (this.tmp_val.install_craft) {
            this.tmp_val.install_craft = this.tmp_val.install_craft["text"];
          }
        }

        if (this.options.install_crafts && res.craft_wailian) {
          this.tmp_val.craft_wailian = this.options.install_crafts.find(
            (_) => _.key == res.craft_wailian
          );
          if (this.tmp_val.craft_wailian) {
            this.tmp_val.craft_wailian = this.tmp_val.craft_wailian["text"];
          }
        }

        if (this.options.install_crafts && res.craft_shalian) {
          this.tmp_val.craft_shalian = this.options.install_crafts.find(
            (_) => _.key == res.craft_shalian
          );
          if (this.tmp_val.craft_shalian) {
            this.tmp_val.craft_shalian = this.tmp_val.craft_shalian["text"];
          }
        }

        if (this.options.strap_dirs && res.strap_dir) {
          this.tmp_val.strap_dir = this.options.strap_dirs.find(
            (_) => _.key == res.strap_dir
          );
          if (this.tmp_val.strap_dir) {
            this.tmp_val.strap_dir = this.tmp_val.strap_dir["text"];
          }
        }

        if (this.options.open_styles && res.open_style) {
          this.tmp_val.open_style = this.options.open_styles.find(
            (_) => _.key == res.open_style
          );
          if (this.tmp_val.open_style) {
            this.tmp_val.open_style = this.tmp_val.open_style["text"];
          }
        }
      }
      Object.entries(res.pos_items ? res.pos_items : {}).forEach(
        ([key, value]) => {
          this.tmp_pos_items[key] = [];
           var un_checked_pos = res.un_checked_pos||[];
         
            this.checked[key] = false;
            if (!un_checked_pos.includes(key)) {
              this.checked[key] = true;
          }
          Object.values(value ? value : {}).forEach((v, k) => {
            let product = this.getProduct(null, v.product_id);
            this.tmp_pos_items[key][k] = {
              key: v && v.key ? v.key : "",
              index: this.tmp_pos_items[key].length,
              curtain_show: false,
              independ_price:v.independ_price,
              curtain_type: v.curtain_type,
              curtain: this.getCurtain(null, v.curtain_type)["text"],
              product_show: false,
              product_id: v.product_id,
              product: product["text"]||v.product_code,
              quantity: v.quantity,
              price: v.price,
              subtotal: v.subtotal,
              position: key,
              fold_num: v.fold_num,
            };
          });
        }
      );
      this.$forceUpdate();
    },
    initCart() {
      let that = this
       window.addEventListener("beforeunload",()=>{
         that.is_show&&that.goBack()
       })
      if (this.design && this.design.id) {
        this.$http
          .patch("/clients/cart/apply/" + this.design.id)
          .then((res) => {
            this.initData(res);
          });
        this.action = "create";
      } else if (this.cart_info) {
        this.initData(this.cart_info);
        this.design.whole_price = this.cart_info.whole_price;
        this.action = "update";
      }
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      //new 一个FormData格式的参数
      let params = new FormData();
      params.append("file", file.file);
      let config = {
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data",
        },
      };
      //把 uploadUrl 换成自己的 上传路径
      this.$http
        .post("/clients/orders/image", params, config)
        .then((res) => {
          if (res) {
            //如果为真 resolve出去
            let img = res.split("storage")[1];
            res = '/storage'+img;
            this.imgList = [
              {
                url: res,
                status: "done",
                message: "上传完成",
              },
            ];
            this.update("api_update");
          } else {
            //否则 Toast 提示
            this.$toast.fail("上传失败");
          }
        })
        .catch((err) => {
          this.$toast.fail("系统异常");
        });

      // return new Promise((resolve, reject) => {
      //     //把 uploadUrl 换成自己的 上传路径
      //     this.$http.post('/clients/orders/image', params, config).then(res => {
      //         console.log(res)
      //         if (res) {				//如果为真 resolve出去
      //             this.imgList = [{
      //                 url: res,
      //                 status: 'done',
      //                 message: '上传完成',
      //             }]
      //             resolve((new File(res, file.file.name, {type: file.file.type,})));
      //         } else {
      //             //否则 Toast 提示
      //             this.$toast.fail('上传失败')
      //         }
      //     }).catch(err => {
      //         this.$toast.fail('系统异常')
      //         reject(err)
      //     });
      // })
    },
    getProducts(productcode) {
      this.loading = true;
      productcode = productcode||this.productcode
      let {client_id,shop_id} = JSON.parse(localStorage.getItem("user")||'{}')
      
      let url = "/clients/helpers/products?order_type=design&product_id="+productcode+'&pagesize=20&client_id='+client_id+"&shop_id="+shop_id+"&page="+this.curpage+'&code='+this.keyWord;
      let ret = this.$http.get(url).then((res) => {
           this.loading = false;
        let Productlist = Object.entries(res || {}).map(([k, v]) => {
          return {
            text: v.code,
            _text: v,
            key: v.id,
          };
        });
        this.finished = res.length==0?true:false;
        this.Productlist = this.Productlist.concat(Productlist);
        
      }).catch((res)=>{

           this.loading = false;
      });
      return ret;
    },
    getClientsOptions() {
      this.$http.get("/clients/options").then((res) => {
        let options = {};
        let _this = this;
        Object.entries(res).forEach(([key, value]) => {
       
          this.showPickerList[key] = false;
          options[key] = Object.entries(value).map(([k, v]) => {
            if (key === "positions") {
              this.tmp_pos_items[k] = [];
            }
            return {
              text: v,
              key: k,
            };
          });
          
        });
      
        if(options.positions_sort&&options.positions_sort.length>0){
          options.positions_sort.forEach((item)=>{
             
          })
        }
        this.options = options;
        this.initCart();
        // this.getProducts();
      });
    },
    getCurtain(val, key) {
      let t = this.options.curtain_types.find(
        (_) => (key && _.key == key) || (val && _.text == val)
      );
      return t ? t : { text: "", key: "" };
    },
    getProduct(val, key) {
      let t = this.Productlist.find(
        (_) => (key && _.key == key) || (val && _.text == val)
      );
      return t ? t : { text: "", key: "" };
    },
    checkCalc() {
      // if (!this.cart.window_width) {
      //   this.$toast.fail("请输入窗宽");
      //   return false;
      // }
      // if (!this.cart.window_height) {
      //   this.$toast.fail("请输入窗高");
      //   return false;
      // }
     return  this.checkData();
      // if (!this.cart.fold_times&&this.order_show_item.includes('fold_times')) {
      //   this.$toast.fail("请输入褶倍");
      //   return false;
      // }
      // // if (!this.cart.fold_gap) {
      // //   this.$toast.fail("请输入褶距");
      // //   return false;
      // // }
      // if (!this.cart.process_type&&this.order_show_item.includes('price_method')) {
      //   this.$toast.fail("请输入购买方式");
      //   return false;
      // }
      // return true;
    },
    calc() {
      let that = this;
      if (!this.checkCalc()) {
        return false;
      }
      return  this.update("api_update",true).then(()=>{
       
     
        return this.$http(
         
         { method:"patch",url: "/clients/cart/" + this.init_cart.index + "/resize"
         ,data:this.getData(),
         message:"价格核算中"}
          

        )
        .then((res) => {
           let {order_id=''} =this.cart_info;
     if(order_id){
    
    this.$http({url:"/clients/orders/"+order_id+"/update/"+ this.init_cart.index,method:"patch",data:{}}).then(()=>{
     
      that.$emit("hideCartInfoPopup", false);
       that.is_show = false;
       this.initData(res);
          this.$forceUpdate();
       }).catch(()=>{
         that.$emit("hideCartInfoPopup", false);
       that.is_show = false;
       this.initData(res);
          this.$forceUpdate();
       })
     }else{
        this.initData(res);
          this.$forceUpdate();
     }
      
       
        });
       })
     
    },
    fix2(val) {
      let t = val.toString();
      let min = t.indexOf(".");
      if (val && !isNaN(val) && min !== -1 && t.length > t.indexOf(".") + 2) {
        return parseFloat(t.substring(0, min + 2 + 1));
      }
      return val;
    },
     fix3(val) {
      let t = val.toString();
      let min = t.indexOf(".");
      if (val && !isNaN(val) && min !== -1 && t.length > t.indexOf(".") + 2) {
        return parseFloat(t.substring(0, min + 2 + 1));
      }
      return val*2;
    },
    setPosItem2(){
      
        if(this.activeProductIndex==-1){
          return 
        }
        let item   = this.Productlist[this.activeProductIndex]
         this.update("api_update").then(()=>{
           this.setPosItem(item,this.item_key,this.item_index,'product_id');
         })
     
    },
    setPosItem(val, key, index, type) {
      let _positem = { ...this.tmp_pos_items[key][index] };
      _positem[type] = val.text;
      _positem["product_id"] = val.key;
      if(type=='product_id'){
        _positem["price"] = val._text.price;
      }
      this.tmp_pos_items[key] = this.tmp_pos_items[key].map((item, $index) => {
        if ($index == index) {
          return _positem;
        } else {
          return item;
        }
      });
      this.update(this.tmp_pos_items[key][index]);
      this.onPosItemClick(key, index, type, false);
    },
    setPicker(val, type) {
      this.cart[type] = val["key"];
      this.tmp_val[type] = val["text"];
      this.update("api_update");
      this.onPickerClick(type, false);
    },
    add(key) {
      this.tmp_pos_items[key].push({
        key: "",
        index: this.tmp_pos_items[key].length,
        curtain_show: false,
        curtain_type: "",
        curtain: "",
        product_show: false,
        product_id: 0,
        product: "",
        quantity: null,
        fold_num: null,
      });
      this.$set(this.tmp_pos_items, `${key}`, this.tmp_pos_items[key]);
      this.$forceUpdate();
    },
    del(key, index) {
      this.tmp_pos_items[key].splice(index, 1);
      this.$forceUpdate();
    },
    getData() {
      let pos_items = {};
      Object.entries(this.tmp_pos_items ? this.tmp_pos_items : {}).forEach(
        ([key, val]) => {
          // if (this.checked[key]) {
            pos_items[key] = [];
            pos_items[key] = val.map((_) => {
              return Object.assign(
                this.init_cart["pos_items"][key] &&
                  this.init_cart["pos_items"][key][_.index]
                  ? this.init_cart["pos_items"][key][_.index]
                  : {},
                {
                  key: _.key,
                  product_id: _.product_id,
                  curtain_type: _.curtain_type,
                  fold_num: _.fold_num,
                  quantity: parseFloat(_.quantity),
                }
              );
            });
          }
        // }
      );
      this.cart.img =
        this.imgList && this.imgList[0] ? this.imgList[0]["url"] : "";
      this.cart.pos_items = pos_items;
      this.cart.positions = this.getPositionStr();
      this.cart.whole_price = this.design.whole_price
      return Object.assign(this.init_cart, this.cart);
    },
    update(type,noInitData=false) {
         let user = JSON.parse(localStorage.getItem("user")||'{}')
         user.client = user.client||{};
        let data = this.getData();
        data.brand = user.client.brand;
    
      if (type == "api_update") {
          data.un_checked_pos = this.getUnCheckedPos();
        return this.$http
          ({url:"/clients/cart/" + this.init_cart.index,method:"patch", data:data,hidden_toast:true})
          .then((res) => {
            if(!noInitData){
                this.initData(res);
            }
           
          });
      } else {
        return this.$http
          .post("/clients/cart/" + this.init_cart.index + "/add", type)
          .then((res) => {
            this.initData(res);
          });
      }
    },
    updateFoldNum(key1, key2) {
     
      let value = this.tmp_pos_items[key1][key2]["fold_num"];
      let tmp_pos_items_quantity = this.tmp_pos_items[key1][key2]["quantity"];
      if (
        this.cart.fold_gap &&
        !isNaN(this.cart.fold_gap) &&
        value &&
        !isNaN(value)
      ) {
        tmp_pos_items_quantity = this.fix2(
          value * this.cart.fold_gap
        );
        
      }
      
      
      this.update("api_update",true).then(()=>{
         this.tmp_pos_items[key1][key2]["quantity"] =tmp_pos_items_quantity;
         this.$forceUpdate();
         this.$nextTick(()=>{
          this.update(this.tmp_pos_items[key1][key2]);
         })
       
      })
      
    },
    getCartNum(){
      if(!this.$store.getters.token) {
        return 
      }
      //  http://lian3.suishouecai.com/api/clients/
       this.$http({url:'/clients/carts/num',method:"get",hidden_toast:true}).then(res => {
         if(res){
            localStorage.setItem('cartcount',res)
            // this.count = res
              this.$parent.count = res
         }else{
            this.count = ''
         }
         
      })
     
    },
    onSubmit() {
       if(this.getPositionStr()==''){
           this.$toast.fail("请至少选择一个部位");
        return 
      }
      if (!this.checkCalc()) {
        return false;
      }
      let that = this;
     
      this.calc().then((_) => {
        this.$http
          ({url:"/clients/cart/" + this.init_cart.index + "/positions",method:"post", hidden_toast:true, data:{
            positions: this.getPositionStr(true),
          }})
          .then((res) => {
          
           

           
// 
            that.$toast("购物车添加成功");
            that.getCartNum();
            this.$store.getters.confirmorderid&&this.action =="create"&&this.$http
                            .post("/clients/orders/carts?order_type=design_order",{created_at:JSON.stringify(new Date()).replace(/T.*|"/g, ""),index: this.init_cart.index,source:"zhilian",group_order_id:this.$store.getters.confirmorderid,status:'draft',onlyProduct:true})
                            .then(res => {})
          })
          .catch((err) => {
            that.$toast.fail("系统异常");
            
          });
      
    let {order_id=''} =this.cart_info;
   
        // this.goBack();
        if(!order_id){
that.$emit("hideCartInfoPopup", false);
 that.is_show = false;
        }else{
          
         
        }
      
        // this.$router.replace('/cart')
      });
    },
    getUnCheckedPos(){
      let arr =[];
      Object.entries(this.pos_items ? this.pos_items : {}).forEach(
        ([key, value]) => {
          if (!this.checked[key]&& this.tmp_pos_items[key].length>0) {
           arr.push(key);
          }
        }
      );
      return arr;
    },
    getPositionStr(nochecked=false) {
      let positions1 = "";
      Object.entries(this.pos_items ? this.pos_items : {}).forEach(
        ([key, value]) => {
          if ((this.checked[key]||nochecked)&&this.tmp_pos_items[key].length>0) {
            positions1 += key + ",";
          }
        }
      );
      return positions1.slice(0,-1);
    },
    onPickerClick(key, val) {
      this.showPickerList[`${key}`] = !!val;
      this.$set(this.showPickerList, `${key}`, !!val);
      this.$forceUpdate();
    },
    onPosItemClick(key, index, type, val) {
     
      if(type!='product_id'){
       this.tmp_pos_items[`${key}`][index][type + "_show"] = !!val;
      }else{
        this.productModalshow = false;
      }
    
    
      this.keyWord = "";
      this.tmp_pos_items[`${key}`][index][type + "_show"] = !!val;
      this.$forceUpdate();
    },
    goBack() {
      
     if(isDirty){
   Dialog.confirm({
        title: '提示',
        message: '确定要放弃本次编辑吗',
      })
        .then(() => {
           if (this.action == "create") {
        this.$http
          .delete("/clients/cart/" + this.init_cart.index)
          .then((res) => {
            this.is_show = false;
          });
      }

      this.is_show = false;
      this.$emit("hideCartInfoPopup", false);
        })
        .catch(() => {
         
      
        });
     }else{
        this.is_show = false;
        
       this.action!='update'&& this.$http.delete("/clients/cart/"+this.init_cart.index,{keepalive: true})
      this.$emit("hideCartInfoPopup", false);
     }
    
  
      
   
  
 
    },
  },
};
</script>

<style>
.m-b-10 {
  margin-bottom: 10px;
}
.cart_form .calc_button {
  width: 88%;
  margin: 10px 6%;
}

.position .van-grid-item__content {
  padding: 8px;
}
.series-goods .van-grid-item__content {
  height: auto;
}

.position .van-field {
  padding: 0;
}
.aaa .van-tag {
  margin-bottom: 5px;
  margin-right: 5px;
  min-width: 50px;
  text-align: center;
  /* padding-right: 0; */
  align-items: center;
  box-sizing: border-box;
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 14px;
  background: #F2F2F2;
  border: 0;
  color:#333;
  border-radius: 5px;
      padding:10px!important;
}
.section {
  display: flex;
  padding: 10px 20px;
  padding-bottom: 0;
  align-items: center;
  
}
.section .label {
  flex: 0 0 70px;
  font-size: 14px;
  margin-right: 10px;
  /* margin-bottom: 10px; */
      color: #777;
          text-align: right;
}
.section .van-checkbox {
      margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    background: #F2F2F2;
    padding: 3px 5px;
    border-radius: 5px;
    color: #333333;
     padding:10px!important;
}
.section .van-checkbox__label {
  margin-left: 0;
}
.section .van-checkbox__icon {
  display: none;
}
.header {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 20px;
}
.header .van-checkbox {
  font-size: 12px;
  margin-right: 10px;
}
.active {
  color: #8AC58A;
  border-color: #8AC58A;
}
.buwei-desc-wrapper {
  padding: 10px;
  box-sizing: border-box;
  /* border: 1px solid #ddd; */
  width: 100%;
}
.buwei-desc {
  width: 100%;
  margin: 0;
  border: 1px solid #ddd !important;
  background: none;
  -o-border-image: none;
  border-image: none;
  padding: 5px;
  border-radius:5px ;
  box-sizing: border-box;
  /* background: #f5f5f5; */
}
.display-flex {
  display: flex;
  /* align-items: center; */
  padding-left: 20px;
  flex-wrap: wrap;
      margin-top: 10px;
}
.display-flex .label {
  flex: 0 0 70px;
  font-size: 14px;
  margin-right: 10px;
      color: #777;
      text-align: right;
}
.display-flex input {
      box-sizing: content-box;
  width: 80px;
  border-color: #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
      height: 26px;
}
.van-checkbox--horizontal {
  /* padding: 5px 20px; */
  font-size: 14px;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
  font-size: 12px;
}
.position {
  /* box-shadow: 0 1px 2px 0 rgb(0 4 12 / 10%); */
}
.section .van-checkbox__icon--checked {
}

.section .van-checkbox[aria-checked="true"]{
 
  background: #8AC58A;
}
.section .van-checkbox[aria-checked="true"] .van-checkbox__label{
 color:#fff!important;
}
.aactive{
  color:#fff!important;
  background: #8AC58A!important;
}
.border-color{
         border: 1px solid #8ac58a;
    padding-top: 10px;
    margin: 10px;
    box-sizing: border-box;
    margin-top: 0;
}
.pos-name{
      background: #8ac58a;
    color: #fff;
    padding: 5px;
    position: absolute;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    bottom: -10px;
    min-width: 40px;
    text-align: center;
}
.check-pos{
      display: flex;
    align-items: center;
    /* margin-left: 65px; */
    margin-top: 20px;
    flex:1;
    justify-content: flex-end;
}
textarea,input{
      appearance: none;
}
.close-wrapper{
      margin-right: 10px;
    position: absolute;
    right: 0;
}
.close-icon{
      border: 2px solid #9e9e9e;
    border-radius: 100%;
    padding: 3px;
    color: #9e9e9e;
    margin-right: 10px;
    font-weight: 600;
}
.van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #8ac58a;
    border-color: #8ac58a;
}
.create-order .van-tabs__wrap{
      border-bottom: 1px solid #f7f7f7;
    padding-bottom: 5px;
}
.img-item{

 
    flex: 0 0 25%;
    /* margin-right: 10px; */
    display: block;
   
        border: 2px solid transparent;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 5px;
        font-size: 14px;
        max-height:120px;
      
}
.img-item.active{
      border-color: #8AC58A;
    border-radius: 10px;
    border-width: 2px;
}
.img-item img{
      max-width: 100%;
    display: block;
    margin: 0 auto;
   min-height: 60px;
    background: #f7f7f7;
    min-width: 65px;
    border: 0!important;
    outline: none;
    max-height: 70px;
}
.van-list__finished-text{
  width: 100%;
  text-align: center;
}
 .create-order .van-uploader__preview-image{
    max-width: 80px!important;
}
.van-icon-arrow-down{
  font-size: 10px;
  color: #bbb;
}
.create-order .van-field::after{
  content: none!important;;
}
.sss{
      padding-bottom: 5px;
    margin-bottom: 10px;
}
.customer-tab .van-tabs__content{
      max-height: calc(90vh - 200px);
    overflow-y: auto;
}
</style>
