<template >
    <div class="cart" >
        <van-tabs :class='$store.getters.shop.config.enable_product_store?"two-tab":"one-tab"' v-model="activeHeader" @click="getList()" >
            <van-tab :title="$store.getters.confirmorderid?'临时单修改':'窗帘购物车'" name="chuanglian" >
                <CreateOrder
                        v-bind:show.sync="isShowCartInfoPop"
                        v-bind:design="{}"
                        @hideCartInfoPopup="hideCartInfoPopup"
                        v-bind:cart_info="cart_info"
                ></CreateOrder >
             <CreateOrderOther    v-bind:show.sync="isShowOtherCartInfoPop"   @hideCartInfoPopup="hideCartInfoPopup" v-bind:cart_info="cart_info" v-bind:design="cart_info"></CreateOrderOther>
            <template v-for="item in cardslength">
             <van-skeleton  avatar-size="70px" avatar-shape="shape" title avatar :row="3" :loading="loading">
             </van-skeleton>
            </template>
                <van-card
                        v-for="card in cards"
                        :key="card.index"
                        @click.prevent="showCartInfoPopup(card)"
                        :thumb="card.design_url"
                >
                <div slot="title"  style="margin:10px 6px 0 0;font-size:14px;" v-if="!card.design_type">
                    <div  >
                        <span style="float:left" >{{ card.serie_code }}-{{card.design_code}}</span >
                        <span style="float:right" >¥{{ price_ratio2(card.total_price_label,card.price_ratio) }}</span >
                        <div style="clear:both;" ></div >
                    </div >
                    <div slot="tags" >
                        <!-- 客户：{{ card.customer }} -->
                        <!-- <span
                                style="margin-left:5px;"
                        >尺寸：{{ card.window_width }}x{{ card.window_height }}</span > -->
                        <div class="">
                            位置：{{ card.install_location }}
                        </div>
                    </div >
                     <div v-if="card._dingxing_shalian"  slot="price" >纱帘定型：{{card._dingxing_shalian&& card._dingxing_shalian.text }}</div >
                     <div v-if="card._dingxing_wailian" slot="price" >外帘定型：{{ card._dingxing_wailian&&card._dingxing_wailian.text }}</div >
                    <div slot="price" >备注：{{ card.comment }}</div >
                    <div style="float: right;" slot="num" >
                        <van-icon name="delete" size="2em" @click.stop.prevent="deleteCard(card.index)" />
                    </div >
                    </div >
                    <div slot="title"  style="margin:10px 6px 0 0;font-size:14px;"  v-else>
                         
                     <div >
                         <span style="float:left" >{{ card.serie_code }}-{{card.design_code}}</span >
                         
                        <span v-if="card.res" style="float:right" >{{ price_ratio2(card.total_price,card.price_ratio) }} {{card.res.quantity?'x'+card.res.quantity:''}}</span >
                        <div style="clear:both;" ></div>
                       
                         <div :key="item" v-for="(item,key) in card.res">
                              <template v-if="key!='dict'&&!card.un_checked_pos.includes(key)">
                             {{key!='quantity'?card.res.dict[key]:''}} 
                             <div v-if="key!='quantity'" style="    display: flex;flex-wrap: wrap;">
                             <div style="margin-left:10px" v-for="($item,key) in  item">
                                 
                                  {{card.res.dict[key]}}：
                                  {{card.res.dict[$item]||$item}}
                             </div>
                             </div>
                              </template>
                          </div>
                    </div >

                     <div style="    float: right;" slot="num" >
                        <van-icon name="delete" size="2em" @click.stop.prevent="deleteCard(card.index)" />
                    </div >
                    </div>
                </van-card >
               
                <!-- <div class="" style="text-align:center;margin-top:20px;" >
                    总价：￥{{ total_price }}
                </div > -->
                 <div style="height:50px;width:100%"></div>
            </van-tab >
            <van-tab style="    padding-bottom: 55px;"  title="面料购物车" name="mianliao" v-if="$store.getters.shop.config.enable_product_store&&!$store.getters.confirmorderid">
                <CreateFabricOrder
                        v-bind:show.sync="isShowCartInfoPop2"
                        v-bind:cart_info="cart_info"
                        @hideCartInfoPopup2="hideCartInfoPopup2"
                        v-bind:design="{}"
                >
                </CreateFabricOrder >
                <van-card
                        v-for="card in cards"
                        :key="card.index"
                        @click.prevent="showCartInfoPopup2(card)"
                >
                    <div slot="title" style="margin:10px 6px 0 0;font-size:14px;" >
                        <!-- <span style="float:right" >{{ price_ratio(card.total_price_label) }}</span > -->
                        <div style="clear:both;" ></div >
                    </div >
                    <div slot="tags" >
                        <!-- 客户：{{ card.customer }} -->
                         <div :key="item" v-for="(item) in card.pos_items.qita">
                           
                          
                            <van-card
                           
                             :num="(item.quantity||0)+item.unit_label"
                            :price="price_ratio2(item.price,card.price_ratio)+'/'+item.unit_label"
                             class="van-hairline--bottom"
                            :title="item.product_code"
                             :thumb="'/storage/'+item.attach"
                            />
                         
                         </div>
                    </div >
                    <!-- <div slot="price" >备注：{{ card.comment }}</div > -->
                    <div slot="num" >
                        <van-icon name="delete" size="2em" @click.stop.prevent="deleteCard(card.index)" />
                    </div >
                </van-card >
              
                <!-- <div v-if="cards.length"    class="" style="text-align:center;margin-top:20px;" >
                    总价：￥{{ price_ratio(total_price) }}
                </div > -->
               
            </van-tab >
        </van-tabs >
        <!-- <van-button
                v-if="cards.length"
                plain
                type="primary"
                size="large"
                style="width:90%;margin-top:20px;margin-left:5%;"
                @click="showPopup()"
        >提交
        </van-button > -->
        <van-submit-bar   v-if="cards.length" style="bottom:50px;box-sizing:border-box" :price="price_ratio(total_price*100,2)" :button-text="$store.getters.confirmorderid?'继续修改':'提交订单'" @submit="showPopup">
              <van-button v-if="$store.getters.confirmorderid" round size="small" @click="gotoNormalMode" plain type="info">回到正常下单</van-button>
        </van-submit-bar>
          <div class="no-bar"  v-else>
         <van-submit-bar  button-color="#fff" style="bottom:50px;box-sizing:border-box"  button-text="" >
              <van-button v-if="$store.getters.confirmorderid" round size="small" @click="gotoNormalMode" plain type="info">回到正常下单</van-button>
        </van-submit-bar>
        </div>
         <!-- <van-popup position="bottom" :style="{ 'height': '80vh',width:'100%','z-index':'200000!important' }" v-model="showAddress">
             <AddressList />
         </van-popup> -->
        <van-popup v-model="show" position="bottom" :style="{ 'height': '480px' }" >
            <van-cell-group>
                 <div @click="gotoaddress" v-if="address.name" class="">
                  <div class="van-address-item">
                      <div class="van-cell van-cell--borderless"><div class="van-cell__value van-cell__value--alone van-address-item__value">
                      <div role="radio" tabindex="-1" aria-checked="false" class="van-radio"><span class="van-radio__label"><div class="van-address-item__name">{{address.name}} {{address.phone}}</div><div class="van-address-item__address">{{address.street}}</div></span></div></div><i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i></div></div>
                </div>
                <div @click="gotoaddress" style="padding: 20px 10px;justify-content: space-between;display: flex;" v-else>
                    选择一个地址<i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
                </div>
            </van-cell-group>
            <van-cell-group >
               
              
             
                <div style="margin-top:8px;padding-left:10px;" >订单备注（厂家可见）</div >
                <van-field v-model="comment" placeholder="订单备注" />
                 <div style="margin-top:8px;padding-left:10px;" >补充凭证（最多上传3张）</div >
            <div class="aaa" style="padding-left:10px;">
              <template>
                <van-uploader
                  v-model="vouchers"
                  result-type="file"
                  :after-read="afterRead"
                  :max-count="3"
                />
              </template>
             
            </div>
            </van-cell-group >
            <van-button
                    style="position:fixed;bottom:0px;"
                    type="primary"
                    size="large"
                    :disabled="!address.name||!address.street||!address.phone||!isPhone"
                    @click="postCard()"
            >提交审核
            </van-button >
        </van-popup >
        <van-empty v-if="!cardslength&&!loading" >没有商品</van-empty >
        <Footer :count="cards.length||cardslength"></Footer >
    </div >
</template >

<script >
    import Footer from "@/components/Footer.vue";
    import CreateOrder from "@/components/CreateOrder.vue";
    import CreateFabricOrder from '@/components/CreateFabricOrder.vue'
   import CreateOrderOther from '@/components/CreateOrderOther.vue'
    import { areaList } from '@/area';
    export default {
        name: "Cart",
        components: {CreateOrder, CreateFabricOrder, Footer,CreateOrderOther},
        data() {
            return {
                showAddress:false,
                address:{},
                isPhone:true,
                vouchers:[],
                phone:"",
                street:"",
                cardslength:0,
                isShowOtherCartInfoPop:false,
                loading:true,
                customer:"",
                name:"",
                design:{},
                cards: [],
                activeHeader: 'chuanglian',
                show: false,
                isShowCartInfoPop: false,
                isShowCartInfoPop2: false,
                cart_info: {},
                total_price: 0,
                comment: ""
            };
        },
       computed:{
        isShowCartInfoPop2A(){
           return this.isShowCartInfoPop2==true&&this.activeHeader=='mianliao';
        },
          isUploadError(){
             return false
          }
       },
        watch: {
            isShowCartInfoPop(newVal, oldVal) {
                newVal === false && this.getList();
            },
            isShowCartInfoPop2(newVal, oldVal) {
                newVal === false && this.getList();
            },
             isShowOtherCartInfoPop(newVal, oldVal) {
                newVal === false && this.getList();
            }
            
        },
        mounted() {
                 
           
             this.cardslength = parseInt(localStorage.getItem('cartcount')||0);
            let params = new URLSearchParams(window.location.href);
            let isType =params.get("activeTab");
            if(isType){
                this.activeHeader ="mianliao";
                this.$router.replace('/cart')
            }
             this.getList();
            if(sessionStorage.getItem("rightOpenModal")=='true'){
                  this.address =JSON.parse(sessionStorage.getItem("selectedAddress"));
               this.showPopup(false);
             
               sessionStorage.setItem("rightOpenModal",undefined)
               sessionStorage.setItem("selectedAddress",undefined)
            }
         
             
        },
        
        methods: {
             price_ratio2(v='',price_ratio){
                if(v&&v!=0&&v!=''){
                    v=v+''
                    v = parseFloat(v.replace("¥",""))
                   v = Number(v* (price_ratio||1)).toFixed(2)
                   if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='round'){
       v = Math.round(v)
      
    }
    if(this.$store.getters.shop.config.smart_curtain_sale_price_odd=='floor'){
        v = Math.floor(v)
         
    }
        return  v; 
    
               
                }else if(v==0){
                    return 0
                }else if(v==''||v==null){
                    return ''
                }
            
            },
            gotoaddress(){
               // this.showAddress = true;
             this.$router.replace('/user/addresslist?from=cart')
            },
            getMyAddress(){
            this.$http.get("/clients/address").then((res)=>{
           res = res||[];
           const {province_list,city_list,county_list} = areaList;
           res =  res.map((item)=>{
               const {province_code,city_code,district_code} = item;
               const province_str =  province_list[province_code]||'';
               const city_str =  city_list[city_code]||'';
                const country_str =  county_list[city_code]||'';
               item ={
                   ...item,
                   street:province_str+''+city_str+''+country_str+''+item.street,
                   tel:item.phone,
                   name:item.name,
                   isDefault:item.default,
                   id:item.id,
                   default:item.default,
                   district_code:item.district_code,
               }
            return item;
           }).filter((item)=>item.default)
           this.address = res[0];
        })
        },
    isphoneAction(){
     this.phone =this.phone||'';
      let regx =/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
     this.isPhone = regx.test(this.address.phone);
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      //new 一个FormData格式的参数
      let params = new FormData();
      params.append("file", file.file);
      let config = {
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data",
        },
      };
      //把 uploadUrl 换成自己的 上传路径
      this.$http
        .post("/clients/orders/image", params, config)
        .then((res) => {
          
          if (res) {
           
            this.vouchers = this.vouchers.filter((item)=>item.url)
            this.vouchers = this.vouchers.concat([
              {
                url: res,
                status: "done",
                message: "上传完成",
              },
            ]);
           
          } else {
            //否则 Toast 提示
            this.$toast.fail("上传失败");
          }
        })
        .catch((err) => {
          this.$toast.fail("系统异常");
        });

    },
            getList() {
             
                this.cards =[];
                 this.loading =true;
                 let orderid= this.$route.query.orderid || this.$store.getters.confirmorderid;
                 let url ="/clients/carts?order_type=" +this.getType()
                 url=!orderid||orderid==undefined||orderid=='undefined'?url:url+"&order_id="+orderid
               return  this.$http.get(url).then(res => {
                    this.loading =false
                    this.cards = res;
                    var all_total_price = 0.00;
                   let that = this;
                   this.cards =  this.cards.map(function (item1, index) {
                        item1.dict =item1.dict||{};
                        item1.un_checked_pos =   item1.un_checked_pos||[]
                        all_total_price =parseFloat(all_total_price)+parseFloat(that.price_ratio2.call(that,item1.total_price,item1.price_ratio));
                      return item1
                    });
                    this.cardslength  = this.cards.length;
                    if(this.cardslength==0){
                   //  sessionStorage.removeItem("confirmorderid");
                     //this.$store.getters.confirmorderid = undefined;
                    }
                    localStorage.setItem('cartcount',this.cards.length)
                    this.total_price = parseFloat(all_total_price);
                });
            },
            getType() {
              
                let type = 'design_order'
                if (this.activeHeader == 'mianliao') {
                    type = 'odd_order'
                }
                return type
            },
            showPopup(addressload=true) {
              
                 let orderid=  this.$store.getters.confirmorderid 
                const isMianliao = this.activeHeader =='mianliao';
                sessionStorage.setItem("isMianliao",isMianliao||false);
                this.$router.replace("./confirmorder?order_id="+orderid+'&isMianliao='+isMianliao);
                return 
                    addressload&& this.getMyAddress();
                this.show = true;
                let user = JSON.parse(localStorage.getItem("user")||'{}')
                 this.phone = user.client.mobile;
                 this.street = user.client.address;
                 this.name = user.client.person;
            },
            hideCartInfoPopup() {
           
                this.isShowCartInfoPop = false;
                  this.isShowOtherCartInfoPop = false;
                 
            },
            showCartInfoPopup(cart) {
               
              
                this.cart_info = cart;
                if(cart.design_type){
                 this.isShowOtherCartInfoPop = true;
                }else{
                  this.isShowCartInfoPop = true;
                }
              
            },
            hideCartInfoPopup2() {
                this.isShowCartInfoPop2 = false;
            },
            showCartInfoPopup2(cart,design) {
               
                this.cart_info = cart;
              //  this.design = design;
                this.isShowCartInfoPop2 = true;
            },
            postCard() {
                let that = this;
                let urls = this.vouchers.map((item)=>{
                     let url ='/storage'+ item.url.split("storage")[1];
                     return url;
                    }).join(',')
                     let user = JSON.parse(localStorage.getItem("user")||'{}')
                        user.client = user.client||{};
                        let brand =  user.client.brand;
                        this.$http
                            .post("/clients/orders/carts?order_type=" + this.getType(), {source:"zhilian",brand,status:'draft',comment: this.comment,name:this.address.name,street:this.address.street,phone:this.address.phone,vouchers:urls})
                            .then(res => {
                            let queryShopId = localStorage.getItem("queryShopId")
                                if(this.total_price>0&&(queryShopId=='210'||queryShopId=='1')){
                                let token =  localStorage.getItem("token")
                                wx&& wx.miniProgram&&  wx.miniProgram.navigateTo({url: '/pages/pay?id='+res.id+'&token='+token})
                                }
                                this.deleteLocationIndex();
                            
                                this.show = false;
                                this.getList().then(()=>{
                                    this.$toast.success("订单提交成功，等待审核")
                                });
                            
                                

                            });
                  
                    
            },
            deleteLocationIndex() {
             
                // if (this.getType() === 'odd_order') {
                localStorage.removeItem('product_index');
                // }
            },
            gotoNormalMode(){
               this.$store.getters.confirmorderid = '';
               sessionStorage.setItem("confirmorderid","")
               this.$router.replace("./cart");
               this.getList();
            },
            deleteCard(id) {
                let _this = this;
                this.$dialog
                    .confirm({
                        title: "确认删除该窗帘？"
                    })
                    .then(() => {
                        _this.$http.delete("/clients/cart/" + id).then(res => {
                            _this.deleteLocationIndex();
                            _this.getList();
                        });
                    })
                    .catch(() => {
                        // on cancel
                    });
                return false;
            }
        }
    };
</script >

<style >
   .cart .van-tabs--line .van-tabs__wrap {
    height: 44px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    left: 0;
}
 .cart .van-tabs__content{
        padding-top: 44px;
}
.one-tab .van-tabs__wrap{
  height: 0!important;
}
.one-tab .van-tabs__content{
    padding-top:0 ;
}
.cart .van-uploader__preview-image{
    max-width: 80px!important;
        min-width: 60px;
}
.van-submit-bar__bar{
    height: 54px;
}
.no-bar .van-submit-bar__bar{
  justify-content: flex-start;
}

@media (-webkit-min-device-pixel-ratio: 3),(min-device-pixel-ratio: 3)
{
  .van-submit-bar {
    position: fixed;
    bottom:calc(50px + env(safe-area-inset-bottom))!important;
    box-sizing: border-box;
    padding-bottom: 0;
  }
}


</style >
