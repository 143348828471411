<template >
    <div :class="selectMode?'addresslist select-mode':'addresslist'" >
            <van-nav-bar title="地址列表"  right-text="新建地址"   @click-right="onAdd" left-text="返回" @click-left="onClickLeft" />
      <van-address-list   
        v-model="chosenAddressId"
        :list="list"
        @select="selectItem"
        :disabled-list="disabledList"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        >
        <slot v-if="selectMode" item-bottom>
            <van-button    :disabled="!chosenAddressId" @click="gotocart" style="    margin-top: 30px;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;" block type="primary">确定</van-button>
        </slot>
      </van-address-list>
    </div >
</template >

<script >
    import Footer from "@/components/Footer.vue";
    import { AddressList} from 'vant';
import { areaList } from '@/area';
    export default {
        name: "Bookmark",
        components: {Footer,"van-address-list":AddressList},
        data() {
            return {
                chosenAddressId:"",
                designs: [],
                selectMode:false,
                disabledList:[],
                 list: [
      ],
            };
        },
        mounted() {
            if(window.location.href.includes("from=cart")){
                  this.selectMode = true
            }
           this.$http
        .get("/clients/address").then((res)=>{
           //console.log(res);
           res = res||[];
           const {province_list,city_list,county_list} = areaList;
           this.list = res.map((item)=>{
            const {province_code,city_code,district_code} = item;
            
               const province_str =  province_list[province_code]||'';
               const city_str =  city_list[city_code]||'';
                const country_str =  county_list[district_code]||'';
               item ={
                   street:item.street,
                   address:province_str+''+city_str+''+country_str+''+item.street,
                   tel:item.phone,
                   name:item.name,
                   isDefault:item.default,
                   id:item.id,
                   district_code,
               }
            return item;
           })
        })
        },
        methods: {
            gotocart(){
                debugger
                let address = this.list.find((item)=>item.id==this.chosenAddressId)||{}
                address =JSON.stringify({
                    name:address.name,
                    phone:address.tel,
                    street:address.address
                })
               sessionStorage.setItem("selectedAddress",address)
               sessionStorage.setItem("rightOpenModal",true)
              let id =  this.$store.getters.confirmorderid ||this.$route.query.orderid
              this.$store.getters.confirmorderid &&  this.$router.replace('/confirmorder?order_id='+id)
              this.$route.query.orderid && this.$router.replace('/confirmorder?orderid='+id)
            },
             onClickLeft(){
                if(this.selectMode){
                   let id =  this.$store.getters.confirmorderid||this.$route.query.order_id
                 this.$store.getters.confirmorderid && this.$router.replace('/confirmorder?order_id='+id)
                   this.$route.query.order_id && this.$router.replace('/confirmorder?orderid='+id)
                }else{
                  this.$router.replace('/user')
                }
              
            },
             selectItem(item,index){
              
                this.chosenAddressId = item.id;
            },
            onAdd(){
              this.$router.replace('/user/addressadd')
            },
            onEdit(item, index){
                this.$router.replace('./addressadd?item='+encodeURIComponent(JSON.stringify(item)));
            
            }
        }
    };
</script >

<style >
    .addresslist .van-address-list__bottom{
        display: none;
    }
  .addresslist  .van-radio__icon{
  display: none;
    }
    .select-mode .van-radio__icon{
  display: block;
    }
</style >
