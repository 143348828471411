<template>
  <div :class="show?'success-page show':'success-page'">
   <van-icon name="passed" />
   
    <span v-if="$route.query.isDraft">保存为临时单</span>
     <span v-else>下单成功</span>
   <div style="margin-top:50px">
   <van-button @click="gotoorder" style="margin-right:10px" square type="primary">查看{{$route.query.isDraft?'临时单':'订单'}}</van-button>
   <van-button plain square @click="gotohome" type="primary">回到首页</van-button>
   </div>
  </div>
</template>

<script>
export default {
  name: 'success',
 
  data() {
    return {
    show:false
    }
  },
  mounted() {
      setTimeout(()=>{
 this.show = true
      },300)
    
  },
  methods: {
    gotoorder(){
           if(!sessionStorage.getItem("isMianliao")||sessionStorage.getItem("isMianliao")=='false'){
                sessionStorage.removeItem('isMianliao')
                this.$router.replace('/user/order'+(this.$route.query.isDraft?'?status=draft':''))
           }else{
               sessionStorage.removeItem('isMianliao')
              this.$router.replace('/user/product_order'+(this.$route.query.isDraft?'?status=draft':''))
           }
             

    },
    gotohome(){
       sessionStorage.removeItem('isMianliao')
      this.$router.replace('/shop/'+localStorage.queryShopId)
    }
  }
}
</script>
<style>
.success-page{
        text-align: center;
    color: #07c160;
    transform:scale(0);
        transition: all .3s ease;
        margin-top:200px ;

}
.success-page.show{
     transform:scale(1);
}
.success-page .van-icon{
        display: block;
    font-size: 70px;
    color: #07c160;
    margin-bottom: 10px;
}
</style>
