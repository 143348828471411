<template >
    <div class="login-body" >
        <!-- <span class="welcome" >欢迎回来</span > -->
        <div class="shop">
        <img :src="shop_logo" />
        <!-- <div class="name">{{shop_name}}</div> -->
      </div>
        <van-row justify="space-around" type="flex" >
            <van-cell-group :border="false" class="login" size="large" >
                <van-field
                        clearable
                        size="large"
                        left-icon="phone-o"
                        placeholder="请输入手机号"
                        type="tel"
                        required
                        v-model="username"
                ></van-field >
                <!-- <van-field
                  clearable
                  left-icon="lock"
                  placeholder="请输入密码"
                  required
                  type="password"
                  v-model="password"
                ></van-field>-->
                <van-field
                        v-model="code"
                        center
                        size="large"
                        clearable
                        required
                        left-icon="envelop-o"
                        placeholder="请输入短信验证码"
                >
                    <van-button
                            slot="button"
                            @click="sendSMS()"
                            :disabled="is_disabled"
                            type="primary"
                            :text="title"
                    ></van-button >
                </van-field >
                <van-cell :border="false" >
                    <van-button to="/" size="large" style="width: 45%;float:left" type="default" >取消</van-button >
                    <van-button @click="login" size="large" style="width: 45%;float:right" type="primary" >登录</van-button >
                </van-cell >
                <van-cell
                        v-if="$store.getters.shop.phone"
                        :url="'tel:'+$store.getters.shop.phone"
                        value="联系我们"
                        class="contact-button"
                />
            </van-cell-group >
        </van-row >
        <div class="contact-tel" v-if="$store.getters.shop.config.sender_phone">
            厂方联系电话：<a @click="callPhone" style=" color: #2c3e50;" >{{$store.getters.shop.config.sender_phone}}</a>
        </div>
        <div class="bottom-text"  >
            <p >随手e裁技术支持</p >
        </div >
        <!-- <van-divider class="bottom-text">随手科技技术支持</van-divider> -->
    </div >
</template >

<script >
    export default {
        name: "Login",
        data() {
            return {
                sender_phone:"",
                shop_name:"",
                shop_logo:"",
                title: "发送验证码",
                is_disabled: false,
                is_setInterval: false,
                username: "",
                code: "",
                password: ""
            };
        },
        mounted(){
             let shopstr =   sessionStorage.getItem("shop")||'{}';
             const setShop =()=>{
                
                   shopstr =   sessionStorage.getItem("shop")||'{}';
                  let shop = JSON.parse(shopstr)||{};
                    this.shop_name = shop.name;
                    shop.config = shop.config||{};
                    this.shop_logo =   shop.config.logo;
                   
             }
             if(shopstr!='{}'){
                 setShop();
             }else{
                this.$store.dispatch("GetShop").then(()=>{
                    
                 setShop();
             })
             }
             
         
        
        },
        methods: {
            callPhone(){
               let sender_phone = this.$store.getters.shop.config.sender_phone
                wx.miniProgram.getEnv(function(res) { 
                   if(res.miniprogram){
                      wx.makePhoneCall({
                        phoneNumber:sender_phone
                      })
                   }else{
                    window.location.href="tel:"+sender_phone
                   }
                 })
            },
            sendSMS() {
                if (!this.username) {
                    this.$toast("请输入手机号");
                } else if (this.username.length !== 11 || !this.username.startsWith(1)) {
                    this.$toast("手机号码格式不正确");
                } else {
                    this.$http.post("/user/sendcode", {mobile: this.username}).then(_ => {
                        this.is_disabled = true;
                        if (this.is_setInterval) {
                            clearInterval(this.is_setInterval);
                        }
                        this.code_count();
                    });
                }
            },
            code_count() {
                let _this = this;
                let i = 60;
                this.is_setInterval = setInterval(function () {
                    if (i > 0) {
                        _this.title = "(" + i + ")秒后重试";
                        i--;
                    } else {
                        _this.is_disabled = false;
                        _this.title = "发送验证码";
                        clearInterval(_this.is_setInterval);
                    }
                }, 1000);
            },
            clearCache() {
                sessionStorage.clear();
                localStorage.clear();
                this.$toast("缓存清空完成");
            },
            login() {
                if (!this.username) {
                    this.$toast("请输入手机号");
                }
                if (!this.code) {
                    this.$toast("请输入验证码");
                }
                var openid = sessionStorage.getItem("openid");
                
                this.$store.dispatch("Login", {
                    mobile: this.username,
                    code: this.code,
                    openid,
                    shop_id: this.$store.getters.shop_id,
                    from_user_id: this.$store.getters.from_id
                });
            }
        }
    };
</script >
<style >
    .login-body {
        margin: 0.3rem;
        padding: 0.3rem;
    }

    .welcome {
        margin-left: 1rem;
        font-size: 2rem;
    }

    .bottom-text p{
        color: #c1c2c3;text-align:center;margin-bottom:30px;font-size:12px;
            position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    }

    .login {
        width: 100%;
        border-radius: 0.15rem;
    }

    .contact-button {
        float: right;
        width: auto;
    }
    .shop{
          font-size: 20px;
          font-family: emoji;
          margin-top: 100px;
          letter-spacing: 8px;
              margin-bottom: 50px;
    }
    .shop .name{
        text-align: center;
    }
    .shop img{
            width: 150px;
            margin: 0 auto;
            display: block;
    }
    .contact-tel{
            text-align: center;
    margin-top: 20px;
    font-size: 14px;
    }
</style >
