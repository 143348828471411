<template >
    <div class="customer" >
        <!-- <van-nav-bar
          title="客户管理"
          right-text="新增客户"
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
        />-->
        <van-cell-group >
            <van-cell >
                <div slot="title" >1.小张</div >
                <div slot="label" >这是小张的个人信息</div >
                <div slot="default" >
                    <van-button type="primary" size="small" >查看</van-button >
                </div >
            </van-cell >
            <van-cell >
                <div slot="title" >2.小王</div >
                <div slot="label" >这是小王的个人信息</div >
                <div slot="default" >
                    <van-button type="primary" size="small" >查看</van-button >
                </div >
            </van-cell >
        </van-cell-group >
    </div >
</template >

<script >
    import Footer from "@/components/Footer.vue";

    export default {
        name: "customer",
        components: {Footer},
        data() {
            return {};
        },
        methods: {}
    };
</script >

<style >
</style >
