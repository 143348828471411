<template >
   <div>
    <div style="    padding: 10px;
    border-bottom: 1px solid #f7f7f7;"><van-icon name="arrow-left" @click="back" /></div>
    <div style="    padding: 10px 10px">共有个{{count}}设备,其中{{normalcount}}个在线 {{offlinecount>0?','+offlinecount+'个离线':''}}</div>
    <div v-show="mode=='list'" class="list" >
        <div class="item" :key="index" v-for="item,index in devices">
       <van-image
      
            width="100"
             lazy-load
         
            :src="item.screenshot_url"
            >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <van-icon v-if="item.active_status==1" @click="playVideo(item)" name="play-circle" />
       </van-image>
       <div>{{item.ipc_name}} <span :class="item.active_status==1?'normal':'gray'">{{item.active_status==1?'在线':'离线'}}</span></div>
        </div>
    </div >
    <div  v-show="mode!='list'">
    <video id="myVideo" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" data-setup='{}' style='width:100%'></video>
    </div>
    <div v-if="mode!='list'" @click="closeVideo" class="cancel-btn">退出</div>
</div >
</template >

<script >
    var failCount =0;
   function loadJS( url, callback ){

    var script = document.createElement('script'),

        fn = callback || function(){};
    
    script.type = 'text/javascript';

    

    //IE

    if(script.readyState){

        script.onreadystatechange = function(){

            if( script.readyState == 'loaded' || script.readyState == 'complete' ){

                script.onreadystatechange = null;

                fn();

            }

        };

    }else{

        //其他浏览器

        script.onload = function(){

            fn();

        };

    }

    script.src = url;

    document.getElementsByTagName('head')[0].appendChild(script);

}
function loadStyles(url) {
        var link = document.createElement("link");
          link.type = "text/css";
          link.rel = "stylesheet";
          link.href = url;
          document.getElementsByTagName("head")[0].appendChild(link);
               }
    export default {
        name: "livelist",
        data() {
            return {
                normalcount:0,
                offlinecount:0,
                count:0,
                mode:"list",
                devices: [],
                src:"",
            };
        },
        mounted() {
           
            document.title =this.$route.query.title
            this.getDevices();
             loadStyles("https://vjs.zencdn.net/7.6.6/video-js.css");

            loadJS('https://vjs.zencdn.net/7.6.6/video.js',function(){
    
   

       });
        },
        methods: {
            back(){
            window.history.back()
            },
            closeVideo(){
                this.mode ='list';
             
              
                let playervideo = videojs('myVideo');
                playervideo.pause();
            },
            playVideo(item){
              this.getVideoSrc(item.ipc_id)

            },
            getVideoSrc(ipc_id) {
                 this.mode ='video';
                this.$http.get("/clients/liveStart?ipc_id="+ipc_id).then(res => {
                   // this.src = res.data;
                   debugger;
                   let playervideo =videojs('myVideo');
                    var myVideo = playervideo.ready(function(){
                    failCount = 0
                   var player = playervideo;
                    player.src([{src: res, type: "application/x-mpegURL"}]);
                            //player.hlsQualitySelector()
                            player.volume(0.5)
                           player.play()
                            player.tech_.on("retryplaylist",function(){
                                if (failCount>=5){
                                    window.location.reload()
                                }else{
                                    failCount++
                                    console.log(failCount)
                                }
                            })
                });
                });
            },
             getDevices() {
                this.$http.get("/clients/getIpc").then(res => {
                   let normalcount = res.data.ipc_list.filter((item)=>item.active_status==1).length;
                   let offlinecount = res.data.total_count - normalcount;
                   this.offlinecount =offlinecount;
                   this.normalcount = normalcount;
                    this.devices = res.data.ipc_list;
                    this.count = res.data.total_count||0;
                });
            }
        }
    };
</script >

<style >
  .list{
      display: flex;
      flex-wrap: wrap;
     
  }
  .list .item  {
          flex:0 0 calc(50% - 15px);
              padding: 10px 0;
    box-sizing: border-box;
    height: calc(50vw - 15px );
        font-size: 14px;
            margin-bottom: 20px;
      }
      .list .van-image{
              height: 100%;
      }
      .list  .item:nth-child(2n-1){
              margin: 0 10px;
      }
      .list .item i{
            font-size: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 100%;
    opacity: .9;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
      }
      .cancel-btn{
              position: fixed;
    bottom: 50px;
    z-index: 1000000;
    color: #333;
    text-align: center;
  
    background: #ddd;
    margin: 0 auto;
    display: block;
    padding: 10px 20px;
    border-radius: 20px;
    transform: translate(-50%,-50%);
    left: 50%;

      }
      .myVideo-dimensions{
          height: 100vh!important;
      }
      .item .normal{
          color: #07c160;
              float: right;
      }
      .item .gray{
          color: #ddd;
              float: right;
      }
</style>
