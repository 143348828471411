<template >
  <div class="addresslist">
    <van-nav-bar :title="addr_info.id?'地址修改':'新增地址'" left-text="返回" @click-left="onClickLeft" />
    <van-address-edit
      :area-list="areaList"
      :show-delete="addr_info.id"
      :address-info="addr_info"
      show-set-default
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @change-detail="onChangeDetail"
    />
  </div>
</template >

<script >
    import Footer from "@/components/Footer.vue";
    import { AddressEdit,Toast} from 'vant';
import { areaList } from '@/area';

    export default {
        name: "Bookmark",
        components: {Footer,"van-address-edit":AddressEdit},
        data() {
            return {
                searchResult:[],
                areaList,
                addr_info:{
                    id:""
                },
                item:{}
            }
        },
        mounted() {
           let addr_info  = JSON.parse(decodeURIComponent(window.location.hash).split("item=")[1].split("&")[0]||'{}');
           this.addr_info = addr_info;
           this.addr_info.addressDetail = addr_info.street||addr_info.address;
           this.addr_info.tel = addr_info.tel||addr_info.phone;
           this.addr_info.isDefault = addr_info.default||addr_info.isDefault;
           this.addr_info.areaCode = (addr_info.district_code||addr_info.areaCode)+'';
        },
        methods: {
           
            onClickLeft(){
                this.$router.replace('./addresslist')
            },
            onSave(content) {
               
               this.$http
        .post("/clients/address/update",{
            id:this.addr_info.id,
            default:content.isDefault,
            name:content.name,
            phone:content.tel,
            province_code:content.areaCode.substr(0,2)+'0000',
            city_code:content.areaCode.substr(0,4)+'00',
            district_code:content.areaCode,
            street:content.addressDetail
        }).then(()=>{
             Toast('保存成功');
            this.$router.replace('./addresslist')
        })
            },
            onDelete() {
                   this.$http
        .delete("/clients/address/"+this.addr_info.id+'/del').then(()=>{
             Toast('删除成功！');
              this.$router.replace('./addresslist')
        })
           

            },
            onChangeDetail(val) {
            if (val) {
                this.searchResult = [
                {
                    name: '黄龙万科中心',
                    address: '杭州市西湖区',
                },
                ];
            } else {
                this.searchResult = [];
            }
            },
        }
    };
</script >

<style >
</style >
