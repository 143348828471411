<template>
  <div >
    <van-image v-if="series['img']['large']" :src="series['img']['large']" fit="contain" lazy-load>
      <template v-slot:loading>
        <van-loading type="spinner" size="20"/>
      </template>
      <template v-slot:error>加载失败</template>
    </van-image>
    <div class="btn-list">
      <div class="show-btn" v-if="series['video']" @click="showVideo">视频</div>
      <div class="show-btn" v-if="series['gallery'] && series['gallery']['large'] && series['gallery']['large'].length" @click="showGallery">相册</div>
      <div class="show-btn" v-if="series['design_url']" @click="tagTo(series['design_url'])">换装</div>
      <div class="show-btn" v-if="series['realshow_url']" @click="tagTo(series['realshow_url'])">试挂</div>
      <div class="show-btn" v-if="series['3d_url']" @click="tagTo(series['3d_url'])">3D</div>
      <div class="show-btn" v-if="series['goods_url']" @click="tagTo(series['goods_url'])">详情</div>
    </div>
    <van-divider/>
    <div v-if="series['desc']" class="desc-contain">
      <div v-html="series['desc']"></div>
    </div>
    <van-popup v-model="isShowVideo" closeable lazy-render close-on-popstate :style="{ width: '100%' }" >
      <video v-if="isShowVideo" controls autoplay disablePictureInPicture width="100%">
        <source :src="series.video" type="video/mp4">
        <p>浏览器不支持此视频格式</p>
      </video>
    </van-popup>
    <van-image-preview closeable v-if="series['gallery'] && series['gallery']['large'] && series['gallery']['large'].length" v-model="isShowGallery"
                       :images="series['gallery']['large']"/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Series',
  components: {Footer},
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      isShowVideo: false,
      isShowGallery: false,
      series: '',
      series_id: 0,
      current_series: {},
    }
  },
  mounted() {
    this.series_id = this.$route.params.series_id
    this.getSeries()
  },
  methods: {
    getSeries() {
      this.$http.get('/clients/series/' + this.series_id).then(res => {
        console.log(res);
        this.series = res;
      })
    },
    tagTo(url) {
      if (!url || url.length === 0 || url === undefined || url === 'undefined') {
        return false
      }
      if (url.startsWith('http')) {
        window.location.href = url
      } else {
        this.$router.push(url)
      }
    },
    showVideo() {
      this.isShowVideo = true;
    },
    showGallery() {
      this.isShowGallery = true;
    },
  }
}
</script>
<style>
.btn-list {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 0.5rem auto;
}

.show-btn {
  display: flex;
  text-shadow: black 0.1em 0.1em 0.2em
}

.desc-contain img {
  height: auto !important;
  width: 100% !important;
}
</style>
