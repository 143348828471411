<!-- @format -->

<template >
    <div :class="statusKey=='draft'?'user-order draft':'user-order'" >
         <van-sticky>
        <div class="order-top" >
            <!-- <van-form @submit="onSubmit"> -->
            <!-- <van-field
        v-model="customer"
        name="name"
        placeholder="请输入客户标识"
        style="width:42%;float:left;"
      /> -->
     
            <van-field readonly clickable name="datetimePicker" :value="startValue" placeholder="开始时间" @click="showPicker = true" style="flex:120px" />
            <van-popup v-model="showPicker" position="bottom" >
                <van-datetime-picker v-model="startTimeValue" type="date" @confirm="confirmTime('start')" @cancel="showPicker = false" />
            </van-popup >
            <van-field readonly clickable name="datetimePickerEnd" :value="endValue" placeholder="结束时间" @click="showPickerEnd = true" style="flex:120px" />
            <van-popup v-model="showPickerEnd" position="bottom" >
                <van-datetime-picker v-model="endTimeValue" type="date" @confirm="confirmTime('end')" @cancel="showPickerEnd = false" />
            </van-popup >
            
            <!-- <van-field readonly clickable name="datetimePickerEnd" :value="status" placeholder="订单状态" @click="showPickerStatus = true" style="width:24%;float:left;" />
            <van-popup v-model="showPickerStatus" position="bottom" >
                <van-picker v-model="statusModel" show-toolbar overlay="false" :columns="statusArr" @confirm="confirmStatus" @cancel="showPickerStatus = false" />
            </van-popup > -->
            <div style="    padding-top: 0.5rem;  width: 60px;float: left;margin-right: 10px;" >
                <van-button block size="small" type="default" native-type="submit" @click="search" >
                    搜索
                </van-button >
            </div >
            <!-- </van-form> -->
            <div style="clear:both;" ></div >
        </div >
         </van-sticky>
       
<van-tabs  v-model="activeIndex" @click="onClick" offset-top="44px" sticky>
  <van-tab :key="item.key"   v-for="item in statusArr" :title="item.text" >
        <div class="order-list" >
            <van-empty description="没有数据" v-if="orders.length==0&&!loading" />
           
            <van-list v-model="loading" :finished="finished" :finished-text="orders.length>0?'没有更多了':''" error-text="请求失败，点击重新加载" @load="getOrderList" >
                <van-cell-group v-for="order in orders"  :key="order.id" class="order-list-group order-border"  >
                           
                    <van-cell title-style="flex:2"	 class="order-border" style="padding:10px 4px;" >
                        <div slot="title" >**{{ order.order_no.substr(-6) }}.共 {{ order.group.length }} 套<span style="color: rgb(238, 10, 36);margin-left:10px">{{ is_factory_price?order.total_price_label :order.sale_total_price_label }}</span> <br />{{ order.comment }}</div >
                        <div slot="default" ><van-tag  :type="order.payment_status_label=='已支付'?'success':'danger'" style="margin-right:5px">{{order.payment_status_label}}</van-tag><div @click="gotodetail(order.mobile_url)" class="btn-detail">{{ order.status_label }}<van-icon name="arrow" /></div> <div>{{ order.created_at | formatTimer }}</div></div >
                        
                    </van-cell >
                     <van-cell title-style="flex:2"	 class="order-border" style="padding:10px 4px;" >
                  <div style="font-size:14px">
                       <div v-if="order.project_name">业主名称：{{order.project_name}}</div>
                        <div v-if="order.community_name">电话地址：{{order.community_name}}</div>
                        <div v-if="order.delivery_date"> 交付日期：{{order.delivery_date}}</div>
                     
                  </div>
                  </van-cell >
                  <div style="    position: relative;padding: 5px;padding-left:0px
    margin: 0;" class="van-address-item van-contact-card">
                      <div class="van-cell van-cell--borderless"><div class="van-cell__value van-cell__value--alone van-address-item__value">
                      <div role="radio" tabindex="-1" aria-checked="false" class="van-radio"><span style="margin-left:0" class="van-radio__label"><div class="van-address-item__name">{{order.name}} {{order.phone||''}}</div><div class="van-address-item__address">{{order.street}}</div></span></div></div>
                      </div></div>
                  <div>
                    <van-card v-for="group in order.group.slice(0,order.openedAll?order.group.length:3)" :key="group.id" style="margin-top:0px;" class="goods-card order-border" :thumb="group.design_url" >
                   
                        <div slot="desc" style="margin-left:1rem;" >
                            <div v-if="order.customer">{{ order.customer }}</div>
                            <span  style="font-size:14px;font-weight:600">款式：{{ group.design_label }}</span> <span style="float:right;    color: #ee0a24;" >{{is_factory_price? group.total_price_label: group.sale_total_price_label }}</span >
                        </div >
                         <div v-if="group.other_info&&!group.other_info.postion_param" slot="desc" class="position-item" style="margin-left:1rem;display:flex;margin-top:10px" >
                            <div :class="key" v-if="key!='corner_left'&&key!='corner_right'" v-for="item,key in group.other_info">
                                <span v-if="key!='extend_checks_other'">{{item}}</span>
                                <div v-else>
                                      <van-tag :key="$item" v-for="$item in item" :text="$item" >{{$item}}</van-tag>
                                </div>
                            </div>
                        </div >
                         <div slot="desc" v-if="group.other_info&&!group.other_info.postion_param" style="margin-top:1.2rem;margin-left:1rem;" >
                            <div class='position-item' v-for="(item,key) in group.position_info">
                               <span style="margin-right:10px">{{positions[key]}}</span>
                                <div v-if="key=='liantou'&&(group.other_info.corner_left||group.other_info.corner_right)">
                                   角度:{{group.other_info.corner_left}} {{group.other_info.corner_right||''}}
                                </div>
                                <div v-if="key2!='size'&&key2!='quantity_label'&&key2!='quantity'&&key2!='quantity2'&&key2!='quantity_label2'"  class='' v-for="item2,key2 in item">
                                {{labelMap[key2]}} 
                                {{key2!='extend_checks'?item2:''}}
                                <div class="extend_checks_value" v-if="key2=='extend_checks'">
                                       <van-tag :key="$item" v-for="$item in item2" :text="$item" >{{$item}}</van-tag>
                                </div>
                              
                                <!-- <span v-if="key2!='extend_checks'">{{item2}} </span>
                                <div v-if="key2=='extend_checks'">
                                      <van-tag :key="$item" v-for="$item in item" :text="$item" >{{$item}}</van-tag>
                                </div> -->
                                
                                </div>
                           
                             
                            </div>
                        </div >
                        <div v-else slot="desc" style="margin-top:1.2rem;margin-left:1rem;">
                               <div class='position-item' v-for="(item,key) in group.other_info.postion_param">
                                   <template v-if="key!='dict'&&group.other_info.postion_param.dict&&key!='quantity'">
                                       <div>
                                   <div>{{group.other_info.postion_param.dict[key]}}</div>
                                   <div class='position-item'>
                                    <div  v-for="($item,key1) in item">
                                   {{group.other_info.postion_param.dict[key1]}}：{{group.other_info.postion_param.dict[$item]||$item}}
                               </div>
                                </div>
                                       </div>
                                   </template>
                               </div>
                        </div>
                         
                        <!-- <div slot="tags" style="margin-top:0.3rem;margin-left:1rem;" >尺寸：{{ group.window_width }} * {{ group.window_height }}</div > -->
                    </van-card >
                    <div @click="openOrClose(order.id)" v-if="order.group.length>3" style="    text-align: center;
    margin: 10px 0;">{{!order.openedAll?'展开全部':'收缩'}}<van-icon :name="!order.openedAll?'arrow-down':'arrow-up'" /></div>
    
                  </div>
                    <div v-if="order.vouchers.length>0" class="">
                         <div style="font-size:10px;margin-bottom:5px">补充凭证</div>
                         <img @click="openImgPreview(orgin+img)" style="width:50px;margin-right: 10px;" :src="orgin+img" v-for="img in order.vouchers" />
                    </div>
                    <div style="text-align: right;margin: 5px 0;font-size: 14px;" v-if="order.sale_discount||order.discount"> 
                        折扣：{{is_factory_price?order.discount:order.sale_discount}}
                    </div>
                    <div class="" style="text-align:right">
                          <!-- <van-button style="margin-right: 10px;" type="info"  size="small" class="copy-btn" :data-clipboard-text="order.mobile_url.replace('orders','draft')">
                        分享报价<van-icon style="color:yellow" name="description" />
                    </van-button> -->
                     <van-button @click="openShareModal(order.mobile_url.replace('orders','draft'))" plain type="primary" style="margin-right: 10px;" size="small">
                         分享报价<van-icon style="color:yellow" name="description" />
                     </van-button>
                       <van-button v-if="order.status=='draft'" @click="editOrder(order.id)" style="margin-right: 10px;"  type="info" size="small">编辑订单</van-button> 
                    
                       <van-button v-if="order.status=='drafted'&&$store.getters.shop.config.zhilian_draft" @click="updateOrderStatus(order.id,order.vouchers.length,'cancel')" style="margin-right: 10px;" plain type="info" size="small">取消提交</van-button> 
                        <van-button v-if="order.status=='created'&&$store.getters.shop.config.zhilian_draft==false" @click="updateOrderStatus(order.id,order.vouchers.length,'cancel')" style="margin-right: 10px;" plain type="info" size="small">取消提交</van-button> 

                      <van-button v-if="order.status=='draft'" @click="updateOrderStatus(order.id,order.vouchers.length)" style="margin-right: 10px;" plain type="info" size="small">提交订单</van-button> 
                     <van-button v-if="order.status=='draft'" @click="deleteOrder(order.id)" style="margin-right: 10px;"  type="default" size="small">删除订单</van-button> 
                      <van-button style="    margin-right: 10px;margin-top: 5px;" v-if="order.payment_status_label=='未支付'&&order.total_price>0&&wepay&&is_allow_toggle_price_checked&&$store.getters.user.auth.AllowTogglePrice" @click="gotopay(order.id,order.total_price)" type="primary" size="small">去支付</van-button>
                    </div>
                   
                </van-cell-group >
              

            </van-list >
            <van-share-sheet
            v-model="showShare"
            title="分享报价单"
            :options="options2"
            @select="onSelect"
            />
            <van-button type="primary" block to="/user" style="position: fixed;bottom: 0;left: 0;" >返回</van-button >
        </div >
        </van-tab>
</van-tabs>
        <!-- <Footer></Footer> -->
    </div >
</template >

<script >
    // import Footer from "@/components/Footer.vue";
    import { ImagePreview ,Dialog} from 'vant';
    import ClipboardJS from "../copy"
     let isHttps = false;
    export default {
        name: 'UserOrder',
        // components: { Footer },
        data() {
            return {
                wepay:false,
                ClientOrderPush:false,
                is_factory_price:false,
                showShare:false,
                orgin:window.location.origin,
                options:[],
                 options2: [
        { name: '微信查看', icon: 'wechat' },
        { name: '复制链接', icon: 'link',className:"copy-btn" }
      ],
                labelMap:{
                    price:"价格",
                      subtotal:"价格",
                     extend_checks:"",
                    "strap_dir":"方向:",
                    "comment":"备注:",
                    "fold_gap":"褶距:",
                    "craft":"加工方式:",
                    "open_style":"打开方式:",
                    "width":"宽度:",
                    "height":"高度:",
                },
                customer: '',
                startValue: '',
                startTimeValue: new Date(),
                showPicker: false,
                endValue: '',
                endTimeValue: new Date(),
                status: '',
                statusModel: '',
                statusKey: '',
                showPickerStatus: false,
                showPickerEnd: false,
                orders: [],
                order_status: [],
              
                page: 1,
              activeIndex:0,
                finished: false,
                loading: false,
                is_search: false,
                statusArr:[]
            }
        },
        computed: {
           is_allow_toggle_price_checked(){
             return  localStorage.getItem('allow_toggle_price_checked')=='true'
           }
          
        },

        mounted() {
            const user = JSON.parse(localStorage.getItem("user"));
            this.statusKey  = this.$route.query.status;
             wx.miniProgram.getEnv(function(res) { 
                isHttps =  res.miniprogram
                 })
           let is_factory_price = user.is_factory_price;
           let ClientOrderPush =  user.auth.ClientOrderPush
           this.is_factory_price = is_factory_price;
           this.ClientOrderPush = ClientOrderPush;
           this.wepay =  JSON.parse(sessionStorage.getItem("shop")).wepay||false;
           
             this.getClientsOptions();
         
        },
        filters: {
            formatTimer: function (value) {
                if (!value) {
                    return ''
                }
                let date = new Date(value.replace(/\-/g, '/'))
                let y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? '0' + MM : MM
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                let h = date.getHours()
                h = h < 10 ? '0' + h : h
                let m = date.getMinutes()
                m = m < 10 ? '0' + m : m
                let s = date.getSeconds()
                s = s < 10 ? '0' + s : s
                return y + '.' + MM + '.' + d
            }
        },
        methods: {
            editOrder(id){
           sessionStorage.removeItem('isMianliao')
         sessionStorage.setItem("confirmorderid",id) 
         this.$store.getters.confirmorderid = id;
           // this.$router.push({path: '/confirmorder?orderid=' + id})
         this.$router.push({path: '/cart?orderid=' + id})
            },
            updateOrderStatus(id,len,type='submit'){
                if(!this.ClientOrderPush){
                      Dialog.confirm({
            title: '提示',
            message: '无权限，请联系管理员',
            showCancelButton:false
            })
                    return;
                }
                if(len==0&&(localStorage.queryShopId=='224'||localStorage.queryShopId=='1')&&type=='submit'){
                      Dialog.confirm({
                    title: '提示',
                    message: '先补充凭证再提交',
                    showCancelButton:false
                    })
                            return;
                }
                 debugger
              Dialog.confirm({
            title: '提示',
            message: type=='submit'?'您确定要提交临时单吗':'您确定要取消提交吗',
            })
            .then(() => {
                
                // on confirm
               let   url =(type=='submit'?'/push':'/unpush');
               
                 this.$http.patch("/clients/orders/"+id+url).then((res) => {
                   
               type=='submit'&&  this.$router.replace("/successorder");
                if(type=='cancel'){
                    this.$router.replace("/user/order?status=draft");
                    this.statusKey = 'draft';
                    this.search();

                } 
            // 
                 
              
            }).catch((res)=>{
              
            })
            })
            .catch(() => {
                // on cancel
            });
            },
            onSelect(e){
              if(e.icon=='wechat'){
                  window.location.href= this.showShare;
              }else if(e.icon=='link'){
                 
              }
            },
            openShareModal(linkurl){
               
                if(isHttps){linkurl = linkurl.replace("https://","http://").replace("http://","https://")} 
              this.showShare = linkurl;
            
       let that = this;
       that.$nextTick(function(){
        var clipboard = new ClipboardJS('.copy-btn', {
            text: function(trigger) {
                return linkurl;
            }
        });
            clipboard.on('success', (e) =>{
                    that && that.$toast.success("报价单复制成功")
                    e.clearSelection();
                });
       })
      
            },
            openOrClose(order_id){
                let that = this;
              this.orders.forEach((item,index)=>{
                 if(item.id==order_id){
                     that.$set(this.orders[index],'openedAll',!item.openedAll)
                   //  that.orders[index].openedAll = !item.openedAll
                 }
              })
            },
            deleteOrder(id){
                Dialog.confirm({
            title: '提示',
            message: '您确定要删除该订单吗',
            })
            .then(() => {
               this.$http.delete("/clients/orders/"+id).then((res) => {
                 
               let index= this.orders.findIndex((item)=>item.id==id)
                this.orders.splice(index,1)
                 
              
            }).catch((res)=>{
              
            })
            })
            .catch(() => {
                // on cancel
            });
            },
            openImgPreview(img){
            ImagePreview([img]);

            },
            gotopay(oid,total_price){
               let queryShopId = localStorage.getItem("queryShopId")
                  let {shop,user} = this.$store.getters;
                    if(total_price>0&&shop.wepay&&user.auth.AllowTogglePrice&&this.is_allow_toggle_price_checked){
                          let token =  localStorage.getItem("token")
                          wx.miniProgram.navigateTo({url: '/pages/pay?fromSource=order&id='+oid+'&token='+token})
                    }
            },
            gotodetail(url){
                url = url.replace('orders','draft');
          
                if(isHttps){url = url.replace("https://","http://").replace("http://","https://")} 
                 window.location.href =url+'?from=zhilian'
            },
            onClick(index,title){
               
                 this.statusKey =this.statusArr[index].key
                 this.$router.replace('/user/order?status='+this.statusKey)
                this.search();
            
            },
             getClientsOptions() {
                 let vm = this;
            this.$http.get("/clients/options").then((res) => {
                let options = {};
               
               this.positions = res.positions;
               let order_status = res.order_status||[];
               this.order_status = order_status;
         
              let statusArr =
                  [].concat(vm.order_status.map(item => {
                      return {text:item.label,key:item.val}
                })).filter((item)=>item.key!='draft')
                this.statusArr = statusArr;
               
                this.$nextTick(()=>{
                let  index = statusArr.findIndex((item)=>item.key == vm.statusKey)||0;
                vm.activeIndex = index;
                })
              
          
          
          
            });
      },
            getOrderList() {
                this.loading = true
                let url
                if (this.is_search) {
                    url = '/clients/orders?type=design_order&page=' + this.page + '&start=' + this.startValue + '&end=' + this.endValue + '&status=' + this.statusKey
                } else {
                    url = '/clients/orders?type=design_order&page=' + this.page + '&status=' + (this.statusKey||'')
                }
                this.$http.get(url).then(res => {
                    if (res.items.length === 0) {
                        this.finished = true
                    } else {
                        res.items.forEach(item => {
                            item.vouchers = item.vouchers||'';
                            item.vouchers = item.vouchers.split(",")
                            item.vouchers = item.vouchers.filter((item)=>item!='');
                            this.orders.push(item)
                        })
                    }
                    if (res.pages <= this.page) {
                        this.finished = true
                    }else{
                        this.finished = false
                    }
                    this.loading = false
                    this.page++
                })
            },
           
            formatTimer2(value) {
                if (value == '' || value == ' ') {
                    return ''
                }
                let date = new Date(value)
                let y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? '0' + MM : MM
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                let h = date.getHours()
                h = h < 10 ? '0' + h : h
                let m = date.getMinutes()
                m = m < 10 ? '0' + m : m
                let s = date.getSeconds()
                s = s < 10 ? '0' + s : s
                return y + '-' + MM + '-' + d
            },
            search() {
                this.is_search = true
                this.page = 1
                this.orders = []
                this.getOrderList()
            },
            confirmTime(type) {
                if (type == 'start') {
                    this.startValue = this.formatTimer2(this.startTimeValue)
                    this.showPicker = false
                } else {
                    this.endValue = this.formatTimer2(this.endTimeValue)
                    this.showPickerEnd = false
                }
            },
            confirmStatus(status, key) {
                this.status = status
                let item = this.order_status[key]
                if (!item || !item['val']) {
                    this.$toast.fail('订单状态选择失败')
                    return false
                }
                this.statusKey = this.order_status[key]['val']
                this.showPickerStatus = false
            },
            orderDetail(id) {
                return false
                this.$router.push({path: '/user/order/' + id})
            }
        }
    }
</script >
<style >
    .order-list .order-list-group {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .order-border {
        border: none;
        border-radius: 5px;
    }

    .user-order {
           background-color: #eeeeee;
    min-height: calc(100vh - 40px);
    }

    .order-top {
        background-color: #fff;
        display: flex;
    }
    .order-list{
        padding: 10px;
    box-sizing: border-box;
        padding-top: 0;
    }
    .order-list-group{
            padding: 10px;
            border-radius:5px!important ;
    }
    .goods-card{
            margin-bottom: 5px;
    }
    .position-item{
       display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    }
    .position-item>div::after{
     content:',';
    }
    .position-item>div:last-child::after{
     content:'';
    }
    .install_location_label{
            font-weight: 600;
    margin-right: 5px;
    }
    .btn-detail{
            display: inline-flex;
    align-items: center;
    margin-right: 0;
    text-align: right;
    }
    .extend_checks_value span{
      margin-right: 3px;
    }
   .draft .van-tabs__wrap{
    display: none;
   }
</style >
