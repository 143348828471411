<template >
    <van-tabbar style="max-width:820px;" route >
        <van-tabbar-item name="index" icon="home-o" :to="'/shop/'+$store.getters.shop_id" >首页</van-tabbar-item >
        <van-tabbar-item name="series" icon="apps-o" :to="'/series/'+$store.getters.shop_id" >{{$store.state.menutexts["商城"]||'商城'}}</van-tabbar-item >
        <van-tabbar-item name="case" icon="star-o" :to="'/case/'+$store.getters.shop_id" >{{$store.state.menutexts["案例库"]||'案例库'}}</van-tabbar-item >
        <van-tabbar-item name="cart" :badge="count" icon="shopping-cart-o" :to="'/cart?orderid='+($store.getters.confirmorderid||'')" >购物车</van-tabbar-item >
        <van-tabbar-item name="user" icon="user-o" to="/user" >我的</van-tabbar-item >
    </van-tabbar >
</template >

<script >
    export default {
       props: {
           count:0
       },
       
        data() {
            return {
                badge:0,
                menutexts:{}
            };
        },
        mounted(){
            let that = this;
           this.$setMenuText((v)=>{
            
               let new_title =v[that.$route.meta.title];
           
                if(new_title){
                document.title = new_title;
                }
           });
           
        }
    };
</script >
<style scoped>

</style>